import React from 'react'
import type { FieldValues, UseControllerProps } from 'react-hook-form'
import { Controller } from 'react-hook-form'

import type { Props as InputProps } from 'src/graphics/atoms/Input'
import Input from 'src/graphics/atoms/Input'

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  inputProps: InputProps
}

const ControllerInput = <T extends FieldValues>({ name, control, inputProps }: Props<T>) => {
  return <Controller name={name} control={control} render={({ field }) => <Input {...inputProps} {...field} />} />
}

export default ControllerInput
