import React from 'react'
import { getEmbedUrl } from 'shared/helpers/parseChannelUrl'

import scss from './style.module.scss'

interface Props {
  url?: string
}

const EmbedPlayer = ({ url }: Props) => {
  const embedInfo = getEmbedUrl(url)
  if (embedInfo?.source === 'youtube') {
    return (
      <div className={scss.container}>
        <iframe
          className={scss.player}
          src={embedInfo.url}
          width="100%"
          height="100%"
          frameBorder={24}
          allow="accelerometer; encrypted-media; picture-in-player; web-share"
          allowFullScreen
        />
      </div>
    )
  } else if (embedInfo?.source === 'twitch') {
    return (
      <div className={scss.container}>
        <iframe className={scss.player} src={embedInfo.url} width="100%" height="100%" frameBorder={24} allowFullScreen />
      </div>
    )
  }
}

export default EmbedPlayer
