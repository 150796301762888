import type { ReactNode } from 'react'
import React from 'react'
import InfoIcon from 'shared/graphics/icons/Info.svg?component'

import scss from './style.module.scss'

const ICONS = {
  default: InfoIcon,
} as const

type Icons = keyof typeof ICONS

interface Props {
  children: ReactNode
  icon?: Icons
}

const Information = ({ children, icon = 'default' }: Props) => {
  const Icon = ICONS[icon]
  return (
    <div className={scss.information}>
      <Icon className={scss.icon} />
      {children}
    </div>
  )
}

export default Information
