import cn from 'classnames'
import type { ReactNode, RefObject } from 'react'
import React, { useCallback, useEffect, useRef } from 'react'
import useCustomPlayer from 'shared/hooks/useCustomPlayer'

import ProgressBar from './ProgressBar'
import VideoController from './VideoController'

import scss from './style.module.scss'

export interface Props {
  onVideoRef?: (ref: RefObject<HTMLVideoElement>) => void
  videoPath: string
  children?: ReactNode
  disableFullScreen?: boolean
  videoClassName?: string
  posterUrl?: string
  videoOffset?: number
  videoDuration?: number
  mainClassName?: string
  className?: string
  onTimeChange?: (time: number) => void
  disablePlayer?: boolean
  events?: ReactNode
  keySpaceToTogglePlayDisabled?: boolean
  verticalContainer?: boolean
}

const VideoPlayer2 = ({
  onVideoRef,
  videoPath,
  children,
  disableFullScreen,
  videoClassName,
  posterUrl,
  videoOffset = 0,
  videoDuration,
  mainClassName,
  className,
  onTimeChange,
  disablePlayer,
  events,
  keySpaceToTogglePlayDisabled,
  verticalContainer,
}: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const video = videoRef.current
    return () => {
      video?.pause()
    }
  }, [videoRef])

  const handleTimeChange = (time: number) => {
    onTimeChange?.(time)
  }

  const {
    togglePlayPause,
    currentTime,
    duration,
    updateCurrentTime,
    isPlaying,
    loaded,
    muted,
    toggleMute,
    percentVolume,
    updateMuted,
    updateVolume,
    toggleFullScreen,
    isFullScreen,
    isMouseMoving,
  } = useCustomPlayer({
    videoRef,
    playerContainerRef: containerRef,
    offset: videoOffset,
    duration: videoDuration,
    onTimeChange: handleTimeChange,
  })

  useEffect(() => {
    onVideoRef?.(videoRef)
  }, [onVideoRef])

  const handleSpaceKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Space' && !disablePlayer && document.activeElement === document.body && !keySpaceToTogglePlayDisabled) {
        event.preventDefault()
        togglePlayPause()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disablePlayer, togglePlayPause]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleSpaceKeyDown)

    return () => {
      window.removeEventListener('keydown', handleSpaceKeyDown)
    }
  }, [handleSpaceKeyDown])

  const handleProgressBarChange = useCallback(
    (value: number) => {
      updateCurrentTime((value * duration) / 100)
    },
    [duration, updateCurrentTime]
  )

  const handleTogglePlayPause = () => {
    if (disablePlayer) return
    togglePlayPause()
  }

  return (
    <div
      className={cn(
        scss.outer,
        className,
        verticalContainer && scss.isVerticalContainer,
        isFullScreen && scss.isFullScreen,
        isMouseMoving && scss.isMouseMoving
      )}
      ref={containerRef}
    >
      <div className={cn(scss.main, mainClassName, disablePlayer && scss.isDisabled)}>
        <video className={cn(scss.video, videoClassName)} ref={videoRef} src={videoPath} poster={posterUrl} onClick={handleTogglePlayPause} />
        {children ? children : null}
      </div>
      {events}

      <div className={scss.controllers}>
        {!disablePlayer && (
          <ProgressBar value={loaded && typeof duration === 'number' ? (currentTime * 100) / duration : 0} onChange={handleProgressBarChange} />
        )}
        <VideoController
          isPlaying={isPlaying}
          onTogglePlayPause={handleTogglePlayPause}
          duration={duration}
          percentCurrentTime={loaded && typeof duration === 'number' ? (currentTime * 100) / duration : 0}
          muted={muted}
          onToggleMute={toggleMute}
          percentVolume={percentVolume}
          updateMuted={updateMuted}
          updateVolume={updateVolume}
          toggleFullScreen={!disableFullScreen ? toggleFullScreen : undefined}
          disablePlayer={disablePlayer}
          isFullScreen={isFullScreen}
        />
      </div>
    </div>
  )
}

export default VideoPlayer2
