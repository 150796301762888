import cn from 'classnames'
import type { Ref } from 'react'
import React, { forwardRef } from 'react'
import type {
  ControlProps,
  GroupBase,
  InputProps,
  MenuProps,
  MultiValueGenericProps,
  MultiValueProps,
  MultiValueRemoveProps,
  OptionProps,
  PlaceholderProps,
  Props,
  SelectInstance,
  SingleValueProps,
  ValueContainerProps,
} from 'react-select'
import ReactSelect, { components } from 'react-select'
import CloseIcon from 'shared/graphics/icons/Close.svg?component'

import scss from './style.module.scss'

export interface SelectProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>
  extends Props<Option, IsMulti, Group> {
  error?: string
}

const Select = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: SelectProps<Option, IsMulti, Group>,
  ref: Ref<SelectInstance<Option, IsMulti, Group>>
) => {
  const { error } = props

  return (
    <div className={scss.outer}>
      <ReactSelect
        {...props}
        ref={ref}
        className={cn(scss.select, error && scss.isError)}
        components={{
          Control: CustomControl,
          Placeholder: CustomPlaceholder,
          ValueContainer: CustomValueContainer,
          Input: CustomInput,
          SingleValue: CustomSingleValue,
          Menu: CustomMenu,
          Option: CustomOption,
          MultiValueContainer: CustomMultiValueContainer,
          MultiValue: CustomMultiValue,
          MultiValueLabel: CustomMultiValueLabel,
          MultiValueRemove: CustomMultiValueRemove,
        }}
      />
      {error && <span className={scss.error}>{error}</span>}
    </div>
  )
}

const CustomControl = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  children,
  ...props
}: ControlProps<Option, IsMulti, Group>) => {
  const { isFocused, isDisabled } = props
  return (
    <components.Control {...props} className={cn(scss.control, isFocused && scss.isFocused, isDisabled && scss.isDisabled)}>
      {children}
    </components.Control>
  )
}

const CustomPlaceholder = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  children,
  ...props
}: PlaceholderProps<Option, IsMulti, Group>) => {
  return (
    <components.Placeholder {...props} className={scss.placeholder}>
      {children}
    </components.Placeholder>
  )
}

const CustomInput = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  children,
  ...props
}: InputProps<Option, IsMulti, Group>) => {
  return (
    <components.Input {...props} className={scss.input}>
      {children}
    </components.Input>
  )
}

const CustomValueContainer = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  children,
  ...props
}: ValueContainerProps<Option, IsMulti, Group>) => {
  return (
    <components.ValueContainer {...props} className={scss.valueContainer}>
      {children}
    </components.ValueContainer>
  )
}

const CustomSingleValue = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  children,
  ...props
}: SingleValueProps<Option, IsMulti, Group>) => {
  return (
    <components.SingleValue {...props} className={scss.singleValue}>
      {children}
    </components.SingleValue>
  )
}

const CustomMenu = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  children,
  ...props
}: MenuProps<Option, IsMulti, Group>) => {
  return (
    <components.Menu {...props} className={scss.menu}>
      {children}
    </components.Menu>
  )
}

const CustomOption = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  children,
  ...props
}: OptionProps<Option, IsMulti, Group>) => {
  const { isSelected, isFocused } = props
  return (
    <components.Option {...props} className={cn(scss.option, isSelected && scss.isSelected, isFocused && scss.isFocused)}>
      {children}
    </components.Option>
  )
}

const CustomMultiValueContainer = ({ children }: MultiValueGenericProps) => {
  return <div className={scss.multiValueContainer}>{children}</div>
}

const CustomMultiValue = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  children,
  ...props
}: MultiValueProps<Option, IsMulti, Group>) => {
  return (
    <components.MultiValue {...props} className={scss.multiValue}>
      {children}
    </components.MultiValue>
  )
}

const CustomMultiValueLabel = ({ children }: MultiValueGenericProps) => {
  return <span className={scss.multiValueLabel}>{children}</span>
}

const CustomMultiValueRemove = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  ...props
}: MultiValueRemoveProps<Option, IsMulti, Group>) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon className={scss.multiValueRemove__icon} />
    </components.MultiValueRemove>
  )
}

export default forwardRef<SelectInstance, any>(Select)
