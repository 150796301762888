import React from 'react'
import { NavLink, generatePath } from 'react-router-dom'

import { ROUTES_PATH } from 'src/config'

import NavbarProfilePicture from 'src/graphics/molecules/NavbarProfilePicture'

import scss from './style.module.scss'

interface Props {
  image?: string
  name: string
  id: string
  reloadDocument?: boolean
}

const OrganizationNavLink = ({ image, name, id, reloadDocument }: Props) => {
  return (
    <NavLink end to={generatePath(ROUTES_PATH.ORGANIZATION, { organizationId: id })} reloadDocument={reloadDocument}>
      <div className={scss.outer}>
        <NavbarProfilePicture radius={12} size={40} src={image} />
        <span className={scss.name}>{name}</span>
      </div>
    </NavLink>
  )
}

export default OrganizationNavLink
