import { rest_AnalysisStatus as AnalysisStatus } from '@powdergg/json-verse-api/dist/generated-axios-client/models/rest_AnalysisStatus'
import React from 'react'
import type { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import NotFoundIcon from 'shared/graphics/icons/NotFound.svg?component'

import scss from './style.module.scss'

type AnalysisStatusValues = `${AnalysisStatus}`

const ANALYZE_INFO: Record<AnalysisStatusValues, { label: ReactNode }> = {
  [AnalysisStatus.AnalysisStatusFailed]: {
    label: <FormattedMessage defaultMessage={'Error occurred'} />,
  },
  [AnalysisStatus.AnalysisStatusPending]: {
    label: <FormattedMessage defaultMessage={'Analyze your video to see results'} />,
  },
  [AnalysisStatus.AnalysisStatusCanceled]: {
    label: <FormattedMessage defaultMessage={'Analyze your video to see results'} />,
  },
  [AnalysisStatus.AnalysisStatusProcessing]: {
    label: <FormattedMessage defaultMessage={'Analyzing your video, clips are going to appear here'} />,
  },
  [AnalysisStatus.AnalysisStatusDone]: {
    label: <FormattedMessage defaultMessage={'No results, try to re-analyze'} />,
  },
} as const
interface Props {
  analysisStatus?: AnalysisStatus
}

const ClipsAnalysisStatus = ({ analysisStatus }: Props) => {
  if (!analysisStatus) {
    return null
  }
  if (analysisStatus === AnalysisStatus.AnalysisStatusProcessing) {
    return (
      <div className={scss.status}>
        {/* @todo add the remaining time when backend ready */}
        {/* <div
          className={scss.status__progress}
          style={
            {
              '--progress-percent': `${progress}%`,
            } as React.CSSProperties
          }
        >
          <progress value="progress" max="100">
            {progress}%
          </progress>
          <span className={scss.status__progressCount}>{progress}%</span>
        </div>
        <span className={scss.status__remaining}>remaining</span> */}
        <span className={scss.status__text}>{ANALYZE_INFO[analysisStatus].label}</span>
      </div>
    )
  }
  if (analysisStatus === AnalysisStatus.AnalysisStatusDone) {
    return (
      <div className={scss.status}>
        <NotFoundIcon className={scss.status__icon} />
        <span className={scss.status__text}>{ANALYZE_INFO[analysisStatus].label}</span>
      </div>
    )
  }
  return (
    <div className={scss.status}>
      <span className={scss.status__text}>{ANALYZE_INFO[analysisStatus].label}</span>
    </div>
  )
}

export default ClipsAnalysisStatus
