import type { rest_GetOrganizationResponse as Organization } from '@powdergg/json-verse-api/dist/generated-axios-client/models/rest_GetOrganizationResponse'
import type { rest_OrganizationMember as OrganizationMember } from '@powdergg/json-verse-api/dist/generated-axios-client/models/rest_OrganizationMember'
import type { rest_Role as Role } from '@powdergg/json-verse-api/dist/generated-axios-client/models/rest_Role'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDeleteOrganizationMember, useGetOrganizationMembers, useUpdateOrganizationMemberRole } from 'shared/api/hooks/organization'
import { useModal } from 'shared/api/providers/ModalProvider'
import { RIGHTS, useRights } from 'shared/api/providers/RightsProvider'
import Trash from 'shared/graphics/icons/Trash.svg?component'

import Button from 'src/graphics/atoms/Button'
import Count from 'src/graphics/atoms/Count'
import DropDownItem from 'src/graphics/atoms/DropDownItem'
import Error from 'src/graphics/molecules/Error'
import FormEditionSubRow from 'src/graphics/molecules/FormEditionSubRow'
import ProfilePicture from 'src/graphics/molecules/ProfilePicture'
import { CellSettings, ListLayout, OrganizationMemberRow } from 'src/graphics/molecules/list'

import DialogModal from '../modal/DialogModal'

import scss from './style.module.scss'

interface Props {
  organization: Organization
}

const OrganizationMemberList = ({ organization }: Props) => {
  const { hasRight } = useRights()

  const { setModal, closeModal } = useModal()
  const {
    data: organizationMembers,
    refetch: refetchOrganizationMembers,
    isError: organizationMembersIsError,
  } = useGetOrganizationMembers({ id: organization.id }, { enabled: hasRight(RIGHTS.LIST_ORGANIZATION_MEMBERS) })
  const { mutate: updateOrganizationMemberRole } = useUpdateOrganizationMemberRole()
  const { mutate: deleteOrganizationMember } = useDeleteOrganizationMember({
    onSuccess: () => {
      refetchOrganizationMembers()
    },
  })

  const groupMembersByInvitationState = (members?: OrganizationMember[]) => {
    return members?.reduce((acc: Record<string, OrganizationMember[]>, member) => {
      const memberStatus = member.invitation_state
      return {
        ...acc,
        [memberStatus]: [...(acc[memberStatus] || []), member],
      }
    }, {})
  }

  const handleDeleteConfirmOrganizationMember = ({ organizationId, organizationProfileId }: { organizationId: string; organizationProfileId: string }) => {
    deleteOrganizationMember({ id: organizationId, organizationProfileId })
    closeModal()
  }

  const handleDeleteOrganizationMember = useCallback(
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    ({ organizationId, organizationProfileId }: Record<string, any>) => {
      setModal(
        <DialogModal
          title={<FormattedMessage defaultMessage={'Are you sure you want to revoke this member?'} />}
          actions={
            <>
              <Button color="purple" onClick={closeModal} outlined>
                <FormattedMessage defaultMessage={'Cancel'} />
              </Button>
              <Button color="purple" onClick={() => handleDeleteConfirmOrganizationMember({ organizationId, organizationProfileId })}>
                <FormattedMessage defaultMessage={'Revoke'} />
              </Button>
            </>
          }
          onClose={closeModal}
        />
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteOrganizationMember]
  )

  const handleUpdateOrganizationMemberRole = useCallback(
    ({ organizationProfileId, role }: { organizationProfileId: string; role: Role }) => {
      updateOrganizationMemberRole({ id: organization.id, organizationProfileId, role })
    },
    [organization.id, updateOrganizationMemberRole]
  )

  const groupedMembers = groupMembersByInvitationState(organizationMembers?.members)

  if (organizationMembersIsError) {
    return <Error />
  }

  return (
    <div className={scss.outer}>
      <FormEditionSubRow
        title={
          <>
            <FormattedMessage defaultMessage="Team Members" />
            <Count>{groupedMembers?.['accepted'].length}</Count>
          </>
        }
      >
        <ListLayout>
          {groupedMembers?.['accepted'].map(({ company_email, role, organization_profile_id, profile_picture }) => (
            <OrganizationMemberRow
              key={organization_profile_id}
              id={organization_profile_id}
              profile={<ProfilePicture text={company_email.substring(0, 1).toUpperCase()} src={profile_picture} />}
              name={company_email}
              role={role}
              onChangeRole={handleUpdateOrganizationMemberRole}
              settings={
                hasRight(RIGHTS.REVOKE_ORGANIZATION_MEMBER) && role !== 'owner' ? (
                  <CellSettings>
                    <DropDownItem
                      onClick={() => handleDeleteOrganizationMember({ organizationProfileId: organization_profile_id, organizationId: organization.id })}
                      icon={Trash}
                      size="small"
                    >
                      <FormattedMessage defaultMessage="Revoke" />,
                    </DropDownItem>
                  </CellSettings>
                ) : undefined
              }
              canUpdateRole={hasRight(RIGHTS.PROMOTE_ORGANIZATION_MEMBER)}
            />
          ))}
        </ListLayout>
      </FormEditionSubRow>
      {groupedMembers?.['pending'] && (
        <FormEditionSubRow
          title={
            <>
              <FormattedMessage defaultMessage="Pending" />
              <Count>{groupedMembers['pending'].length}</Count>
            </>
          }
        >
          <ListLayout>
            {groupedMembers['pending'].map(({ company_email, role, organization_profile_id, profile_picture }) => (
              <OrganizationMemberRow
                key={organization_profile_id}
                id={organization_profile_id}
                profile={<ProfilePicture text={company_email.substring(0, 1).toUpperCase()} src={profile_picture} />}
                name={company_email}
                role={role}
                onChangeRole={handleUpdateOrganizationMemberRole}
                settings={
                  hasRight(RIGHTS.REVOKE_ORGANIZATION_MEMBER) ? (
                    <CellSettings>
                      <DropDownItem
                        onClick={() => handleDeleteOrganizationMember({ organizationProfileId: organization_profile_id, organizationId: organization.id })}
                        icon={Trash}
                      >
                        <FormattedMessage defaultMessage="Revoke" />,
                      </DropDownItem>
                    </CellSettings>
                  ) : undefined
                }
                canUpdateRole={hasRight(RIGHTS.PROMOTE_ORGANIZATION_MEMBER)}
              />
            ))}
          </ListLayout>
        </FormEditionSubRow>
      )}
    </div>
  )
}

export default OrganizationMemberList
