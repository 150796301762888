import type { RightListValues } from 'shared/api/providers/RightsProvider'

import Campaign from './pages/Campaign'
import CampaignAddEdit from './pages/CampaignAddEdit'
import CampaignClipList from './pages/CampaignClipList'
import CampaignList from './pages/CampaignList'
import Organization from './pages/Organization'

export const ROUTES_PATH = {
  SIGN_IN: '/sign-in',
  HOME: '/',
  JOIN: '/organization/join',
  ORGANIZATION: '/organization/:organizationId',
  CAMPAIGN_LIST: '/organization/:organizationId/campaigns',
  CAMPAIGN_ADD: '/organization/:organizationId/campaigns/add',
  CAMPAIGN_EDIT: '/organization/:organizationId/campaigns/:campaignId/edit',
  CAMPAIGN: '/organization/:organizationId/campaigns/:campaignId',
  CAMPAIGN_CLIP_LIST: '/organization/:organizationId/campaigns/:campaignId/:recordingId',
  NOT_FOUND: '/404',
} as const

type RoutesPath = keyof typeof ROUTES_PATH
type RoutesPathValues = (typeof ROUTES_PATH)[RoutesPath]

export const RIGHTS_ROUTES: { path: RoutesPathValues; element: () => React.JSX.Element; rights: RightListValues[] }[] = [
  {
    path: ROUTES_PATH.ORGANIZATION,
    element: Organization,
    rights: ['view_organization'],
  },
  {
    path: ROUTES_PATH.CAMPAIGN_LIST,
    element: CampaignList,
    rights: ['list_campaigns'],
  },
  {
    path: ROUTES_PATH.CAMPAIGN_ADD,
    element: CampaignAddEdit,
    rights: ['create_campaign'],
  },
  {
    path: ROUTES_PATH.CAMPAIGN_EDIT,
    element: CampaignAddEdit,
    rights: ['update_campaign'],
  },
  {
    path: ROUTES_PATH.CAMPAIGN,
    element: Campaign,
    rights: ['view_campaign'],
  },
  {
    path: ROUTES_PATH.CAMPAIGN_CLIP_LIST,
    element: CampaignClipList,
    rights: ['list_video_clips'],
  },
]
