import type { rest_CampaignList as Campaign } from '@powdergg/json-verse-api/dist/generated-axios-client/models/rest_CampaignList'
import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useNavigate } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group'
import { useDeleteCampaign } from 'shared/api/hooks/organization'
import { useModal } from 'shared/api/providers/ModalProvider'
import { RIGHTS, useRights } from 'shared/api/providers/RightsProvider'
import Settings from 'shared/graphics/icons/Settings.svg?component'
import Trash from 'shared/graphics/icons/Trash.svg?component'

import { ROUTES_PATH } from 'src/config'

import Button from 'src/graphics/atoms/Button'
import DropDownItem from 'src/graphics/atoms/DropDownItem'
import ProfilePicture from 'src/graphics/molecules/ProfilePicture'
import { CampaignRow, CellSettings, ListLayout } from 'src/graphics/molecules/list'
import CSSTransitionRow from 'src/graphics/molecules/list/TransitionRow'

import DialogModal from '../modal/DialogModal'

interface Props {
  organizationId: string
  organizationCampaigns: Campaign[]
  refetchOrganizationCampaigns: () => void
  organizationCampaignsError?: boolean
}

const CampaignList = ({ organizationId, organizationCampaigns, refetchOrganizationCampaigns }: Props) => {
  const { hasRight } = useRights()
  const navigate = useNavigate()
  const intl = useIntl()
  const { closeModal, setModal } = useModal()

  const dateTimeS = new Date().getTime() / 1000

  const { mutate: deleteCampaign } = useDeleteCampaign({
    onSuccess: () => {
      refetchOrganizationCampaigns()
    },
  })

  const handleManageCampaign = useCallback(
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    ({ campaignId, organizationId }: Record<string, any>) => {
      navigate(generatePath(ROUTES_PATH.CAMPAIGN_EDIT, { organizationId, campaignId }))
    },
    [navigate]
  )

  const handleConfirmDeleteCampaign = ({ campaignId, organizationId }: { campaignId: string; organizationId: string }) => {
    deleteCampaign({ campaignId, organizationId })
    closeModal()
  }

  const handleDeleteCampaign = useCallback(
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    ({ campaignId, organizationId }: Record<string, any>) => {
      setModal(
        <DialogModal
          title={<FormattedMessage defaultMessage={'Are you sure you want to delete this campaign?'} />}
          actions={
            <>
              <Button color="purple" onClick={closeModal} outlined>
                <FormattedMessage defaultMessage={'Cancel'} />
              </Button>
              <Button color="purple" onClick={() => handleConfirmDeleteCampaign({ campaignId, organizationId })}>
                <FormattedMessage defaultMessage={'Delete'} />
              </Button>
            </>
          }
          onClose={closeModal}
        />
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteCampaign]
  )

  const handleNavigateToCampaign = useCallback(
    (campaignId: string) => {
      navigate(generatePath(ROUTES_PATH.CAMPAIGN, { campaignId, organizationId }))
    },
    [navigate, organizationId]
  )

  if (!organizationCampaigns.length) {
    return <ListLayout emptyState={<FormattedMessage defaultMessage={'No results, please change your filters'} />} />
  }

  return (
    <ListLayout>
      <TransitionGroup component={null}>
        {organizationCampaigns?.map(({ name, channels_count, end_date, id, start_date, clips_count, videos_count, customer_name }) => (
          <CSSTransitionRow key={id}>
            <CampaignRow
              key={id}
              picture={<ProfilePicture text={name.substring(0, 1).toUpperCase()} radius={8} size={64} />}
              name={name}
              brand={customer_name}
              channels={[`${channels_count} channels`]}
              content={[
                `${videos_count} ${intl.formatMessage(
                  { defaultMessage: '{itemCount, plural, =0 {stream} one {stream} other {streams}}' },
                  { itemCount: videos_count }
                )}`,
                `${clips_count} ${intl.formatMessage(
                  { defaultMessage: '{itemCount, plural, =0 {clip} one {clips} other {clips}}' },
                  { itemCount: clips_count }
                )}`,
              ]}
              status={dateTimeS > start_date && dateTimeS < end_date ? 'active' : dateTimeS < start_date ? 'upcoming' : 'completed'}
              settings={
                <CellSettings>
                  {hasRight(RIGHTS.UPDATE_CAMPAIGN) && (
                    <DropDownItem onClick={() => handleManageCampaign({ campaignId: id, organizationId })} icon={Settings} size="small">
                      <FormattedMessage defaultMessage="Manage" />
                    </DropDownItem>
                  )}
                  {hasRight(RIGHTS.DELETE_CAMPAIGN) && (
                    <DropDownItem onClick={() => handleDeleteCampaign({ campaignId: id, organizationId })} icon={Trash} size="small">
                      <FormattedMessage defaultMessage="Delete" />
                    </DropDownItem>
                  )}
                </CellSettings>
              }
              onClick={hasRight(RIGHTS.VIEW_CAMPAIGN) ? () => handleNavigateToCampaign(id) : undefined}
            />
          </CSSTransitionRow>
        ))}
      </TransitionGroup>
    </ListLayout>
  )
}

export default CampaignList
