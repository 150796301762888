import React from 'react'
import { FormattedMessage } from 'react-intl'
import Trash from 'shared/graphics/icons/Trash.svg?component'

import Count from 'src/graphics/atoms/Count'
import DropDownItem from 'src/graphics/atoms/DropDownItem'
import FormEditionSubRow from 'src/graphics/molecules/FormEditionSubRow'
import ProfilePicture from 'src/graphics/molecules/ProfilePicture'
import { CellSettings, ChannelRow, ListLayout } from 'src/graphics/molecules/list'

interface Props {
  list: string[]
  onDelete: ({ value }: { value: string }) => void
  editMode?: boolean
}

const CampaignChannelsList = ({ list, onDelete, editMode }: Props) => {
  return (
    <FormEditionSubRow
      title={
        <>
          <FormattedMessage defaultMessage="Added channels" />
          <Count>{list.length}</Count>
        </>
      }
    >
      <ListLayout>
        {list.map((name) => (
          <ChannelRow
            key={name}
            name={name}
            picture={<ProfilePicture text={name.charAt(0)} />}
            settings={
              !editMode ? (
                <CellSettings>
                  <DropDownItem icon={Trash} onClick={() => onDelete({ value: name })} size="small">
                    <FormattedMessage defaultMessage={'Delete'} />
                  </DropDownItem>
                </CellSettings>
              ) : undefined
            }
          />
        ))}
      </ListLayout>
    </FormEditionSubRow>
  )
}

export default CampaignChannelsList
