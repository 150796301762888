import type { rest_AddOrganizationProfileRequest, rest_Role, rest_UpdateOrganizationProfileRequest } from '@powdergg/json-verse-api/dist/generated-axios-client'

import { powderClient } from '../libs/powder'
import { getAuthorization, type QueryParams } from '../libs/utils'
import type { Auth } from '../providers/AuthProvider'

import { normalizeSession } from './user'

export const joinOrganization = async ({ invitationToken }: { invitationToken: string }, { setSession }: Auth) => {
  const data = await powderClient.loginApiV1.loginApiv1JoinOrganization({
    requestBody: { invitation_token: invitationToken },
  })

  const session = normalizeSession(data)

  setSession(session)

  return { organizationId: data.organization_id }
}

export const updateOrganizationLogo = async ({ organizationId, file }: { organizationId: string; file: File }, { session }: Auth) => {
  const url = await powderClient.uploadApiV1.uploadApiv1GetOrganizationLogoUrl(
    {
      organizationId,
      requestBody: { file_size: file.size, file_name: file.name },
    },
    { headers: getAuthorization(session) }
  )

  if (!url) {
    throw new Error('No url')
  }

  try {
    await fetch(url.presigned_upload_url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('ERROR :', e)
  }
}

export const updateCampaignLogo = async (
  { organizationId, campaignId, file }: { organizationId: string; campaignId: string; file: File },
  { session }: Auth
) => {
  const url = await powderClient.uploadApiV1.uploadApiv1GetCampaignLogoUrl(
    {
      organizationId,
      campaignId,
      requestBody: { file_size: file.size, file_name: file.name },
    },
    { headers: getAuthorization(session) }
  )

  if (!url) {
    throw new Error('No url')
  }

  try {
    await fetch(url.presigned_upload_url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('ERROR :', e)
  }
}

export const updateOrganizationProfilePictureUrl = async (
  {
    organizationId,
    organizationProfileId,
  }: {
    organizationId: string
    organizationProfileId: string
  },
  { session }: Auth
) => {
  const url = await powderClient.uploadApiV1.uploadApiv1GetOrganizationProfileProfilePictureUrl(
    {
      organizationId,
      organizationProfileId,

      // @todo fix this
      requestBody: { file_name: '1234', file_size: 1234 },
    },
    { headers: getAuthorization(session) }
  )

  if (!url) {
    throw new Error('No url')
  }

  //@todo do upload
  // eslint-disable-next-line no-console
  console.log({ url })
}

export const createOrganization = async ({ name, website }: { name: string; website?: string }, { session }: Auth) => {
  const data = await powderClient.userApiV1.userApiv1CreateOrganization(
    {
      requestBody: {
        name,
        website,
      },
    },
    {
      headers: getAuthorization(session, true),
    }
  )

  return data
}

export const updateOrganization = async ({ id, name, website }: { id: string; name: string; website?: string }, { session }: Auth) => {
  const data = await powderClient.userApiV1.userApiv1UpdateOrganization(
    {
      id,
      requestBody: {
        name,
        website,
      },
    },
    {
      headers: getAuthorization(session),
    }
  )

  return data
}

export const getOrganization = async ({ auth: { session }, params: { id } }: QueryParams<{ id: string }>) => {
  const data = await powderClient.userApiV1.userApiv1GetOrganization(
    {
      id,
    },
    {
      headers: getAuthorization(session),
    }
  )
  // @todo find another solution to force logo update
  return {
    id: data.id,
    logo: data.logo + '?t=' + new Date().getTime(),
    members: data.members,
    name: data.name,
    website: data.website,
  }
}

export const getOrganizationMembers = async ({ auth: { session }, params: { id } }: QueryParams<{ id: string }>) => {
  const data = await powderClient.userApiV1.userApiv1ListOrganizationMembers({ id }, { headers: getAuthorization(session) })

  return data
}

export const createOrganizationMembers = async ({ id, emails }: { id: string; emails: string[] }, { session }: Auth) => {
  const data = await powderClient.userApiV1.userApiv1AddOrganizationMembers(
    {
      id,
      requestBody: {
        emails,
      },
    },
    {
      headers: getAuthorization(session),
    }
  )

  return data
}

export const updateOrganizationMemberRole = async (
  { id, organizationProfileId, role }: { id: string; organizationProfileId: string; role: rest_Role },
  { session }: Auth
) => {
  const data = await powderClient.userApiV1.userApiv1UpdateOrganizationMemberRole(
    {
      id,
      organizationProfileId,
      requestBody: {
        role,
      },
    },
    {
      headers: getAuthorization(session),
    }
  )

  return data
}

export const deleteOrganizationMember = async ({ id, organizationProfileId }: { id: string; organizationProfileId: string }, { session }: Auth) => {
  const data = await powderClient.userApiV1.userApiv1DeleteOrganizationMemberRequest(
    {
      id,
      organizationProfileId,
    },
    { headers: getAuthorization(session) }
  )

  return data
}

export const createOrganizationProfile = async ({
  id,
  organizationId,
  profile,
}: {
  id: string
  organizationId: string
  profile: rest_AddOrganizationProfileRequest
}) => {
  const data = await powderClient.userApiV1.userApiv1AddOrganizationProfile({
    id,
    organizationId,
    requestBody: {
      ...profile,
    },
  })

  return data
}

export const updateOrganizationProfile = async ({
  id,
  organizationId,
  organizationProfileId,
  profile,
}: {
  id: string
  organizationId: string
  organizationProfileId: string
  profile: rest_UpdateOrganizationProfileRequest
}) => {
  const data = await powderClient.userApiV1.userApiv1UpdateOrganizationProfile({
    id,
    organizationId,
    organizationProfileId,
    requestBody: {
      ...profile,
    },
  })

  return data
}

export const getUserOrganizations = async ({ auth: { session } }: QueryParams) => {
  if (!session?.user.id) throw new Error('No user id')

  const data = await powderClient.userApiV1.userApiv1ListMyOrganization({ id: session?.user.id }, { headers: getAuthorization(session, true) })

  return data?.organizations
}

export const getUserOrganizationSubscription = async ({ auth: { session } }: QueryParams) => {
  if (!session?.user.id) throw new Error('No user id')

  const data = await powderClient.userApiV1.userApiv1GetUserOrganizationSubscription({ id: session.user.id }, { headers: getAuthorization(session, true) })

  return data?.is_user_paying_for_subscription_plan
}

export const getOrganizationCampaigns = async ({ auth: { session }, params: { organizationId } }: QueryParams<{ organizationId: string }>) => {
  const data = await powderClient.userApiV1.userApiv1ListOrganizationCampaigns({ id: organizationId }, { headers: getAuthorization(session) })

  return data?.campaigns
}

export const createCampaign = async ({ organizationId, ...params }: Campaign & { organizationId: string }, { session }: Auth) => {
  const data = await powderClient.userApiV1.userApiv1CreateCampaign(
    {
      id: organizationId,
      requestBody: {
        name: params.name,
        start_at: new Date(params.startDate).getTime() / 1000,
        end_at: new Date(params.endDate).getTime() / 1000,
        language: params.language,
        keywords: params.keywords,
        channels_urls: params.channels,
        customer_name: params.customerName,
      },
    },
    { headers: getAuthorization(session) }
  )

  return data
}

export interface Campaign {
  customer: string
  guests: string[]
  name: string
  startDate: string
  endDate: string
  channels: string[]
  language: string
  keywords: string[]
  customerName: string
  logoUrl: string
  logo: File
}

export const getCampaign = async ({
  auth: { session },
  params: { organizationId, campaignId },
}: QueryParams<{ organizationId?: string; campaignId?: string }>) => {
  if (!organizationId || !campaignId) return {} as Campaign

  const data = await powderClient.userApiV1.userApiv1GetOrganizationCampaign({ id: organizationId, campaignId }, { headers: getAuthorization(session) })

  return {
    // customer: data.customer,
    // guests: data.guests,
    name: data.name,
    startDate: new Date(data.start_date * 1000).toISOString().split('T')[0],
    endDate: new Date(data.end_date * 1000).toISOString().split('T')[0],
    channels: data.channels,
    language: data.language,
    keywords: data.keywords,
    customerName: data.customer_name,
    logoUrl: data.logo_url,
  }
}

export const updateCampaign = async (
  { organizationId, campaignId, ...params }: Campaign & { organizationId: string; campaignId: string },
  { session }: Auth
) => {
  const data = await powderClient.userApiV1.userApiv1UpdateCampaign(
    {
      id: organizationId,
      campaignId,
      requestBody: {
        name: params.name,
        start_at: new Date(params.startDate).getTime() / 1000,
        end_at: new Date(params.endDate).getTime() / 1000,
        language: params.language,
        keywords: params.keywords,
        channels_urls: params.channels,
        customer_name: params.customerName,
      },
    },
    { headers: getAuthorization(session) }
  )

  return data
}

export const deleteCampaign = async ({ organizationId, campaignId }: { organizationId: string; campaignId: string }, { session }: Auth) => {
  const data = await powderClient.userApiV1.userApiv1DeleteCampaign({ id: organizationId, campaignId }, { headers: getAuthorization(session) })

  return data
}

export const getCampaignRecordings = async ({
  auth: { session },
  params: { organizationId, campaignId },
}: QueryParams<{ organizationId: string; campaignId: string }>) => {
  const data = await powderClient.userApiV1.userApiv1ListCampaignVideos({ id: organizationId, campaignId }, { headers: getAuthorization(session) })

  return data
}

export const startCampaignRecordingAnalysis = async (
  { organizationId, campaignId, recordingId }: { organizationId: string; campaignId: string; recordingId: string },
  { session }: Auth
) => {
  const data = await powderClient.userApiV1.userApiv1StartVideoAnalysis(
    {
      id: organizationId,
      campaignId: campaignId,
      videoId: recordingId,
    },
    {
      headers: getAuthorization(session),
    }
  )

  return data
}

export const cancelRecordingAnalysis = async (
  { organizationId, campaignId, recordingId }: { organizationId: string; campaignId: string; recordingId: string },
  { session }: Auth
) => {
  const data = await powderClient.userApiV1.userApiv1CancelVideoAnalysis(
    {
      id: organizationId,
      campaignId,
      videoId: recordingId,
    },
    {
      headers: getAuthorization(session),
    }
  )

  return data
}

export const getCampaignRecordingClips = async ({
  auth: { session },
  params: { organizationId, campaignId, recordingId },
}: QueryParams<{ organizationId: string; campaignId: string; recordingId: string }>) => {
  const data = await powderClient.userApiV1.userApiv1ListVideoClips(
    { id: organizationId, campaignId, videoId: recordingId },
    { headers: getAuthorization(session) }
  )

  return data
}

export const getOrganizationProfileToken = async ({ organizationId }: { organizationId: string }, { session }: Auth) => {
  const data = await powderClient.loginApiV1.loginApiv1GetOrganizationProfileToken({ id: organizationId }, { headers: getAuthorization(session, true) })

  return data
}
