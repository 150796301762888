import type { ReactNode } from 'react'
import React from 'react'
import SlimArrowTop from 'shared/graphics/icons/SlimArrowTop.svg?component'

import scss from './style.module.scss'

interface Props {
  illustrationUrl: string
  title: ReactNode
  withArrow?: boolean
}

const ContentHero = ({ title, illustrationUrl, withArrow }: Props) => {
  return (
    <div className={scss.outer}>
      <div className={scss.illustration}>
        <img className={scss.illustration__image} src={illustrationUrl} />
      </div>
      <div className={scss.title}>
        {title}
        {withArrow && <SlimArrowTop />}
      </div>
    </div>
  )
}

export default ContentHero
