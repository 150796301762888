import type { ReactNode } from 'react'
import React from 'react'
import type { IntlShape } from 'react-intl'
import { FormattedMessage, useIntl } from 'react-intl'

import scss from './style.module.scss'

export type AppError = (Error & { body?: { error?: string } }) | null

interface Props {
  children?: ReactNode
  error?: AppError
}

export const getRequestError = ({ error, intl }: { error: string; intl: IntlShape }) => {
  const REQUEST_ERRORS = {
    organization_name_already_in_use: intl.formatMessage({ defaultMessage: 'Organization name already in use' }),
  }

  if (error in REQUEST_ERRORS) {
    return REQUEST_ERRORS[error as keyof typeof REQUEST_ERRORS]
  }

  return intl.formatMessage({ defaultMessage: 'Something went wrong.' })
}

const Error = ({ children, error }: Props) => {
  const intl = useIntl()

  return (
    <div className={scss.error}>
      {children ? (
        children
      ) : typeof error?.body?.error === 'string' ? (
        getRequestError({ error: error.body.error, intl: intl })
      ) : (
        <FormattedMessage defaultMessage="Something went wrong." />
      )}
    </div>
  )
}

export default Error
