import cn from 'classnames'
import type { MouseEvent as MouseReactEvent, ReactNode } from 'react'
import React, { useRef } from 'react'

import scss from './style.module.scss'

interface BackdropProps {
  children: ReactNode
  onClick?: () => void
  noPadding?: boolean
}

const Backdrop = ({ children, onClick = () => {}, noPadding }: BackdropProps) => {
  const backdropRef = useRef<HTMLInputElement>(null)

  const handleClick = ({ target }: MouseReactEvent<HTMLDivElement>) => {
    if (backdropRef.current === target) {
      onClick()
    }
  }

  return (
    <div ref={backdropRef} className={cn(scss.backdrop, noPadding && scss.noPadding)} onClick={handleClick}>
      {children}
    </div>
  )
}

export default Backdrop
