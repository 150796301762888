import React from 'react'
import { useNavigate } from 'react-router-dom'

import type { Props as PropsButton } from '../Button'
import Button from '../Button'

interface Props extends PropsButton {
  to: string
}

const LinkButton = ({ children, to, ...props }: Props) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(to)
  }
  return (
    <Button {...props} type="button" onClick={handleClick}>
      {children}
    </Button>
  )
}

export default LinkButton
