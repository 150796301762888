import React from 'react'
import type { ReactNode } from 'react'

import scss from './style.module.scss'

interface Props {
  children: ReactNode
}

const OrganizationLayout = ({ children }: Props) => {
  return <div className={scss.outer}>{children}</div>
}

export default OrganizationLayout
