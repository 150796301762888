import type { ReactNode } from 'react'
import React from 'react'

import scss from './style.module.scss'

interface Props {
  children: ReactNode
}

const Description = ({ children }: Props) => {
  return <div className={scss.description}>{children}</div>
}

export default Description
