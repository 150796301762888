import type { rest_ValidatedSigninResponse } from '@powdergg/json-verse-api/dist/generated-axios-client'

import { powderClient } from '../libs/powder'
import type { QueryParams } from '../libs/utils'
import type { Auth } from '../providers/AuthProvider'

export const loginEmailSignIn = async ({ email }: { email: string }) => {
  const data = await powderClient.loginApiV1.loginApiWebV1EmailSignIn({ requestBody: { email } })
  return {
    email: email,
    token: data.token,
  }
}

export const loginValidateOtpSignIn = async ({ code, token }: { code: string; token: string }, { setSession }: Auth) => {
  const data = await powderClient.loginApiV1.loginApiWebV1ValidateOtpSignIn({
    requestBody: {
      token,
      validation_code: code,
    },
  })

  const session = normalizeSession(data)

  setSession(session)

  return session
}

export const fetchUser = async ({ auth: { session }, params: { id } }: QueryParams<{ id: string }>) => {
  try {
    const data = await powderClient.userApiV1.userApiv1GetUser(
      { id: id },
      {
        headers: {
          Authorization: session?.accessToken,
        },
      }
    )

    return {
      data,
    }
  } catch (error) {
    return {
      error,
    }
  }
}

export const fetchMyUser = async ({ auth: { session } }: QueryParams) => {
  try {
    const id = session?.user.id

    if (!id) {
      throw new Error('No id')
    }

    const data = await powderClient.userApiV1.userApiv1GetUser(
      { id },
      {
        headers: {
          Authorization: session?.accessToken,
        },
      }
    )

    return {
      data,
    }
  } catch (error) {
    return {
      error,
    }
  }
}

export const refreshSession = async ({ session, setSession, wipeSession }: Auth) => {
  try {
    if (!session?.refreshToken) {
      return
    }

    const data = await powderClient.loginApiV1.loginApiv1Token({
      requestBody: {
        refresh_token: session?.refreshToken,
      },
    })

    const sessionNormalized = normalizeSession(data)

    setSession(sessionNormalized)

    return sessionNormalized
  } catch (error) {
    wipeSession()
    throw error
  }
}

export const startSignInWithEmail = async ({ email }: { email: string }) => {
  const data = await powderClient.loginApiV1.loginApiv1EmailSignIn({
    requestBody: {
      email: email,
    },
  })

  return {
    email: email,
    token: data.token,
  }
}

export const finishSignInWithEmail = async ({ code, token }: { code: string; token: string }, { setSession }: Auth) => {
  if (!token) {
    throw new Error('No token, first call startSignInWithEmail')
  }

  const data = await powderClient.loginApiV1.loginApiv1ValidateOtpSignIn({
    requestBody: {
      token,
      validation_code: code,
    },
  })

  const session = normalizeSession(data)

  setSession(session)

  return session
}

export const normalizeSession = (data: rest_ValidatedSigninResponse) => {
  return {
    user: data.user,
    login: data.login,
    accessToken: data.token,
    refreshToken: data.refresh_token,
    expireAt: data.expires_at * 1000,
    rights: data.rights,
  }
}
