import type { ChangeEvent, FormEvent } from 'react'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useStartSignInWithEmail } from 'shared/api/hooks/user'

import Input from 'src/graphics/atoms/Input'
import LandingForm from 'src/graphics/organisms/LandingForm'

import scss from './style.module.scss'

interface Props {
  onSubmitAction: (code: string) => void
  isError?: boolean
  isPending?: boolean
  email: string
}

const CodeForm = ({ isError, isPending, onSubmitAction, email }: Props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { mutate: startSignInWithEmail, isPending: startSignInWithEmailPending } = useStartSignInWithEmail({
    onError: () => {
      setErrorMessage(intl.formatMessage({ defaultMessage: 'An error occurred while sending the email. Please try again later.' }))
    },
  })
  const intl = useIntl()

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const code = event.target.value

    if (code && code.length === 6) {
      setErrorMessage(null)
      onSubmitAction(code)
      return
    }

    return
  }

  useEffect(() => {
    if (isError) {
      setErrorMessage(intl.formatMessage({ defaultMessage: 'Wrong verification code' }))
    }
  }, [isError, intl])

  return (
    <LandingForm title={<FormattedMessage defaultMessage={'Check your inbox'} />}>
      <form onSubmit={handleSubmit} className={scss.form}>
        <div className={scss.form__input}>
          <Input
            disabled={isPending}
            placeholder={intl.formatMessage({ defaultMessage: 'Enter your verification code' })}
            type="text"
            name="code"
            onChange={handleInputChange}
            isError={!!errorMessage || isError}
            maxLength={6}
            error={errorMessage}
          />
        </div>
      </form>
      <div className={scss.footer}>
        <FormattedMessage
          defaultMessage={"Didn't receive the code? <a>Resend</a>"}
          values={{
            a: (chunks) => (
              <button
                className={scss.footer__resend}
                disabled={startSignInWithEmailPending}
                onClick={() => {
                  startSignInWithEmail({ email })
                }}
              >
                {chunks}
              </button>
            ),
          }}
        />
      </div>
    </LandingForm>
  )
}

export default CodeForm
