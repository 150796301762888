import { rest_Role as Role } from '@powdergg/json-verse-api/dist/generated-axios-client/models/rest_Role'
import type { ComponentType, ReactNode } from 'react'
import React, { useEffect, useState } from 'react'
import Select from 'shared/graphics/molecules/Select'

import { CellTitle } from 'src/graphics/molecules/list'

import Row from '../Row'

import scss from './style.module.scss'

const ROLES = [
  { label: 'Owner', value: Role.Owner },
  { label: 'Admin', value: Role.Admin },
  { label: 'Editor', value: Role.Editor },
]

interface Props {
  id: string
  gridTemplateColumns?: string
  profile: ReactNode
  name: string
  role?: Role
  infoIcon?: ComponentType<{ className?: string }>
  onChangeRole?: ({ organizationProfileId, role }: { organizationProfileId: string; role: Role }) => void
  settings?: ReactNode
  canUpdateRole?: boolean
}

type Option = { label: string; value: Role }

const OrganizationMemberRow = ({ id, profile, name, role, settings, gridTemplateColumns = '48px 1fr 150px 24px', onChangeRole, canUpdateRole }: Props) => {
  const [newRole, setNewRole] = useState<Role | undefined>(role)

  const handleSelectionChange = (option: Option | null) => {
    setNewRole(option?.value)
  }

  useEffect(() => {
    if (newRole && newRole !== role && onChangeRole) onChangeRole({ organizationProfileId: id, role: newRole })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRole, id, onChangeRole])

  const updateRoleDisabled = role === 'owner' || !canUpdateRole

  return (
    <Row gridTemplateColumns={gridTemplateColumns}>
      <div className={scss.profile}>{profile}</div>
      <div className={scss.name}>
        <CellTitle title={name} />
      </div>
      <div className={scss.action}>
        <Select options={ROLES.slice(1)} value={ROLES.find((r) => r.value === newRole)} isDisabled={updateRoleDisabled} onChange={handleSelectionChange} />
      </div>
      <div className={scss.settings}>{settings}</div>
    </Row>
  )
}

export default OrganizationMemberRow
