import type { ReactNode } from 'react'
import React from 'react'
import Link from 'shared/graphics/icons/Link.svg?component'

import HeaderCover from 'src/graphics/molecules/HeaderCover'

import scss from './style.module.scss'

interface Props {
  logoUrl?: string
  name: string
  website?: string
  actions?: ReactNode
}

const OrganizationHeader = ({ logoUrl, name, website, actions }: Props) => {
  return (
    <div className={scss.outer}>
      <div className={scss.header}>
        <HeaderCover image={logoUrl} />
        <div className={scss.content}>
          <h1 className={scss.content__name}>{name}</h1>
          {website && (
            <a className={scss.content__url} href={website}>
              <Link />
              {website}
            </a>
          )}
        </div>
      </div>
      {actions}
    </div>
  )
}

export default OrganizationHeader
