import React from 'react'
import type { ComponentType } from 'react'

import scss from './style.module.scss'

interface Props {
  icon?: ComponentType<{ className?: string }>
  title?: string
  infos?: string[]
  url?: string
}

const RecordingInfo = ({ title, icon: Icon, infos, url }: Props) => {
  return (
    <div className={scss.outer}>
      {Icon && <Icon className={scss.icon} />}
      <a className={scss.title} href={url} target="_blank" rel="noreferrer">
        {title}
      </a>
      {infos?.map((info) => (
        <span key={info} className={scss.info}>
          {' '}
          • {info}
        </span>
      ))}
    </div>
  )
}

export default RecordingInfo
