import cn from 'classnames'
import type { ReactElement, ReactNode, RefObject } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import BodyPortal from 'shared/graphics/atoms/BodyPortal'

import scss from './style.module.scss'

// Position his relative to the anchor point
// For corner positions child element flow will go to the opposite x direction
export const POSITION = {
  topStart: 'topStart',
  top: 'top',
  topEnd: 'topEnd',
  rightStart: 'rightStart',
  right: 'right',
  rightEnd: 'rightEnd',
  bottomEnd: 'bottomEnd',
  bottom: 'bottom',
  bottomStart: 'bottomStart',
  left: 'left',
} as const

export type Position = keyof typeof POSITION

interface Props {
  children: ReactNode
  data?: ReactElement
  position?: Position
  space?: number
  hidden?: boolean
  visible?: boolean
  anchorRef: RefObject<HTMLElement>
  offset?: number
}

const FloatingElement = ({ children, position = POSITION.top, space = 10, visible, anchorRef, offset = 0 }: Props) => {
  const floatingElementRef = useRef<HTMLDivElement>(null)
  const [coordinate, setCoordinate] = useState({ x: 0, y: 0, translate: { x: 0, y: 0 } })

  useEffect(() => {
    if (!visible) {
      return
    }
    const anchorRectInfo = anchorRef?.current?.getBoundingClientRect()
    const floatingElementRectInfo = floatingElementRef.current?.getBoundingClientRect()

    if (anchorRectInfo && floatingElementRectInfo) {
      const newCoordinate = getCoordinate({ defaultPosition: position, anchorRectInfo, floatingElementRectInfo, space, offset })
      setCoordinate((oldCoordinate) => ({ ...oldCoordinate, ...newCoordinate }))
    }
  }, [space, anchorRef, visible, position, offset])

  return (
    children && (
      <BodyPortal wrapperId="floating-element-wrapper">
        <CSSTransition
          in={visible}
          timeout={{ enter: 0, exit: 200 }}
          classNames={{ enterDone: scss.__enterDone, exit: scss.__exit }}
          unmountOnExit
          nodeRef={floatingElementRef}
        >
          <div
            ref={floatingElementRef}
            className={cn(scss.floatingElement)}
            style={{
              left: coordinate.x,
              top: coordinate.y,
              transform: `translate(${coordinate.translate.x}px, ${coordinate.translate.y}px)`,
            }}
          >
            {children}
          </div>
        </CSSTransition>
      </BodyPortal>
    )
  )
}

export default FloatingElement

type TCoordinate = { anchorRectInfo: DOMRect; floatingElementRectInfo: DOMRect; space: number; offset?: number }

const calculateTopCoordinate = ({ anchorRectInfo, floatingElementRectInfo, space }: TCoordinate) => ({
  x: anchorRectInfo.x + anchorRectInfo.width / 2 - floatingElementRectInfo.width / 2,
  y: anchorRectInfo.y - floatingElementRectInfo.height,
  translate: { x: 0, y: -space },
})

const calculateTopRightCoordinate = ({ anchorRectInfo, floatingElementRectInfo, space }: TCoordinate) => ({
  x: anchorRectInfo.x + anchorRectInfo.width - floatingElementRectInfo.width,
  y: anchorRectInfo.y - floatingElementRectInfo.height,
  translate: { x: 0, y: -space },
})

const calculateRightCoordinate = ({ anchorRectInfo, floatingElementRectInfo, space }: TCoordinate) => ({
  x: anchorRectInfo.x + anchorRectInfo.width,
  y: anchorRectInfo.y + anchorRectInfo.height / 2 - floatingElementRectInfo.height / 2,
  translate: { x: space, y: 0 },
})

const calculateRightTopCoordinate = ({ anchorRectInfo, space, offset = 0 }: TCoordinate) => ({
  x: anchorRectInfo.x + anchorRectInfo.width,
  y: anchorRectInfo.y,
  translate: { x: space, y: offset },
})

const calculateRightBottomCoordinate = ({ anchorRectInfo, floatingElementRectInfo, space }: TCoordinate) => ({
  x: anchorRectInfo.x + anchorRectInfo.width,
  y: anchorRectInfo.y + anchorRectInfo.height - floatingElementRectInfo.height,
  translate: { x: space, y: 0 },
})

const calculateBottomRightCoordinate = ({ anchorRectInfo, floatingElementRectInfo, space }: TCoordinate) => ({
  x: anchorRectInfo.x + anchorRectInfo.width - floatingElementRectInfo.width,
  y: anchorRectInfo.y + anchorRectInfo.height,
  translate: { x: 0, y: space },
})

const calculateBottomCoordinate = ({ anchorRectInfo, floatingElementRectInfo, space }: TCoordinate) => ({
  x: anchorRectInfo.x + anchorRectInfo.width / 2 - floatingElementRectInfo.width / 2,
  y: anchorRectInfo.y + anchorRectInfo.height,
  translate: { x: 0, y: space },
})

const calculateBottomLeftCoordinate = ({ anchorRectInfo, space }: TCoordinate) => ({
  x: anchorRectInfo.x,
  y: anchorRectInfo.y + anchorRectInfo.height,
  translate: { x: 0, y: space },
})

const calculateLeftCoordinate = ({ anchorRectInfo, floatingElementRectInfo, space }: TCoordinate) => ({
  x: anchorRectInfo.x - floatingElementRectInfo.width,
  y: anchorRectInfo.y + anchorRectInfo.height / 2 - floatingElementRectInfo.height / 2,
  translate: { x: -space, y: 0 },
})
const calculateTopLeftCoordinate = ({ anchorRectInfo, floatingElementRectInfo, space }: TCoordinate) => ({
  x: anchorRectInfo.x,
  y: anchorRectInfo.y - floatingElementRectInfo.height,
  translate: { x: 0, y: -space },
})

const getIfIsInViewport = ({ top, left, bottom, right }: { top: number; left: number; bottom: number; right: number }) =>
  top >= 0 &&
  left >= 0 &&
  bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
  right <= (window.innerWidth || document.documentElement.clientWidth)

const calculateCoordinate = ({
  position,
  anchorRectInfo,
  floatingElementRectInfo,
  space,
  offset,
}: {
  position?: Position
  anchorRectInfo: DOMRect
  floatingElementRectInfo: DOMRect
  space: number
  offset?: number
}) => {
  switch (position) {
    case POSITION.top:
      return calculateTopCoordinate({ anchorRectInfo, floatingElementRectInfo, space })
    case POSITION.topEnd:
      return calculateTopRightCoordinate({ anchorRectInfo, floatingElementRectInfo, space })
    case POSITION.right:
      return calculateRightCoordinate({ anchorRectInfo, floatingElementRectInfo, space })
    case POSITION.rightStart:
      return calculateRightTopCoordinate({ anchorRectInfo, floatingElementRectInfo, space, offset })
    case POSITION.rightEnd:
      return calculateRightBottomCoordinate({ anchorRectInfo, floatingElementRectInfo, space })
    case POSITION.bottom:
      return calculateBottomCoordinate({ anchorRectInfo, floatingElementRectInfo, space })
    case POSITION.bottomEnd:
      return calculateBottomRightCoordinate({ anchorRectInfo, floatingElementRectInfo, space })
    case POSITION.bottomStart:
      return calculateBottomLeftCoordinate({ anchorRectInfo, floatingElementRectInfo, space })
    case POSITION.left:
      return calculateLeftCoordinate({ anchorRectInfo, floatingElementRectInfo, space })
    case POSITION.topStart:
      return calculateTopLeftCoordinate({ anchorRectInfo, floatingElementRectInfo, space })
    default:
      return calculateTopCoordinate({ anchorRectInfo, floatingElementRectInfo, space })
  }
}

const positionList = Object.values(POSITION)

const getCoordinate = ({
  defaultPosition,
  anchorRectInfo,
  floatingElementRectInfo,
  space,
  lastPosition,
  offset,
}: {
  defaultPosition: Position
  anchorRectInfo: DOMRect
  floatingElementRectInfo: DOMRect
  space: number
  lastPosition?: Position
  offset?: number
}): { x: number; y: number; translate: { x: number; y: number } } => {
  const defaultPositionIndex = positionList.indexOf(defaultPosition)
  const positionIndex = lastPosition ? (positionList.indexOf(lastPosition) + 1) % positionList.length : defaultPositionIndex

  const coordinate = calculateCoordinate({ position: positionList[positionIndex], anchorRectInfo, floatingElementRectInfo, space, offset })

  // Note: If we have test all the positions return default
  if (defaultPositionIndex === positionIndex && !!lastPosition) {
    return coordinate
  }

  const isInViewport = getIfIsInViewport({
    top: coordinate.y,
    left: coordinate.x,
    bottom: coordinate.y + floatingElementRectInfo.height,
    right: coordinate.x + floatingElementRectInfo.width,
  })

  if (!isInViewport) {
    return getCoordinate({ defaultPosition, anchorRectInfo, floatingElementRectInfo, space, lastPosition: positionList[positionIndex], offset })
  }

  return coordinate
}
