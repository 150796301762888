import cn from 'classnames'
import React from 'react'
import Image from 'shared/graphics/icons/Image.svg?component'

import scss from './style.module.scss'

interface Props {
  image?: string
  disabled?: boolean
}

const HeaderCover = ({ image, disabled }: Props) => {
  return (
    <div className={cn(scss.outer, disabled && scss.isDisabled)}>{image ? <img className={scss.image} src={image} /> : <Image className={scss.icon} />}</div>
  )
}

export default HeaderCover
