import type { ReactNode } from 'react'
import React from 'react'

import scss from './style.module.scss'

interface Props {
  title: ReactNode
  subtitles?: ReactNode[]
}

const CellInfo = ({ title, subtitles }: Props) => {
  return (
    <div className={scss.outer}>
      <h1 className={scss.title}>{title}</h1>
      {subtitles?.map((subtitle, index) => (
        <div key={index} className={scss.subtitles}>
          {subtitle}
        </div>
      ))}
    </div>
  )
}

export default CellInfo
