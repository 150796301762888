import React from 'react'
import type { ReactNode } from 'react'

import scss from './style.module.scss'

interface Props {
  header: ReactNode
  children: ReactNode
}

const RecordingLayout = ({ children, header }: Props) => {
  return (
    <div className={scss.outer}>
      <div className={scss.header}>{header}</div>
      <div className={scss.inner}>{children}</div>
    </div>
  )
}

export default RecordingLayout
