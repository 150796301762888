import cn from 'classnames'
import type { ComponentType } from 'react'
import React from 'react'

import scss from './style.module.scss'

interface Props {
  title: string
  isActive?: boolean
  icon: ComponentType
}

const SidebarItem = ({ title, isActive, icon: Icon }: Props) => {
  return (
    <div className={cn(scss.item, isActive && scss.isActive)}>
      <div className={scss.item__icon}>
        <Icon />
      </div>
      <p className={scss.item__title}>{title}</p>
    </div>
  )
}

export default SidebarItem
