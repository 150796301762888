import React from 'react'
import type { ChangeEvent } from 'react'

import scss from './style.module.scss'

interface Props {
  defaultValue: number
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  min?: number
  max?: number
}

const Slider = ({ defaultValue, min = 0, max = 9, onChange }: Props) => {
  return (
    <div className={scss.slider}>
      <input type="range" min={min} max={max} value={defaultValue} onChange={onChange} />
    </div>
  )
}

export default Slider
