import type { ReactNode } from 'react'
import React from 'react'

import scss from './style.module.scss'

interface Props {
  title: ReactNode
  children: ReactNode
}

const FormEditionRow = ({ title, children }: Props) => {
  return (
    <div className={scss.outer}>
      <div className={scss.title}>{title}</div>
      <div className={scss.content}>{children}</div>
    </div>
  )
}

export default FormEditionRow
