import cn from 'classnames'
import React from 'react'
import DownloadIcon from 'shared/graphics/icons/Download.svg?component'

import scss from './style.module.scss'

interface Props {
  illustration: string
  tags: string[]
  duration?: number
  onClick?: () => void
  isActive?: boolean
  url?: string
}

const ClipItem = ({ illustration, tags, duration, url, onClick, isActive }: Props) => {
  return (
    <div
      className={cn(scss.outer, onClick && scss.isClickable, isActive && scss.isActive)}
      {...(!!onClick && { role: 'button', tabIndex: 0 })}
      onClick={onClick}
    >
      <div className={scss.cover}>
        <img className={scss.cover__illustration} src={illustration} />
        <span className={scss.cover__duration}>{duration} sec</span>
      </div>
      <div className={scss.footer}>
        <div className={scss.tags}>
          {!!tags[0] && <div className={scss.tags__item}>{tags[0]}</div>}
          {tags.length > 1 && <div className={scss.tags__item}>+{tags.length - 1}</div>}
        </div>
        {url && (
          <a href={url} className={scss.download}>
            <DownloadIcon className={scss.download__icon} />
          </a>
        )}

        {/** @todo add settings */}
      </div>
    </div>
  )
}

export default ClipItem
