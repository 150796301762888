import type { ReactNode } from 'react'
import React, { createContext, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SplashScreen from 'shared/graphics/organisms/SplashScreen'

import { useGetOrganizationProfileToken } from '../hooks/organization'

import type { Session } from './AuthProvider'
import { useAuth } from './AuthProvider'

export const RIGHTS = {
  CREATE_ORGANIZATION: 'create_organization',
  VIEW_ORGANIZATION: 'view_organization',
  UPDATE_ORGANIZATION: 'update_organization',
  LIST_ORGANIZATIONS: 'list_organizations',
  INVITE_ORGANIZATION_MEMBER: 'invite_organization_member',
  LIST_ORGANIZATION_MEMBERS: 'list_organization_members',
  PROMOTE_ORGANIZATION_MEMBER: 'promote_organization_member',
  REVOKE_ORGANIZATION_MEMBER: 'revoke_organization_member',
  UPDATE_ORGANIZATION_PROFILE: 'update_organization_profile',
  CREATE_CAMPAIGN: 'create_campaign',
  UPDATE_CAMPAIGN: 'update_campaign',
  DELETE_CAMPAIGN: 'delete_campaign',
  VIEW_CAMPAIGN: 'view_campaign',
  LIST_CAMPAIGNS: 'list_campaigns',
  LIST_CAMPAIGN_VIDEOS: 'list_campaign_videos',
  START_VIDEO_ANALYSIS: 'start_video_analysis',
  LIST_VIDEO_CLIPS: 'list_video_clips',
} as const

export type RightList = keyof typeof RIGHTS
export type RightListValues = (typeof RIGHTS)[RightList]

export interface Rights {
  rights?: string[]
  hasRight: (right: RightListValues) => boolean
  hasAllRights: (rights: RightListValues[]) => boolean
}

const RightsContext = createContext<Rights>({} as Rights)

interface RightsProviderProps {
  children: ReactNode
}

const RightsProvider = ({ children }: RightsProviderProps) => {
  const params = useParams()
  const { organizationId } = params
  const { setSession, session } = useAuth()

  const { mutate, isError, isPending, isIdle } = useGetOrganizationProfileToken({
    onSuccess: (data) => {
      const { organization_token, organization_rights } = data as { organization_token: string; organization_rights: string[] }
      setSession({ ...(session as Session), organizationToken: organization_token, rights: organization_rights })
    },
  })

  useEffect(() => {
    if (organizationId) {
      mutate({ organizationId })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  const rights = session?.rights

  const hasRight = (right: RightListValues) => {
    return !!rights?.includes(right)
  }

  const hasAllRights = (rights: RightListValues[]) => {
    return rights.every((right) => hasRight(right))
  }

  if (isError) {
    return 'error'
  }

  return (
    <RightsContext.Provider value={{ rights, hasRight, hasAllRights }}>{!rights || isPending || isIdle ? <SplashScreen /> : children}</RightsContext.Provider>
  )
}

export const useRights = () => {
  const rights = useContext(RightsContext)

  if (!rights) {
    throw new Error('rights context should be provided.')
  }

  return rights
}

export default RightsProvider
