import cn from 'classnames'
import type { ComponentType, KeyboardEventHandler, Ref } from 'react'
import React, { forwardRef, useState } from 'react'
import type {
  ClearIndicatorProps,
  ControlProps,
  GroupBase,
  InputProps,
  MultiValue,
  MultiValueGenericProps,
  MultiValueProps,
  MultiValueRemoveProps,
  PlaceholderProps,
  Props,
  SelectInstance,
  ValueContainerProps,
} from 'react-select'
import { components } from 'react-select'
import Creatable from 'react-select/creatable'
import CloseIcon from 'shared/graphics/icons/Close.svg?component'

import scss from './style.module.scss'

export interface OptionLocal {
  readonly label: string
  readonly value: string
}

const createOption = (label: string) => ({
  label,
  value: label,
})

export interface LocalProps extends Props<OptionLocal, true, GroupBase<OptionLocal>> {
  onChange?: (value: MultiValue<OptionLocal> | undefined) => void
  onNewOptionValidate?: (newOption: string) => boolean
  onInputChange?: (newValue: string) => void
  error?: string
  hasError?: boolean
  iconBefore?: ComponentType
  value?: MultiValue<OptionLocal> | undefined
}

const SelectCreatable = (props: LocalProps, ref: Ref<SelectInstance<OptionLocal, true, GroupBase<OptionLocal>>>) => {
  const { onChange, value, onNewOptionValidate, error, onInputChange, hasError } = props

  const [inputValue, setInputValue] = useState('')

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab': {
        if (onNewOptionValidate && !onNewOptionValidate?.(inputValue)) return
        const array = [...(value ? value : []), createOption(inputValue)]
        onChange?.(array.filter((element, index) => index === array.findIndex((o) => element.value === o.value)))
        setInputValue('')
        event.preventDefault()
      }
    }
  }

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue)
    onInputChange?.(newValue)
  }

  return (
    <div className={scss.outer}>
      <Creatable
        ref={ref}
        {...props}
        className={cn(scss.creatable, (!!error || hasError) && scss.isError)}
        components={{
          DropdownIndicator: null,
          Control: CustomControl,
          Placeholder: CustomPlaceholder,
          ValueContainer: CustomValueContainer,
          Input: CustomInput,
          MultiValueContainer: CustomMultiValueContainer,
          MultiValue: CustomMultiValue,
          MultiValueLabel: CustomMultiValueLabel,
          MultiValueRemove: CustomMultiValueRemove,
          ClearIndicator: CustomClearIndicator,
        }}
        isMulti
        inputValue={inputValue}
        isClearable
        menuIsOpen={false}
        onChange={onChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        value={value}
        defaultValue={value}
      />
      {error && <span className={scss.error}>{error}</span>}
    </div>
  )
}

const CustomControl = ({ children, ...props }: ControlProps<OptionLocal>) => {
  const { isFocused, isDisabled } = props

  return (
    <components.Control {...props} className={cn(scss.control, isFocused && scss.isFocused, isDisabled && scss.isDisabled)}>
      {children}
    </components.Control>
  )
}

const CustomPlaceholder = ({ children, ...props }: PlaceholderProps<OptionLocal>) => {
  return (
    <components.Placeholder {...props} className={scss.placeholder}>
      {children}
    </components.Placeholder>
  )
}

const CustomInput = ({ children, ...props }: InputProps<OptionLocal>) => {
  return (
    <components.Input {...props} className={scss.input}>
      {children}
    </components.Input>
  )
}

const CustomValueContainer = ({ children, ...props }: ValueContainerProps<OptionLocal>) => {
  const { selectProps } = props
  // @ts-expect-error
  const IconBefore = selectProps.iconBefore
  return (
    <components.ValueContainer {...props} className={cn(scss.valueContainer, IconBefore && scss.hasBeforeIcon)}>
      {!!IconBefore && <IconBefore className={scss.icon} />}
      {children}
    </components.ValueContainer>
  )
}

const CustomMultiValueContainer = ({ children }: MultiValueGenericProps) => {
  return <div className={scss.multiValueContainer}>{children}</div>
}

const CustomMultiValue = ({ children, ...props }: MultiValueProps<OptionLocal>) => {
  return (
    <components.MultiValue {...props} className={scss.multiValue}>
      {children}
    </components.MultiValue>
  )
}

const CustomMultiValueLabel = ({ children }: MultiValueGenericProps) => {
  return <span className={scss.multiValueLabel}>{children}</span>
}

const CustomMultiValueRemove = ({ ...props }: MultiValueRemoveProps<OptionLocal>) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon className={scss.multiValueRemove__icon} />
    </components.MultiValueRemove>
  )
}

const CustomClearIndicator = ({ ...props }: ClearIndicatorProps<OptionLocal>) => {
  return (
    <components.ClearIndicator {...props} className={scss.clearIndicator}>
      <CloseIcon />
    </components.ClearIndicator>
  )
}

export default forwardRef(SelectCreatable)
