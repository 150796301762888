import type { ReactNode } from 'react'
import React from 'react'

import scss from './style.module.scss'

interface Props {
  children: ReactNode
}

const LoginLayout = ({ children }: Props) => {
  return (
    <div className={scss.outer}>
      <div className={scss.inner}>{children}</div>
    </div>
  )
}

export default LoginLayout
