import React from 'react'
import type { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import TwitchIcon from 'shared/graphics/icons/Twitch.svg?component'
import YoutubeIcon from 'shared/graphics/icons/Youtube.svg?component'
import { formatDateToNow } from 'shared/helpers/formatDateToNow'
import parseChannelUrl from 'shared/helpers/parseChannelUrl'

import ProfilePicture from 'src/graphics/molecules/ProfilePicture'
import RecordingInfo from 'src/graphics/molecules/RecordingInfo'

import scss from './style.module.scss'

const ICONS = {
  youtube: YoutubeIcon,
  twitch: TwitchIcon,
} as const

type Icons = keyof typeof ICONS
interface Props {
  title?: string
  channelName?: string
  dateS?: number
  views?: number
  url?: string
  action?: ReactNode
}

const CampaignClipHeader = ({ channelName, dateS, title, views, url, action }: Props) => {
  const intl = useIntl()
  return (
    <div className={scss.outer}>
      <div className={scss.profile}>
        <ProfilePicture src="" size={48} text={channelName?.substring(0, 1).toUpperCase()} />
      </div>
      <div className={scss.header}>
        <h1 className={scss.header__title}>{title}</h1>
        <RecordingInfo
          title={channelName}
          icon={(url && ICONS[parseChannelUrl(url)?.source as Icons]) || undefined}
          infos={[
            formatDateToNow({ formatter: intl, dateS }),
            `${views} ${intl.formatMessage({ defaultMessage: '{viewCount, plural, =0 {view} one {views} other {views}}' }, { viewCount: views })}`,
          ]}
          url={url}
        />
      </div>
      {action && <div className={scss.action}>{action}</div>}
    </div>
  )
}

export default CampaignClipHeader
