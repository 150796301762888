import React from 'react'

import MainLayout from 'src/templates/MainLayout'
import NotFoundLayout from 'src/templates/NotFoundLayout'

import NotFound from 'src/graphics/organisms/NotFound'

const NotFoundPage = () => {
  return (
    <MainLayout>
      <NotFoundLayout>
        <NotFound />
      </NotFoundLayout>
    </MainLayout>
  )
}

export default NotFoundPage
