import React, { useEffect, useRef, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import {
  useCreateOrganizationMembers,
  useGetOrganization,
  useReGetOrganizationMembers,
  useUpdateOrganization,
  useUpdateOrganizationLogo,
} from 'shared/api/hooks/organization'
import { RIGHTS, useRights } from 'shared/api/providers/RightsProvider'

import ContentLayout from 'src/templates/ContentLayout'
import LoadingLayout from 'src/templates/LoadingLayout'
import MainLayout from 'src/templates/MainLayout'
import OrganizationLayout from 'src/templates/OrganizationLayout'

import Button from 'src/graphics/atoms/Button'
import ContentHero from 'src/graphics/molecules/ContentHero'
import Profile from 'src/graphics/molecules/ContentHero/images/Profil.png'
import OrganizationHeader from 'src/graphics/molecules/OrganizationHeader'
import type { OrganizationFormValues } from 'src/graphics/organisms/OrganizationForm'
import OrganizationForm from 'src/graphics/organisms/OrganizationForm'
import type { OrganizationInvitationValues } from 'src/graphics/organisms/OrganizationInvitation'
import OrganizationInvitation from 'src/graphics/organisms/OrganizationInvitation'
import OrganizationMemberList from 'src/graphics/organisms/OrganizationMembersList'

import NotFound from '../NotFound'

const FAKE_REFETCH_TIMEOUT = 2000

const OrganizationCheck = () => {
  const { organizationId } = useParams()

  if (!organizationId) {
    return <NotFound />
  }

  return <Organization organizationId={organizationId} />
}

interface Props {
  organizationId: string
}

const Organization = ({ organizationId }: Props) => {
  const intl = useIntl()
  const { hasRight } = useRights()

  // update image take time to be updated, so we fake a loading state
  const [fakeRefetchOrganizationLoading, setFakeRefetchOrganizationLoading] = useState<boolean>(false)
  const refetchOrganizationTimeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => {
      if (refetchOrganizationTimeout.current) clearTimeout(refetchOrganizationTimeout.current)
    }
  }, [])

  const reGetOrganizationMembers = useReGetOrganizationMembers()
  const [isEditModeHeader, setIsEditModeHeader] = useState<boolean>(false)
  const {
    data: organization,
    refetch: refetchOrganization,
    isLoading: getOrganizationIsLoading,
    isError: getOrganizationError,
  } = useGetOrganization({ id: organizationId })
  const { mutateAsync: updateOrganization, isPending: updateOrganizationLoading } = useUpdateOrganization()
  const { mutateAsync: updateOrganizationLogo, isPending: updateOrganizationLogoLoading } = useUpdateOrganizationLogo()
  const { mutate: createOrganizationMembers } = useCreateOrganizationMembers({
    onSuccess: () => {
      refetchOrganization()
      reGetOrganizationMembers()
    },
  })

  const handleUpdateOrganizationSubmit: SubmitHandler<OrganizationFormValues> = async (organizationFormData: OrganizationFormValues) => {
    if (organization) {
      setFakeRefetchOrganizationLoading(true)
      await updateOrganization({ id: organization.id, name: organizationFormData.organizationName, website: organizationFormData.organizationWebsite })
      if (organizationFormData.organizationLogo) {
        await updateOrganizationLogo({ organizationId, file: organizationFormData.organizationLogo })
      }
      refetchOrganizationTimeout.current = setTimeout(async () => {
        refetchOrganization()
        setIsEditModeHeader(false)
        setFakeRefetchOrganizationLoading(false)
      }, FAKE_REFETCH_TIMEOUT)
    }
  }

  const handleOrganizationInvitationSubmit: SubmitHandler<OrganizationInvitationValues> = (organizationInvitationData: OrganizationInvitationValues) => {
    const emails = organizationInvitationData.emails.map((email) => email.value)
    createOrganizationMembers({ id: organizationId, emails: emails })
  }

  const handleCancelUpdateOrganization = () => {
    setIsEditModeHeader(false)
  }

  if (getOrganizationIsLoading) {
    return <LoadingLayout />
  }

  if (getOrganizationError || !organization) {
    return <NotFound />
  }

  const { name, logo, website, members } = organization

  return (
    <MainLayout>
      <ContentLayout>
        <OrganizationLayout>
          {isEditModeHeader ? (
            <OrganizationForm
              name={name}
              logoUrl={logo}
              website={website}
              onCancel={handleCancelUpdateOrganization}
              onSubmit={handleUpdateOrganizationSubmit}
              loading={updateOrganizationLoading || updateOrganizationLogoLoading || fakeRefetchOrganizationLoading}
            />
          ) : (
            <OrganizationHeader
              name={name}
              logoUrl={logo}
              website={website}
              actions={
                hasRight(RIGHTS.UPDATE_ORGANIZATION) && (
                  <Button color="purple" onClick={() => setIsEditModeHeader(true)}>
                    <FormattedMessage defaultMessage="Edit" />
                  </Button>
                )
              }
            />
          )}
          {hasRight(RIGHTS.INVITE_ORGANIZATION_MEMBER) && (
            <>
              {!members?.length && <ContentHero title={intl.formatMessage({ defaultMessage: 'Now let’s add your team members' })} illustrationUrl={Profile} />}
              <OrganizationInvitation organizationName={name} onSubmit={handleOrganizationInvitationSubmit} />
            </>
          )}
          {hasRight(RIGHTS.LIST_ORGANIZATION_MEMBERS) && <OrganizationMemberList organization={organization} />}
        </OrganizationLayout>
      </ContentLayout>
    </MainLayout>
  )
}

export default OrganizationCheck
