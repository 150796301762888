import React from 'react'
import LogoAnimation from 'shared/graphics/molecules/LogoAnimation'

import scss from './style.module.scss'

const SplashScreen = () => {
  return (
    <div className={scss.outer}>
      <div className={scss.inner}>
        <LogoAnimation />
      </div>
    </div>
  )
}

export default SplashScreen
