import type { IntlShape } from 'react-intl'

export const PRICING = [
  { minutes: 4800, pricePerMonth: 464, storage: 133, licenses: 10 },
  { minutes: 9600, pricePerMonth: 928, storage: 267, licenses: 20 },
  { minutes: 14400, pricePerMonth: 1392, storage: 400, licenses: 30 },
  { minutes: 19200, pricePerMonth: 1856, storage: 533, licenses: 40 },
  { minutes: 24000, pricePerMonth: 2320, storage: 667, licenses: 50 },
  { minutes: 28800, pricePerMonth: 2784, storage: 800, licenses: 60 },
  { minutes: 33600, pricePerMonth: 3248, storage: 933, licenses: 70 },
  { minutes: 38400, pricePerMonth: 3712, storage: 1067, licenses: 80 },
  { minutes: 43200, pricePerMonth: 4176, storage: 1200, licenses: 90 },
  { minutes: 48000, pricePerMonth: 4640, storage: 1333, licenses: 100 },
]

export const formatCurrencyPerTime = ({ amount, time, intl }: { amount: number; time: 'month' | 'minute'; intl: IntlShape }) => {
  const numberWithCurrency = intl.formatNumber(amount, { currency: 'USD', style: 'currency', maximumFractionDigits: time === 'month' ? 0 : 2 })
  const unit = intl.formatMessage({ defaultMessage: '/{month}' }, { month: time })
  return `${numberWithCurrency}${unit}`
}
