import { rest_AnalysisStatus as AnalysisStatus } from '@powdergg/json-verse-api/dist/generated-axios-client/models/rest_AnalysisStatus'
import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useGetCampaign, useGetCampaignRecordings } from 'shared/api/hooks/organization'
import { RIGHTS, useRights } from 'shared/api/providers/RightsProvider'
import type { GetPlatformDataFromUrl } from 'shared/helpers/parseChannelUrl'
import parseChannelUrl, { getPlatformDataFromUrl } from 'shared/helpers/parseChannelUrl'
import useLocalStorage from 'shared/hooks/useLocalStorage'

import { ROUTES_PATH } from 'src/config'
import NotFound from 'src/pages/NotFound'
import ContentLayout from 'src/templates/ContentLayout'
import LoadingLayout from 'src/templates/LoadingLayout'
import MainLayout from 'src/templates/MainLayout'
import RecordingLayout from 'src/templates/RecordingLayout'

import DropDownItem from 'src/graphics/atoms/DropDownItem'
import CampaignRecordingsHeader from 'src/graphics/molecules/CampaignRecordingsHeader'
import ContentHero from 'src/graphics/molecules/ContentHero'
import Clock from 'src/graphics/molecules/ContentHero/images/Clock.png'
import Error from 'src/graphics/molecules/Error'
import FilterLayout from 'src/graphics/molecules/FilterLayout'
import FilterSelection from 'src/graphics/molecules/FilterSelection'
import HideSelection from 'src/graphics/molecules/HideSelection'
import type { FilteredRecording } from 'src/graphics/organisms/CampaignRecordingList'
import CampaignRecordingList from 'src/graphics/organisms/CampaignRecordingList'

const REFETCHING_INTERVAL = 300000

const ANALYSIS_STATUS_FILTER = [
  { label: 'Ready to analyze', value: AnalysisStatus.AnalysisStatusPending },
  { label: 'Analyzing', value: AnalysisStatus.AnalysisStatusProcessing },
  { label: 'Analysis finished', value: AnalysisStatus.AnalysisStatusDone },
  { label: 'Re-analyze', value: AnalysisStatus.AnalysisStatusFailed },
]

const CampaignCheck = () => {
  const { campaignId, organizationId } = useParams()

  if (!organizationId || !campaignId) {
    return <NotFound />
  }

  return <Campaign organizationId={organizationId} campaignId={campaignId} />
}

interface Props {
  organizationId: string
  campaignId: string
}

const Campaign = ({ campaignId, organizationId }: Props) => {
  const { data: campaign, isFetching: getCampaignFetching, isError: getCampaignError } = useGetCampaign({ organizationId, campaignId })
  const {
    data: campaignRecordings,
    isFetching: getCampaignRecordingsFetching,
    isError: getCampaignRecordingsError,
    refetch: refetchGetCampaignRecording,
  } = useGetCampaignRecordings({ organizationId, campaignId }, { refetchInterval: REFETCHING_INTERVAL })
  const { hasRight } = useRights()
  const [localStorageRecordingsFilter, setLocalStorageRecordingsFilter] = useLocalStorage<Record<string, string[]>>('recordingsFilter', {})
  const [showHidden, setShowHidden] = useState<boolean>(false)

  const [searchParams, setSearchParams] = useSearchParams()

  const channelsFilterValue = searchParams.get('channel') || undefined
  const platformFilterValue = searchParams.get('platform') || undefined
  const analysisStatusFilterValue = searchParams.get('status') || undefined

  const toggleRecordingVisibility = ({ recordingId, campaignId }: { recordingId: string; campaignId: string }) => {
    setLocalStorageRecordingsFilter((prevState: Record<string, string[]>) => {
      const result = {
        ...prevState,
        [campaignId]: prevState[campaignId]
          ? prevState[campaignId].some((id) => recordingId === id)
            ? prevState[campaignId].filter((id) => id !== recordingId)
            : [...prevState[campaignId], recordingId]
          : [recordingId],
      }

      const myCampaign = result[campaignId]

      if (myCampaign.length === 0) {
        setShowHidden(false)
      }
      return result
    })
  }

  const deleteSearchParamsByKey = (key: string) => {
    setSearchParams((prev) => {
      prev.delete(key)
      return prev
    })
  }

  const updateSearchParams = (key: string, value: string) => {
    setSearchParams((prev) => {
      prev.set(key, value)
      return prev
    })
  }

  /**
   * Filtering
   */

  const analysisStatusList = ANALYSIS_STATUS_FILTER.filter(({ value }) => campaignRecordings?.videos?.some(({ analysis_status }) => analysis_status === value))

  const channelList = campaignRecordings?.videos?.map(({ channel_title }) => channel_title).filter((item, pos, array) => array.indexOf(item) == pos) as string[]

  const platformList = campaignRecordings?.videos
    ?.map(({ url }) => getPlatformDataFromUrl(url))
    .reduce<GetPlatformDataFromUrl[]>((acc, cur) => {
      if (acc.some(({ value }) => value === cur?.value)) {
        return [...acc]
      }
      return [...acc, cur]
    }, [])

  const hiddenRecordingsIdList = localStorageRecordingsFilter?.[campaignId]
  const filteredRecordings = campaignRecordings?.videos?.reduce<FilteredRecording[]>((acc, curr) => {
    if (!curr.id || !curr.url) {
      return [...acc]
    }
    const isRecordingHidden = hiddenRecordingsIdList?.some((id) => id === curr.id)
    const recordingPlatform = parseChannelUrl(curr.url)
    const formattedRecording = {
      ...curr,
      isHidden: isRecordingHidden,
      platform: recordingPlatform,
    }

    // Apply Analysis Status filter
    if (formattedRecording.analysis_status && analysisStatusFilterValue && formattedRecording.analysis_status !== analysisStatusFilterValue) {
      return [...acc]
    }

    // Apply Channels filter
    if (formattedRecording.channel_title && channelsFilterValue && formattedRecording.channel_title !== channelsFilterValue) {
      return [...acc]
    }

    // Apply Platform filter
    if (formattedRecording.platform && platformFilterValue && formattedRecording.platform.source !== platformFilterValue) {
      return [...acc]
    }

    return [...acc, formattedRecording]
  }, [])

  const enableHiddenToggle = filteredRecordings?.some((recording) => recording.id && hiddenRecordingsIdList?.includes(recording.id))

  /**
   * This use effect is set to update the showHidden value properly and use the filtered recordings list and not just the value in local storage
   * How to reproduce the bug
   *  1- Go to a campaign with 2 channels
   *  2- Hide some recording from channel A
   *  3- Filter to display recordings from channel B
   *  4- Hide only one recording and toggle "Show hidden"
   *  5- Reveal the recording you just hide, the showHidden should be set to false , without useEffect it's not the case
   *
   */
  useEffect(() => {
    if (filteredRecordings?.filter((recording) => recording.isHidden).length === 0) {
      setShowHidden(false)
    }
  }, [filteredRecordings])

  const navigate = useNavigate()

  const generateCampaignStatus = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const dateTimeS = new Date().getTime() / 1000
    const start = new Date(startDate).getTime() / 1000
    const end = new Date(endDate).getTime() / 1000

    return dateTimeS > start && dateTimeS < end ? 'active' : dateTimeS < start ? 'upcoming' : 'completed'
  }

  const handleBack = () => {
    navigate(generatePath(ROUTES_PATH.CAMPAIGN_LIST, { organizationId }))
  }

  if (getCampaignFetching || getCampaignRecordingsFetching || !campaign) {
    return <LoadingLayout />
  }

  if (getCampaignError || getCampaignRecordingsError || !campaignRecordings?.videos) {
    return (
      <MainLayout>
        <ContentLayout>
          <Error />
        </ContentLayout>
      </MainLayout>
    )
  }

  const { name, startDate, endDate, keywords, logoUrl } = campaign

  return (
    <MainLayout>
      <ContentLayout onBack={handleBack}>
        <RecordingLayout
          header={
            <>
              <CampaignRecordingsHeader text={name} status={generateCampaignStatus({ startDate, endDate })} keywords={keywords} logoUrl={logoUrl} />
              <FilterLayout
                filters={
                  <>
                    <FilterSelection
                      label="Status"
                      selected={analysisStatusList.find((el) => el.value === analysisStatusFilterValue)?.label || <FormattedMessage defaultMessage="All" />}
                      disabled={!campaignRecordings?.videos.length}
                    >
                      <DropDownItem
                        onClick={() => {
                          deleteSearchParamsByKey('status')
                        }}
                        active={!analysisStatusFilterValue}
                      >
                        <FormattedMessage defaultMessage="All" />
                      </DropDownItem>
                      {analysisStatusList.map(({ label, value }, index) => (
                        <DropDownItem key={index} onClick={() => updateSearchParams('status', value)} active={analysisStatusFilterValue === value}>
                          <FormattedMessage defaultMessage={'{value}'} values={{ value: label }} />
                        </DropDownItem>
                      ))}
                    </FilterSelection>
                    <FilterSelection
                      label="Channels"
                      selected={channelsFilterValue || <FormattedMessage defaultMessage="All" />}
                      disabled={!campaignRecordings?.videos.length}
                    >
                      <DropDownItem
                        onClick={() => {
                          deleteSearchParamsByKey('channel')
                        }}
                        active={!channelsFilterValue}
                      >
                        <FormattedMessage defaultMessage="All" />
                      </DropDownItem>
                      {channelList?.map((channel, index) => (
                        <DropDownItem key={index} onClick={() => updateSearchParams('channel', channel)} active={channelsFilterValue === channel}>
                          {channel}
                        </DropDownItem>
                      ))}
                    </FilterSelection>

                    <FilterSelection
                      label="Platform"
                      selected={platformFilterValue || <FormattedMessage defaultMessage="All" />}
                      disabled={!campaignRecordings?.videos.length}
                    >
                      <DropDownItem
                        onClick={() => {
                          deleteSearchParamsByKey('platform')
                        }}
                        active={!platformFilterValue}
                      >
                        <FormattedMessage defaultMessage="All" />
                      </DropDownItem>
                      {platformList?.map(({ value, label, icon }, index) => (
                        <DropDownItem key={index} onClick={() => updateSearchParams('platform', value)} icon={icon} active={platformFilterValue === value}>
                          {label}
                        </DropDownItem>
                      ))}
                    </FilterSelection>
                  </>
                }
                action={<HideSelection showHidden={showHidden} onChange={() => setShowHidden(!showHidden)} disabled={!enableHiddenToggle} />}
              />
              {!campaignRecordings?.videos.length && (
                <ContentHero illustrationUrl={Clock} title={<FormattedMessage defaultMessage={'Currently waiting for new streams, stay tuned!'} />} />
              )}
            </>
          }
        >
          {hasRight(RIGHTS.LIST_CAMPAIGN_VIDEOS) && campaignRecordings?.videos.length && filteredRecordings && (
            <CampaignRecordingList
              recordings={showHidden ? filteredRecordings : filteredRecordings.filter((recording) => !recording.isHidden)}
              organizationId={organizationId}
              campaignId={campaignId}
              refetchCampaignRecordingList={refetchGetCampaignRecording}
              onToggleVisibility={toggleRecordingVisibility}
            />
          )}
        </RecordingLayout>
      </ContentLayout>
    </MainLayout>
  )
}

export default CampaignCheck
