import React from 'react'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'

import StartSignIn from 'src/pages/StartSignIn'

import { RIGHTS_ROUTES, ROUTES_PATH } from './config'
import ProtectedRightsRoutes from './logics/ProtectedRightsRoutes'
import ProtectedRoute from './logics/ProtectedRoute'
import RightRoute from './logics/RightRoute'
import RootRouteElement from './logics/RootRouteElement'
import Home from './pages/Home'
import Join from './pages/Join'
import NotFound from './pages/NotFound'

export type RoutesPath = keyof typeof ROUTES_PATH
export type RoutePath = (typeof ROUTES_PATH)[RoutesPath]

const App = () => {
  return (
    <>
      <RouterProvider
        router={createBrowserRouter(
          createRoutesFromElements(
            <Route element={<RootRouteElement />}>
              <Route path={ROUTES_PATH.SIGN_IN} element={<StartSignIn />} />
              <Route path={ROUTES_PATH.JOIN} element={<Join />} />
              <Route path={ROUTES_PATH.NOT_FOUND} element={<NotFound />} />
              <Route path={ROUTES_PATH.HOME} element={<ProtectedRoute redirection={ROUTES_PATH.SIGN_IN} />}>
                <Route path={ROUTES_PATH.HOME} element={<Home />} />
                <Route element={<ProtectedRightsRoutes />}>
                  {RIGHTS_ROUTES.map(({ element: Element, rights, ...route }) => (
                    <Route
                      {...route}
                      key={route.path}
                      element={
                        <RightRoute rights={rights}>
                          <Element />
                        </RightRoute>
                      }
                    />
                  ))}
                </Route>
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
          )
        )}
      />
    </>
  )
}

export default App
