import React from 'react'
import { generatePath } from 'react-router'
import { Navigate } from 'react-router-dom'
import { useGetUserOrganizationSubscription, useGetUserOrganizations } from 'shared/api/hooks/organization'
import { useAuth } from 'shared/api/providers/AuthProvider'
import useLocalStorage from 'shared/hooks/useLocalStorage'

import { ROUTES_PATH } from 'src/config'
import ContentLayout from 'src/templates/ContentLayout'
import LoadingLayout from 'src/templates/LoadingLayout'
import MainLayout from 'src/templates/MainLayout'

import Error from 'src/graphics/molecules/Error'

import OrganizationAdd from '../Organization/OrganizationAdd'
import Paywall from '../Paywall'

const Home = () => {
  const { sessionIsLoading } = useAuth()
  const {
    data: userOrganizations,
    isFetching: getOrganizationsFetching,
    isError: getOrganizationsError,
  } = useGetUserOrganizations({ enabled: !sessionIsLoading })
  const {
    data: userOrganizationSubscription,
    isFetching: getUserOrganizationSubscriptionFetching,
    isError: getUserOrganizationSubscriptionError,
  } = useGetUserOrganizationSubscription()

  const [localOrganizationId] = useLocalStorage('organizationId', null)

  if (getOrganizationsFetching || sessionIsLoading || getUserOrganizationSubscriptionFetching) {
    return <LoadingLayout menuHidden />
  }

  if (getOrganizationsError || getUserOrganizationSubscriptionError) {
    return (
      <MainLayout menuHidden>
        <ContentLayout>
          <Error />
        </ContentLayout>
      </MainLayout>
    )
  }

  // redirect to the last organization visited
  if (!!userOrganizations?.length && userOrganizations.some((organization) => organization.id === localOrganizationId)) {
    return <Navigate to={generatePath(ROUTES_PATH.ORGANIZATION, { organizationId: localOrganizationId })} />
  }

  // redirect to the first organization of the list by default
  if (!!userOrganizations?.length && userOrganizations[0].id) {
    return <Navigate to={generatePath(ROUTES_PATH.ORGANIZATION, { organizationId: userOrganizations[0].id })} />
  }

  if (!userOrganizationSubscription) {
    return <Paywall />
  }

  return <OrganizationAdd />
}

export default Home
