import cn from 'classnames'
import type { ComponentType, ReactNode } from 'react'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { CellInfo, CellTitle, Row } from 'src/graphics/molecules/list'
import Illustration from 'src/graphics/molecules/list/images/illustration.png'

import scss from './style.module.scss'

interface Props {
  thumbnail?: string
  picture?: ReactNode
  name: string
  streamInfo?: string
  streamDate?: ReactNode
  streamIcon?: ComponentType<{ className?: string | undefined }>
  views: string
  mention?: ReactNode
  action?: ReactNode
  settings: ReactNode
  isHidden?: boolean
  onClick?: () => void
}

const RecordingRow = ({ thumbnail, picture, name, streamInfo, streamDate, streamIcon, views, mention, action, settings, isHidden, onClick }: Props) => {
  const [thumbnailSrc, setThumbnailSrc] = useState<string>(thumbnail || Illustration)
  const [thumbnailSrcFailed, setThumbnailSrcFailed] = useState<boolean>(!thumbnail)

  const handleThumbnailError = () => {
    setThumbnailSrcFailed(true)
    setThumbnailSrc(Illustration)
  }
  const intl = useIntl()

  /**
   * @todo
   * we d'ont use grid correctly. We can't have the same behavior of a table like that.
   * We need to rework this.
   */

  return (
    <Row gridTemplateColumns="172px 1fr" gridTemplateRows="98px" noPadding noGap lowOpacity={isHidden} onClick={onClick}>
      <div className={scss.thumbnail}>
        <img src={thumbnailSrc} className={cn(scss.thumbnail__illustration, thumbnailSrcFailed && scss.emptyState)} onError={handleThumbnailError} />
      </div>
      <div className={scss.content}>
        <div className={scss.profile}>{picture}</div>
        <div className={scss.name}>
          <CellTitle
            title={name}
            subtitle={
              <>
                {streamInfo} {' • '} {streamDate}
              </>
            }
            subtitleIcon={streamIcon}
          />
        </div>
        <div className={scss.channel}>
          <CellInfo title={views} subtitles={[intl.formatMessage({ defaultMessage: 'Views' })]} />
        </div>
        <div className={scss.mention}>
          <CellInfo title={mention} subtitles={[intl.formatMessage({ defaultMessage: 'Mentions' })]} />
        </div>
        <div className={scss.action}>{action}</div>
        {settings}
      </div>
    </Row>
  )
}

export default RecordingRow
