import cn from 'classnames'
import React from 'react'

import scss from './style.module.scss'

interface Props {
  text?: string
  src?: string
  radius?: string | number
  size?: number
}
const ProfilePicture = ({ text, src, radius = '50%', size }: Props) => {
  return (
    <div className={cn(scss.outer, !src && scss.noSource)} style={{ borderRadius: radius, ...(size && { height: size, width: size }) }}>
      {src && <img className={scss.picture} src={src} />}
      {text && <span className={scss.text}>{text}</span>}
    </div>
  )
}

export default ProfilePicture
