import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import type { IntlShape } from 'react-intl'
import { FormattedMessage, useIntl } from 'react-intl'
import type { MultiValue } from 'react-select'
import { object, string } from 'zod'

import Button from 'src/graphics/atoms/Button'
import ControllerSelectCreatable from 'src/graphics/molecules/ControllerSelectCreatable'

import scss from './style.module.scss'

const schema = ({ intl }: { intl: IntlShape }) =>
  object({
    emails: object({ label: string(), value: string().email() })
      .array()
      .min(1, intl.formatMessage({ defaultMessage: 'Please enter an email' })),
  })

export interface OrganizationInvitationValues {
  emails: MultiValue<{ label: string; value: string }>
}

interface Props {
  organizationName: string
  onSubmit: (data: OrganizationInvitationValues) => void
}

const OrganizationInvitation = ({ organizationName, onSubmit }: Props) => {
  const intl = useIntl()
  const {
    control,
    formState: { errors, isSubmitSuccessful },
    handleSubmit: handleSubmitHook,
    reset,
  } = useForm<OrganizationInvitationValues>({
    defaultValues: {
      emails: [],
    },
    resolver: zodResolver(schema({ intl })),
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful])

  return (
    <div className={scss.outer}>
      <div className={scss.title}>
        <FormattedMessage
          defaultMessage={'Invite your team to join {organization}'}
          values={{
            organization: organizationName,
          }}
        />
      </div>
      <form className={scss.form} onSubmit={handleSubmitHook(onSubmit)}>
        <div className={scss.select}>
          <ControllerSelectCreatable control={control} name="emails" inputProps={{ placeholder: 'team@gmail.com', hasError: !!errors.emails }} />
        </div>
        <Button>
          <FormattedMessage defaultMessage="Invite" />
        </Button>
      </form>
    </div>
  )
}

export default OrganizationInvitation
