import React, { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import useLocalStorage from 'shared/hooks/useLocalStorage'

const RootRouteElement = () => {
  const [, setLocalOrganizationId] = useLocalStorage<string | null>('organizationId', null)

  const { organizationId } = useParams()

  useEffect(() => {
    setLocalOrganizationId(organizationId || null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  return <Outlet />
}

export default RootRouteElement
