import type { FormEvent } from 'react'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import Button from 'src/graphics/atoms/Button'
import Input from 'src/graphics/atoms/Input'
import LandingForm from 'src/graphics/organisms/LandingForm'

import scss from './style.module.scss'

interface Props {
  onSubmitAction: (event: FormEvent) => void
  isError?: boolean
  isPending?: boolean
}

const EmailForm = ({ isError, isPending, onSubmitAction }: Props) => {
  const intl = useIntl()
  return (
    <LandingForm title={<FormattedMessage defaultMessage={'Join Powder today!'} description="Title of sign in form" />}>
      <form onSubmit={onSubmitAction} className={scss.form}>
        <div className={scss.form__input}>
          <Input
            placeholder={isError ? intl.formatMessage({ defaultMessage: 'Enter a valid email' }) : intl.formatMessage({ defaultMessage: 'Enter email' })}
            type="email"
            name="email"
            isError={isError}
          />
        </div>
        <Button width={225} color="purple" type="submit" disabled={isPending}>
          <FormattedMessage defaultMessage={'Sign up with Email'} />
        </Button>
      </form>
    </LandingForm>
  )
}

export default EmailForm
