import type { FormEvent } from 'react'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useStartSignInWithEmail } from 'shared/api/hooks/user'
import { useAuth } from 'shared/api/providers/AuthProvider'

import { ROUTES_PATH } from 'src/config'
import LoginLayout from 'src/templates/LoginLayout'
import EmailForm from 'src/templates/LoginLayout/EmailForm'

import FinishSignIn from './FinishSignIn'

const SignIn = () => {
  const { session } = useAuth()
  const { mutate: startSignInWithEmail, isPending, isError, data } = useStartSignInWithEmail()

  const handleEmailSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (event.target instanceof HTMLFormElement) {
      const data = new FormData(event.target)

      const email = data.get('email')?.toString()

      if (email) {
        startSignInWithEmail({ email })
      }
    }
  }

  if (data?.token) {
    return <FinishSignIn signInData={data} />
  }

  if (session) {
    return <Navigate to={ROUTES_PATH.HOME} />
  }

  return (
    <LoginLayout>
      <EmailForm onSubmitAction={handleEmailSubmit} isPending={isPending} isError={isError} />
    </LoginLayout>
  )
}

export default SignIn
