import { createGlobalRefetchQueryHook, createPowderMutationHook, createPowderQueryHook, createPowderQueryHookWithoutParams } from '../libs/utils'
import {
  cancelRecordingAnalysis,
  createCampaign,
  createOrganization,
  createOrganizationMembers,
  createOrganizationProfile,
  deleteCampaign,
  deleteOrganizationMember,
  getCampaign,
  getCampaignRecordingClips,
  getCampaignRecordings,
  getOrganization,
  getOrganizationCampaigns,
  getOrganizationMembers,
  getOrganizationProfileToken,
  getUserOrganizationSubscription,
  getUserOrganizations,
  joinOrganization,
  startCampaignRecordingAnalysis,
  updateCampaign,
  updateCampaignLogo,
  updateOrganization,
  updateOrganizationLogo,
  updateOrganizationMemberRole,
  updateOrganizationProfile,
  updateOrganizationProfilePictureUrl,
} from '../services/organization'

/** Mutations */

// /?invitationToken=12234
// use token from mail (generated by create organizationMembers)
export const useJoinOrganization = createPowderMutationHook(joinOrganization)
export const useUpdateOrganizationLogo = createPowderMutationHook(updateOrganizationLogo)
export const useUpdateCampaignLogo = createPowderMutationHook(updateCampaignLogo)
export const useUpdateOrganizationProfilePictureUrl = createPowderMutationHook(updateOrganizationProfilePictureUrl)
export const useCreateOrganization = createPowderMutationHook(createOrganization)
export const useUpdateOrganization = createPowderMutationHook(updateOrganization)

// invitation via emails
export const useCreateOrganizationMembers = createPowderMutationHook(createOrganizationMembers)

export const useDeleteOrganizationMember = createPowderMutationHook(deleteOrganizationMember)
export const useCreateOrganizationProfile = createPowderMutationHook(createOrganizationProfile)
export const useUpdateOrganizationProfile = createPowderMutationHook(updateOrganizationProfile)
export const useUpdateOrganizationMemberRole = createPowderMutationHook(updateOrganizationMemberRole)
export const useCreateCampaign = createPowderMutationHook(createCampaign)
export const useUpdateCampaign = createPowderMutationHook(updateCampaign)
export const useDeleteCampaign = createPowderMutationHook(deleteCampaign)
export const useStartCampaignRecordingAnalysis = createPowderMutationHook(startCampaignRecordingAnalysis)
export const useCancelRecordingAnalysis = createPowderMutationHook(cancelRecordingAnalysis)
export const useGetOrganizationProfileToken = createPowderMutationHook(getOrganizationProfileToken)

/** Queries  */
export const useGetOrganization = createPowderQueryHook(['getOrganization'], getOrganization)
export const useGetOrganizationMembers = createPowderQueryHook(['getOrganizationMembers'], getOrganizationMembers)
export const useGetOrganizationCampaigns = createPowderQueryHook(['getOrganizationCampaigns'], getOrganizationCampaigns)
export const useGetCampaign = createPowderQueryHook(['getCampaign'], getCampaign)
export const useGetCampaignRecordings = createPowderQueryHook(['getCampaignRecordings'], getCampaignRecordings)
export const useGetCampaignRecordingClips = createPowderQueryHook(['getCampaignRecordingClips'], getCampaignRecordingClips)

/** Queries without params */
export const useGetUserOrganizations = createPowderQueryHookWithoutParams(['getUserOrganizations'], getUserOrganizations)
export const useGetUserOrganizationSubscription = createPowderQueryHookWithoutParams(['getUserOrganizationSubscription'], getUserOrganizationSubscription)

/** Global Refetch */
export const useReGetOrganizationMembers = createGlobalRefetchQueryHook(['getOrganizationMembers'])
export const useReGetUserOrganizations = createGlobalRefetchQueryHook(['getUserOrganizations'])
export const useReGetCampaign = createGlobalRefetchQueryHook(['getCampaign'])
