import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useParams, useSearchParams } from 'react-router-dom'
import { useGetOrganizationCampaigns } from 'shared/api/hooks/organization'
import { RIGHTS, useRights } from 'shared/api/providers/RightsProvider'
import AddIcon from 'shared/graphics/icons/Add.svg?component'
import SearchIcon from 'shared/graphics/icons/Search.svg?component'
import { formatDateToCurrentStatus } from 'shared/helpers/formatDateToStatus'

import { ROUTES_PATH } from 'src/config'
import NotFound from 'src/pages/NotFound'
import CampaignListLayout from 'src/templates/CampaignListLayout'
import ContentLayout from 'src/templates/ContentLayout'
import LoadingLayout from 'src/templates/LoadingLayout'
import MainLayout from 'src/templates/MainLayout'

import DropDownItem from 'src/graphics/atoms/DropDownItem'
import Input from 'src/graphics/atoms/Input'
import LinkButton from 'src/graphics/atoms/LinkButton'
import CampaignListHeader from 'src/graphics/molecules/CampaignListHeader'
import ContentHero from 'src/graphics/molecules/ContentHero'
import Megaphone from 'src/graphics/molecules/ContentHero/images/Megaphone.png'
import Error from 'src/graphics/molecules/Error'
import FilterLayout from 'src/graphics/molecules/FilterLayout'
import FilterSelection from 'src/graphics/molecules/FilterSelection'
import CampaignList from 'src/graphics/organisms/CampaignList'

const CampaignListPageSecurity = () => {
  const { organizationId } = useParams()

  if (!organizationId) {
    return <NotFound />
  }

  return <CampaignListPage organizationId={organizationId} />
}

interface Props {
  organizationId: string
}

const CampaignListPage = ({ organizationId }: Props) => {
  const intl = useIntl()
  const { hasRight } = useRights()

  const {
    data: campaigns,
    isLoading: getOrganizationCampaignsLoading,
    isError: organizationCampaignsError,
    refetch: refetchOrganizationCampaigns,
  } = useGetOrganizationCampaigns({ organizationId })

  const [searchParams, setSearchParams] = useSearchParams()
  const [searchValue, setSearchValue] = useState('')

  const brandFilterValue = searchParams.get('brand') || undefined
  const statusFilterValue = searchParams.get('status') || undefined

  /**
   * Search
   */

  const handleSearchEvent = (event: React.ChangeEvent) => {
    if (event.target instanceof HTMLInputElement) {
      setSearchValue(event.target.value.trimStart().toLowerCase())
    }
  }

  /**
   * Filtering
   */

  const brandList = campaigns
    ?.filter(({ customer_name }) => customer_name !== undefined)
    .map(({ customer_name }) => customer_name)
    .filter((item, pos, array) => array.indexOf(item) == pos) as string[] // @todo problem avec typescript

  const statusList = campaigns
    ?.map(({ start_date, end_date }) => formatDateToCurrentStatus({ startDate: start_date, endDate: end_date }))
    .filter((item, pos, array) => array.indexOf(item) == pos) as string[]

  const filteredCampaigns = campaigns
    ?.filter(({ customer_name }) => (brandFilterValue ? customer_name === brandFilterValue : true))
    .filter(({ start_date, end_date }) =>
      statusFilterValue ? formatDateToCurrentStatus({ startDate: start_date, endDate: end_date }) === statusFilterValue : true
    )
    .filter(({ name }) => (searchValue ? name.toLowerCase().includes(searchValue) : true))

  const deleteSearchParamsByKey = (key: string) => {
    setSearchParams((prev) => {
      prev.delete(key)
      return prev
    })
  }

  const updateSearchParams = (key: string, value: string) => {
    setSearchParams((prev) => {
      prev.set(key, value)
      return prev
    })
  }

  if (getOrganizationCampaignsLoading) {
    return <LoadingLayout />
  }

  if (organizationCampaignsError) {
    return (
      <MainLayout>
        <ContentLayout>
          <Error />
        </ContentLayout>
      </MainLayout>
    )
  }

  return (
    <MainLayout>
      <ContentLayout>
        <CampaignListLayout
          header={
            <>
              <CampaignListHeader
                title={intl.formatMessage({ defaultMessage: 'Campaigns' })}
                actions={
                  hasRight(RIGHTS.CREATE_CAMPAIGN) && (
                    <LinkButton color="purple" iconBefore={AddIcon} to={generatePath(ROUTES_PATH.CAMPAIGN_ADD, { organizationId })}>
                      <FormattedMessage defaultMessage="Create campaign" />
                    </LinkButton>
                  )
                }
              />
              <FilterLayout
                selection={<Input iconBefore={SearchIcon} placeholder={intl.formatMessage({ defaultMessage: 'Search' })} onChange={handleSearchEvent} />}
                filters={
                  <>
                    <FilterSelection label="Brand" selected={brandFilterValue || <FormattedMessage defaultMessage="All" />} disabled={!campaigns?.length}>
                      <DropDownItem
                        onClick={() => {
                          deleteSearchParamsByKey('brand')
                        }}
                        active={!brandFilterValue}
                      >
                        <FormattedMessage defaultMessage="All" />
                      </DropDownItem>
                      {brandList?.map((brand, index) => (
                        <DropDownItem key={index} onClick={() => updateSearchParams('brand', brand)} active={brand === brandFilterValue}>
                          {brand}
                        </DropDownItem>
                      ))}
                    </FilterSelection>
                    <FilterSelection label="Status" selected={statusFilterValue || <FormattedMessage defaultMessage="All" />} disabled={!campaigns?.length}>
                      <DropDownItem
                        onClick={() => {
                          deleteSearchParamsByKey('status')
                        }}
                        active={!statusFilterValue}
                      >
                        <FormattedMessage defaultMessage="All" />
                      </DropDownItem>
                      {statusList?.map((status, index) => (
                        <DropDownItem
                          key={index}
                          onClick={() => {
                            updateSearchParams('status', status)
                          }}
                          active={status === statusFilterValue}
                        >
                          {status}
                        </DropDownItem>
                      ))}
                    </FilterSelection>
                  </>
                }
              />
              {hasRight(RIGHTS.CREATE_CAMPAIGN) && !campaigns?.length && (
                <ContentHero withArrow illustrationUrl={Megaphone} title={intl.formatMessage({ defaultMessage: 'No campaigns yet, create one now' })} />
              )}
            </>
          }
        >
          {hasRight(RIGHTS.LIST_CAMPAIGNS) && campaigns?.length && filteredCampaigns && (
            <CampaignList
              organizationId={organizationId}
              organizationCampaigns={filteredCampaigns}
              refetchOrganizationCampaigns={refetchOrganizationCampaigns}
              organizationCampaignsError={organizationCampaignsError}
            />
          )}
        </CampaignListLayout>
      </ContentLayout>
    </MainLayout>
  )
}

export default CampaignListPageSecurity
