import React from 'react'
import { Link } from 'react-router-dom'
import PowderLogo from 'shared/graphics/icons/PowderFullLogo.svg?component'

import { ROUTES_PATH } from 'src/config'

import scss from './style.module.scss'

const Header = () => {
  return (
    <div className={scss.outer}>
      <Link className={scss.logo} to={ROUTES_PATH.HOME}>
        <PowderLogo />
      </Link>
    </div>
  )
}

export default Header
