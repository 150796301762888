import type { rest_Video as Video } from '@powdergg/json-verse-api/dist/generated-axios-client/models/rest_Video'
import { default as React } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, generatePath } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group'
import { RIGHTS, useRights } from 'shared/api/providers/RightsProvider'
import RevealIcon from 'shared/graphics/icons/Eye.svg?component'
import HideIcon from 'shared/graphics/icons/Hide.svg?component'
import LinkIcon from 'shared/graphics/icons/Link.svg?component'
import TwitchIcon from 'shared/graphics/icons/Twitch.svg?component'
import YoutubeIcon from 'shared/graphics/icons/Youtube.svg?component'
import { formatDateToNow } from 'shared/helpers/formatDateToNow'
import { parseChannelUrl } from 'shared/helpers/parseChannelUrl'

import { ROUTES_PATH } from 'src/config'

import DropDownItem from 'src/graphics/atoms/DropDownItem'
import ProfilePicture from 'src/graphics/molecules/ProfilePicture'
import { CellSettings, ListLayout } from 'src/graphics/molecules/list'
import RecordingRow from 'src/graphics/molecules/list/RecordingRow'
import CSSTransitionRow from 'src/graphics/molecules/list/TransitionRow'

import CampaignRecordingsAction from '../CampaignRecordingsAction'

const ICONS = {
  youtube: YoutubeIcon,
  twitch: TwitchIcon,
} as const

type Icons = keyof typeof ICONS

export interface FilteredRecording extends Video {
  isHidden?: boolean
  platform?: { channel: string; source: 'youtube' | 'twitch' } | null
}

interface Props {
  organizationId: string
  campaignId: string
  recordings: FilteredRecording[]
  onToggleVisibility: ({ recordingId, campaignId }: { recordingId: string; campaignId: string }) => void
  refetchCampaignRecordingList: () => void
}

const CampaignRecordingList = ({ recordings, campaignId, organizationId, refetchCampaignRecordingList, onToggleVisibility }: Props) => {
  const { hasRight } = useRights()
  const intl = useIntl()
  const navigate = useNavigate()

  const handleViewOriginalVideo = ({ videoUrl }: { videoUrl?: string }) => {
    window.open(videoUrl)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleToggleVisibility = ({ recordingId }: Record<string, any>) => {
    onToggleVisibility({ campaignId, recordingId })
  }

  const handleNavigationToClips = (recordingId?: string) => {
    if (recordingId) {
      navigate(generatePath(ROUTES_PATH.CAMPAIGN_CLIP_LIST, { campaignId, organizationId, recordingId }))
    }
  }

  if (recordings.length === 0) {
    return <ListLayout emptyState={<FormattedMessage defaultMessage={'No results, please change your filters'} />} />
  }

  return (
    <ListLayout>
      <TransitionGroup component={null}>
        {recordings.map(
          ({
            channel_thumbnail_url,
            channel_title,
            id,
            thumbnail_url,
            title,
            url,
            view_count,
            clips_count,
            analysis_status,
            posted_at,
            isHidden,
            keywords_mentions_count,
          }) => {
            return (
              <CSSTransitionRow key={id}>
                <RecordingRow
                  key={id}
                  thumbnail={thumbnail_url ? thumbnail_url : ''}
                  picture={<ProfilePicture text={channel_title ? channel_title.substring(0, 1).toUpperCase() : ''} src={channel_thumbnail_url} size={64} />}
                  name={title ? title : 'undefined'}
                  streamInfo={channel_title}
                  streamDate={formatDateToNow({ formatter: intl, dateS: posted_at })}
                  streamIcon={(url && ICONS[parseChannelUrl(url)?.source as Icons]) || undefined}
                  views={view_count ? intl.formatNumber(view_count) : 'N/A'}
                  mention={keywords_mentions_count || 'N/A'}
                  isHidden={isHidden}
                  action={
                    analysis_status && id ? (
                      <CampaignRecordingsAction
                        analysisStatus={analysis_status}
                        campaignId={campaignId}
                        organizationId={organizationId}
                        clipsCount={clips_count}
                        recordingId={id}
                        refetchCampaignRecordingList={refetchCampaignRecordingList}
                      />
                    ) : null
                  }
                  settings={
                    <CellSettings>
                      <DropDownItem onClick={() => handleViewOriginalVideo({ videoUrl: url })} icon={LinkIcon} size="small">
                        <FormattedMessage defaultMessage="View original video" />
                      </DropDownItem>
                      {isHidden ? (
                        <DropDownItem onClick={() => handleToggleVisibility({ recordingId: id })} icon={RevealIcon} size="small">
                          <FormattedMessage defaultMessage="Reveal" />
                        </DropDownItem>
                      ) : (
                        <DropDownItem onClick={() => handleToggleVisibility({ recordingId: id })} icon={HideIcon} size="small">
                          <FormattedMessage defaultMessage="Hide" />
                        </DropDownItem>
                      )}
                    </CellSettings>
                  }
                  onClick={hasRight(RIGHTS.LIST_VIDEO_CLIPS) ? () => handleNavigationToClips(id) : undefined}
                />
              </CSSTransitionRow>
            )
          }
        )}
      </TransitionGroup>
    </ListLayout>
  )
}

export default CampaignRecordingList
