import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import type { Campaign } from 'shared/api/services/organization'

import ControllerSelectCreatable from 'src/graphics/molecules/ControllerSelectCreatable'
import FormEditionSubRow from 'src/graphics/molecules/FormEditionSubRow'
import Information from 'src/graphics/molecules/Information'

import scss from './style.module.scss'

interface Props {
  editMode?: boolean
}

const CampaignAddChannel = ({ editMode }: Props) => {
  const intl = useIntl()

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = useFormContext<Campaign>()

  const channels = watch('channels')

  return (
    <FormEditionSubRow title={<FormattedMessage defaultMessage="Add participating streamer channels" />}>
      <div className={scss.outer}>
        <Information>
          <FormattedMessage defaultMessage={'Note: Channels must have public past streams for analysis to work.'} />
        </Information>
        <Information>
          <FormattedMessage
            defaultMessage={
              'Caution: Please ensure that the links pasted here do not include any elements following the YouTube or Twitch URLs. For example, a correctly formatted link would be: www.youtube.com/@IShowSpeed or/and https://www.twitch.tv/kaicenat'
            }
          />
        </Information>
        <div className={scss.select}>
          <ControllerSelectCreatable
            name="channels"
            control={control}
            inputProps={{
              error: errors.channels?.message,
              isDisabled: editMode,
              placeholder: intl.formatMessage({ defaultMessage: 'Add Twitch or Youtube channels’ Url' }),
              value: channels.map((channel: string) => ({ label: channel, value: channel })),
              onChange: (values) => {
                setValue('channels', values?.map((value) => value.value) || [])
                trigger('channels')
              },
            }}
          />
        </div>
      </div>
    </FormEditionSubRow>
  )
}

export default CampaignAddChannel
