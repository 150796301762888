import cn from 'classnames'
import type { MouseEvent as ReactMouseEvent, ReactNode } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import type { Position } from 'shared/graphics/atoms/FloatingElement'
import FloatingElement from 'shared/graphics/atoms/FloatingElement'
import useClickOutside from 'shared/hooks/useClickOutside'

import scss from './style.module.scss'

const VARIANTS = {
  default: undefined,
  outlined: scss.outlined,
} as const

type Variant = keyof typeof VARIANTS

interface Props {
  children: ReactNode | (() => ReactNode)
  data?: ReactNode
  position?: Position
  space?: number
  element?: string
  hidden?: boolean
  isAttachedToIconButton?: boolean // Use to align icon for topStart and bottomStart position
  variant?: Variant
  offset?: number
  radius?: string | number
  disabled?: boolean
}

const DropDown = ({ children, data, position = 'top', space = 10, element = 'div', isAttachedToIconButton, variant, offset, radius = 12, disabled }: Props) => {
  const [visible, setVisible] = useState(false)
  const dropDownRef = useRef<HTMLDivElement>(null)
  const childrenRef = useRef<HTMLElement>(null)

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    setVisible((visible) => !visible)
  }
  useEffect(() => {
    const wheelCallback = () => {
      setVisible(false)
    }

    document.addEventListener('wheel', wheelCallback)
    return () => {
      document.removeEventListener('wheel', wheelCallback)
    }
  }, [])

  const closeDropDown = (event: ReactMouseEvent) => {
    event.stopPropagation()
    setVisible(false)
  }

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (childrenRef.current && !childrenRef.current.contains(event.target as Node)) {
        setVisible(false)
      }
    },
    [setVisible]
  )

  useClickOutside(dropDownRef, handleClickOutside)

  return (
    <>
      {React.createElement(
        element,
        {
          className: cn(scss.children, disabled && scss.isDisabled),
          onClick: !disabled && handleClick,
          ref: childrenRef,
        },
        typeof children === 'function' ? children() : children
      )}
      <FloatingElement visible={visible} anchorRef={childrenRef} space={space} position={position} offset={offset}>
        <div
          className={cn(scss.dropDown, isAttachedToIconButton && position.includes('Start') && scss.isAttachedToIconButtonStart, variant && VARIANTS[variant])}
          ref={dropDownRef}
          onClick={closeDropDown}
          style={{ borderRadius: radius }}
        >
          {data}
        </div>
      </FloatingElement>
    </>
  )
}

export default DropDown
