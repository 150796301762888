import type { ReactNode } from 'react'
import React from 'react'
import Powder from 'shared/graphics/icons/PowderColor.svg?component'

import scss from './style.module.scss'

interface Props {
  title: ReactNode
  children: ReactNode
}

const LandingForm = ({ title, children }: Props) => {
  return (
    <div className={scss.outer}>
      <div className={scss.logo}>
        <Powder />
      </div>
      <h1 className={scss.title}>{title}</h1>
      {children}
    </div>
  )
}

export default LandingForm
