import React from 'react'
import { Navigate } from 'react-router-dom'
import { useFinishSignInWithEmail } from 'shared/api/hooks/user'

import { ROUTES_PATH } from 'src/config'
import LoginLayout from 'src/templates/LoginLayout'
import CodeForm from 'src/templates/LoginLayout/CodeForm'

interface Props {
  signInData: { token: string; email: string }
}

const FinishSignIn = ({ signInData }: Props) => {
  const { mutate: finishSignInWithEmail, isPending, isError, data } = useFinishSignInWithEmail()
  const { token, email } = signInData

  const handleCodeSubmit = (code: string) => {
    finishSignInWithEmail({ code, token })
  }

  if (data) {
    return <Navigate to={ROUTES_PATH.HOME} />
  }

  return (
    <LoginLayout>
      <CodeForm onSubmitAction={handleCodeSubmit} isError={isError} isPending={isPending} email={email} />
    </LoginLayout>
  )
}

export default FinishSignIn
