import React from 'react'
import type { FieldValues, UseControllerProps } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import type { LocalProps } from 'shared/graphics/molecules/SelectCreatable'
import SelectCreatable from 'shared/graphics/molecules/SelectCreatable'

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  inputProps: LocalProps
}

const ControllerSelectCreatable = <T extends FieldValues>({ name, control, inputProps }: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return <SelectCreatable {...field} {...inputProps} />
      }}
    />
  )
}

export default ControllerSelectCreatable
