import type { ReactNode } from 'react'
import React from 'react'

import scss from './style.module.scss'

interface Props {
  title: string
  actions?: ReactNode
}

const CampaignListHeader = ({ title, actions }: Props) => {
  return (
    <div className={scss.outer}>
      <div className={scss.title}>{title}</div>
      {actions && <div>{actions}</div>}
    </div>
  )
}

export default CampaignListHeader
