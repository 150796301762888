import React from 'react'
import LottieAnimation from 'shared/graphics/molecules/LottieAnimation'

import LogoLoop from './LogoLoop.json'

import scss from './style.module.scss'

const LogoAnimation = () => (
  <div className={scss.outer}>
    <LottieAnimation animationData={LogoLoop} className={scss.icon} />
  </div>
)

export default LogoAnimation
