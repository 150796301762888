import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import type { Campaign } from 'shared/api/services/organization'

import ControllerSelect from 'src/graphics/molecules/ControllerSelect'
import Description from 'src/graphics/molecules/Description'
import FormEditionSubRow from 'src/graphics/molecules/FormEditionSubRow'
import Information from 'src/graphics/molecules/Information'

import scss from './style.module.scss'

interface Props {
  editMode?: boolean
}

const CampaignLanguageDetection = ({ editMode }: Props) => {
  const intl = useIntl()

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext<Campaign>()

  const OPTIONS = [
    { label: intl.formatMessage({ defaultMessage: 'English (optimized)' }), value: 'en' },
    {
      label: intl.formatMessage({ defaultMessage: 'Multilingual (beta)' }),
      value: 'multi',
    },
  ]

  const language = watch('language')

  return (
    <FormEditionSubRow title={<FormattedMessage defaultMessage="Language detection" />}>
      <div className={scss.form}>
        <div className={scss.info}>
          <Description>
            <FormattedMessage
              defaultMessage={
                "Select 'Optimized English' for improved English content analysis or 'Multilingual' for automatic language detection, currently including Dutch, English, German, Portuguese and Spanish."
              }
            />
          </Description>
          <Information>
            <FormattedMessage defaultMessage={'Caution: The language setting is fixed once the campaign is created.'} />
          </Information>
        </div>
        <div className={scss.select}>
          <ControllerSelect
            control={control}
            name="language"
            selectProps={{
              error: errors.language?.message,
              options: OPTIONS,
              isDisabled: editMode,
              value: OPTIONS.filter((option) => option.value === language),
              onChange: (value) => {
                setValue('language', value ? value.value : '')
                trigger('language')
              },
            }}
          />
        </div>
      </div>
    </FormEditionSubRow>
  )
}

export default CampaignLanguageDetection
