import cn from 'classnames'
import type { ComponentType, ReactNode } from 'react'
import React from 'react'

import scss from './style.module.scss'

const SIZES = {
  default: undefined,
  small: scss.small,
} as const

type Size = keyof typeof SIZES

interface Props {
  children: ReactNode
  icon?: ComponentType<{ className?: string }>
  onClick: () => void
  active?: boolean
  size?: Size
}

const DropDownItem = ({ children, icon: Icon, onClick, active, size = 'default' }: Props) => {
  return (
    <div className={cn(scss.outer, active && scss.isActive, SIZES[size])} onClick={onClick}>
      {Icon && <Icon className={scss.icon} />}
      <p className={scss.label}>{children}</p>
    </div>
  )
}

export default DropDownItem
