import type { ChangeEvent } from 'react'
import React, { useState } from 'react'

import ImageUpload from 'src/graphics/molecules/ImageUpload'

interface Props {
  image?: string
  onChange?: (file: File) => void
  disabled?: boolean
}

const FormCover = ({ image, onChange, disabled }: Props) => {
  const [imageUrlLocal, setImageUrlLocal] = useState<string | undefined>(image)

  const handleImageUpload = (event: ChangeEvent) => {
    if (event.target instanceof HTMLInputElement) {
      const file = event.target.files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = () => {
          setImageUrlLocal(reader.result as string)
        }
        reader.readAsDataURL(file)
        onChange?.(file)
      }
    }
  }
  return <ImageUpload onUpload={handleImageUpload} image={imageUrlLocal || image} disabled={disabled} />
}

export default FormCover
