import React from 'react'

import Chip from 'src/graphics/atoms/Chip'
import type { Names as StatusNames } from 'src/graphics/molecules/Status'
import Status from 'src/graphics/molecules/Status'

import ProfilePicture from '../ProfilePicture'

import scss from './style.module.scss'

interface Props {
  text: string
  status: StatusNames
  keywords: string[]
  logoUrl?: string
}

const CampaignRecordingsHeader = ({ text, status, keywords, logoUrl }: Props) => {
  return (
    <div className={scss.outer}>
      <div className={scss.header}>
        <ProfilePicture src={logoUrl} size={48} radius={8} text={text.substring(0, 1).toUpperCase()} />
        <h1 className={scss.header__title}>{text}</h1>
        <Status name={status} />
      </div>
      <div className={scss.keywords}>
        {keywords.map((keyword) => (
          <Chip key={keyword} keyword={keyword} />
        ))}
      </div>
    </div>
  )
}

export default CampaignRecordingsHeader
