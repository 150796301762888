import type { ComponentType, ReactNode } from 'react'
import React from 'react'

import { TextIcon } from 'src/graphics/molecules/list'

import scss from './style.module.scss'

interface Props {
  title: string
  subtitle?: ReactNode
  subtitleIcon?: ComponentType<{ className?: string | undefined }>
}

const CellTitle = ({ title, subtitle, subtitleIcon }: Props) => {
  return (
    <div className={scss.outer}>
      <h1 className={scss.title}>{title}</h1>
      {subtitle && <TextIcon text={subtitle} icon={subtitleIcon} />}
    </div>
  )
}

export default CellTitle
