import React from 'react'
import { Outlet } from 'react-router-dom'
import RightsProvider from 'shared/api/providers/RightsProvider'

const ProtectedRightsRoutes = () => {
  return (
    <RightsProvider>
      <Outlet />
    </RightsProvider>
  )
}
export default ProtectedRightsRoutes
