import type { ReactNode } from 'react'
import React from 'react'
import ArrowIcon from 'shared/graphics/icons/Arrow.svg?component'

import scss from './style.module.scss'

interface Props {
  children: ReactNode
  onBack?: () => void
}

const ContentLayout = ({ children, onBack }: Props) => {
  return (
    <div className={scss.outer}>
      <div className={scss.inner}>
        {onBack && (
          <button className={scss.arrow} onClick={onBack}>
            <ArrowIcon />
          </button>
        )}
        {children}
      </div>
    </div>
  )
}

export default ContentLayout
