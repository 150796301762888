import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import type { IntlShape } from 'react-intl'
import { FormattedMessage, useIntl } from 'react-intl'
import { any, object, string } from 'zod'

import Button from 'src/graphics/atoms/Button'
import ControllerInput from 'src/graphics/molecules/ControllerInput'
import type { AppError } from 'src/graphics/molecules/Error'
import Error, { getRequestError } from 'src/graphics/molecules/Error'
import FormCover from 'src/graphics/organisms/FormCover'

import scss from './style.module.scss'

export interface OrganizationFormValues {
  organizationWebsite?: string
  organizationName: string
  organizationLogo?: File
}

const MAX_FILE_SIZE = 20000
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']

const schema = ({ intl }: { intl: IntlShape }) =>
  object({
    organizationLogo: any()
      .refine(
        (files) => {
          return files?.size <= MAX_FILE_SIZE
        },
        { message: intl.formatMessage({ defaultMessage: `Max file size is 2MB.` }) }
      )
      .refine((files) => ACCEPTED_IMAGE_TYPES.includes(files?.type), {
        message: intl.formatMessage({ defaultMessage: '.jpg, .jpeg, .png and .webp files are accepted.' }),
      })
      .optional(),
    organizationName: string({ required_error: intl.formatMessage({ defaultMessage: 'Organization name is required' }) })
      .min(1, { message: intl.formatMessage({ defaultMessage: 'Organization name is required' }) })
      .trim(),
    organizationWebsite: string().optional(),
  })

interface Props {
  logoUrl?: string
  name?: string
  website?: string
  onSubmit: (data: OrganizationFormValues) => void
  loading?: boolean
  noPicture?: boolean
  error?: AppError
  onCancel?: () => void
}

const OrganizationForm = ({ name, logoUrl, website, onSubmit, onCancel, loading, noPicture, error }: Props) => {
  const intl = useIntl()

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    setValue,
    trigger,
    setError,
  } = useForm<OrganizationFormValues>({
    defaultValues: {
      organizationName: name || '',
      organizationWebsite: website || '',
    },
    resolver: zodResolver(schema({ intl })),
    mode: 'onSubmit',
  })

  useEffect(() => {
    if (error?.body?.error) {
      setError('organizationName', { message: getRequestError({ error: error?.body.error, intl: intl }) })
    }
  }, [error, intl, setError])

  return (
    <div className={scss.outer}>
      <form className={scss.form} onSubmit={handleSubmit(!isDirty && !!onCancel ? onCancel : onSubmit)}>
        <div className={scss.header}>
          {!noPicture && (
            <div className={scss.cover}>
              <FormCover
                image={logoUrl}
                disabled={loading}
                onChange={(file) => {
                  setValue('organizationLogo', file, { shouldDirty: true })
                  trigger('organizationLogo')
                }}
              />
            </div>
          )}
          <div className={scss.content}>
            <ControllerInput
              control={control}
              name="organizationName"
              inputProps={{
                placeholder: intl.formatMessage({ defaultMessage: 'Name your organization* ' }),
                error: errors.organizationName?.message,
                isError: !!errors.organizationName,
                disabled: loading,
              }}
            />
            <ControllerInput
              control={control}
              name="organizationWebsite"
              inputProps={{
                placeholder: intl.formatMessage({ defaultMessage: 'Organization website (optional)' }),
                error: errors.organizationWebsite?.message,
                disabled: loading,
              }}
            />
          </div>
        </div>
        {!isDirty && !!onCancel ? (
          <Button color="purple">
            <FormattedMessage defaultMessage={'Cancel'} />
          </Button>
        ) : (
          <Button color="purple" outlined={!isValid} disabled={loading}>
            {name ? <FormattedMessage defaultMessage={'Save'} /> : <FormattedMessage defaultMessage={'Create'} />}
          </Button>
        )}
      </form>
      {errors.organizationLogo?.message && <Error>{errors.organizationLogo.message}</Error>}
    </div>
  )
}

export default OrganizationForm
