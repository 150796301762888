import type { ChangeEvent } from 'react'
import React from 'react'

import HeaderCover from 'src/graphics/molecules/HeaderCover'

import scss from './style.module.scss'

interface Props {
  image?: string
  onUpload: (event: ChangeEvent) => void
  disabled?: boolean
}

const ImageUpload = ({ onUpload, image, disabled }: Props) => {
  return (
    <label className={scss.outer} htmlFor="image-upload">
      <HeaderCover image={image} disabled={disabled} />
      <input className={scss.input} type="file" id="image-upload" onChange={onUpload} disabled={disabled} />
    </label>
  )
}

export default ImageUpload
