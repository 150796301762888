import type { rest_Login, rest_User } from '@powdergg/json-verse-api/dist/generated-axios-client'
import type { ReactNode } from 'react'
import React, { createContext, useContext, useEffect, useState } from 'react'
import SplashScreen from 'shared/graphics/organisms/SplashScreen'

import useLocalStorage from '../../hooks/useLocalStorage'
import { refreshSession } from '../services/user'

export type Session = {
  user: rest_User
  login: rest_Login
  accessToken: string
  organizationToken?: string
  refreshToken: string
  expireAt: number
  rights: string[]
}
export interface Auth {
  session?: Session | null
  setSession: (params: Session) => void
  wipeSession: () => void
  sessionIsLoading?: boolean
}

const AuthContext = createContext<Auth>({} as Auth)

interface AuthProviderProps {
  children: ReactNode
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [session, setSessionLocal] = useLocalStorage<Session | null>('session', null)
  const [sessionIsLoading, setSessionIsLoading] = useState(true)

  const setSession = (newSession: Session) => {
    setSessionLocal(newSession)
  }

  const wipeSession = () => {
    setSessionLocal(null)
  }

  useEffect(() => {
    ;(async () => {
      try {
        setSessionIsLoading(true)
        await refreshSession({ session, setSession, wipeSession })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error in AuthProvider useEffect', e)
      } finally {
        setSessionIsLoading(false)
      }
    })()
    // eslint-disable-next-line
  }, [])

  return (
    <AuthContext.Provider value={{ session, setSession, wipeSession, sessionIsLoading }}>{sessionIsLoading ? <SplashScreen /> : children}</AuthContext.Provider>
  )
}

export const useAuth = () => {
  const auth = useContext(AuthContext)

  if (!auth) {
    throw new Error('auth context should be provided.')
  }

  return auth
}

export default AuthProvider
