import React from 'react'
import type { FieldValues, UseControllerProps } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import type { GroupBase } from 'react-select'
import type { SelectProps } from 'shared/graphics/molecules/Select'
import Select from 'shared/graphics/molecules/Select'

interface Props<T extends FieldValues, Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>
  extends UseControllerProps<T> {
  selectProps: SelectProps<Option, IsMulti, Group>
}

const ControllerSelect = <T extends FieldValues, Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  name,
  control,
  selectProps,
}: Props<T, Option, IsMulti, Group>) => {
  return <Controller name={name} control={control} render={({ field }) => <Select {...field} {...selectProps} />} />
}

export default ControllerSelect
