import React from 'react'
import type { ReactNode } from 'react'

import scss from './style.module.scss'

interface Props {
  children?: ReactNode
  emptyState?: ReactNode
}
const Layout = ({ children, emptyState }: Props) => {
  if (!children) {
    return <span className={scss.emptyState}>{emptyState}</span>
  }
  return <div className={scss.outer}>{children}</div>
}

export default Layout
