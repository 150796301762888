import type { ReactNode } from 'react'
import React from 'react'
import { Navigate } from 'react-router-dom'
import type { RightListValues } from 'shared/api/providers/RightsProvider'
import { useRights } from 'shared/api/providers/RightsProvider'

import { ROUTES_PATH } from 'src/config'

interface Props {
  rights: RightListValues[]
  children: ReactNode
}

const RightRoute = ({ rights, children }: Props) => {
  const { hasAllRights } = useRights()

  if (!hasAllRights(rights)) {
    return <Navigate to={ROUTES_PATH.NOT_FOUND} />
  }

  return children
}

export default RightRoute
