import type { ReactNode } from 'react'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import scss from './style.module.scss'

const highlightKeywords = ({ keywords, text }: { keywords: string[]; text: string }) =>
  keywords.reduce((acc, keyword) => acc.replace(new RegExp(keyword, 'ig'), (match: string) => `<span class="${scss.keyword}">${match}</span>`), text)

interface Props {
  children?: string
  events?: {
    label: string
    start_at_seconds: number
    type: string
  }[]
  emptyState?: ReactNode
}

const Transcript = ({ children, events = [], emptyState = <FormattedMessage defaultMessage={'No transcript found'} /> }: Props) => {
  return (
    <div className={scss.outer}>
      {children ? (
        <p dangerouslySetInnerHTML={{ __html: highlightKeywords({ keywords: events.map((event) => event.label), text: children }) }} />
      ) : (
        <div className={scss.noChildren}>{emptyState}</div>
      )}
    </div>
  )
}

export default Transcript
