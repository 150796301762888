import type { ComponentType, ReactNode } from 'react'
import React from 'react'

import scss from './style.module.scss'

interface Props {
  icon?: ComponentType<{ className?: string | undefined }>
  text?: ReactNode
}

const TextIcon = ({ icon: Icon, text }: Props) => {
  return (
    <p className={scss.content}>
      {Icon && <Icon className={scss.content__icon} />}
      {text}
    </p>
  )
}

export default TextIcon
