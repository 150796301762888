import type { ReactNode } from 'react'
import { useState, useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'

const createWrapperAndAppendToBody = (wrapperId: string) => {
  const wrapperElement = document.createElement('div')
  wrapperElement.setAttribute('id', wrapperId)
  document.body.appendChild(wrapperElement)
  return wrapperElement
}

interface Props {
  children: ReactNode
  wrapperId?: string
}

const BodyPortal = ({ children, wrapperId = 'react-portal-wrapper' }: Props) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null)

  useLayoutEffect(() => {
    let element: HTMLElement | null = document.getElementById(wrapperId)

    if (!element) {
      element = createWrapperAndAppendToBody(wrapperId)
    }

    setWrapperElement(element)
  }, [wrapperId])

  // wrapperElement state will be null on very first render.
  if (wrapperElement === null) return null

  return createPortal(children, wrapperElement)
}

export default BodyPortal
