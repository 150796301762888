import type { ReactNode } from 'react'
import React from 'react'
import Close from 'shared/graphics/icons/Close.svg?component'

import scss from './style.module.scss'

interface Props {
  title: ReactNode
  subtitle?: ReactNode
  actions: ReactNode
  onClose?: () => void
}

const DialogModal = ({ title, subtitle, actions, onClose }: Props) => {
  return (
    <div className={scss.outer}>
      {onClose && (
        <button className={scss.close} onClick={onClose}>
          <Close className={scss.icon} />
        </button>
      )}
      <div className={scss.content}>
        <h1 className={scss.content__title}>{title}</h1>
        {subtitle && <p className={scss.content__subtitle}>{subtitle}</p>}
        <div className={scss.content__actions}>{actions}</div>
      </div>
    </div>
  )
}

export default DialogModal
