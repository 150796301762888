import { differenceInWeeks, differenceInDays, differenceInMonths, isToday, isYesterday } from 'date-fns'
import type { IntlShape } from 'react-intl'

interface Props {
  formatter: IntlShape
  dateS?: number
}

export const formatDateToNow = ({ dateS, formatter }: Props) => {
  if (!dateS) return 'N/A'
  const date = dateS * 1000

  if (isToday(date)) {
    return formatter.formatMessage({ defaultMessage: 'Today' })
  }
  if (isYesterday(date)) {
    return formatter.formatMessage({ defaultMessage: 'Yesterday' })
  }
  const now = new Date()
  const distanceInDays = differenceInDays(now, date)
  if (distanceInDays < 7) {
    return formatter.formatRelativeTime(-distanceInDays, 'day')
  }
  const distanceInMonth = differenceInMonths(now, date)
  if (distanceInMonth < 1) {
    const weeksCount = differenceInWeeks(now, date)
    return formatter.formatRelativeTime(-weeksCount, 'week')
  }
  if (distanceInMonth < 3) {
    return formatter.formatRelativeTime(-distanceInMonth, 'month')
  }
  return formatter.formatDate(date)
}
