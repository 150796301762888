import React, { useState } from 'react'
import type { ChangeEvent } from 'react'
import { FormattedMessage } from 'react-intl'

import { PRICING } from 'src/pricing'

import Slider from 'src/graphics/atoms/Slider'
import PricingCard from 'src/graphics/molecules/PricingCard'

import scss from './style.module.scss'

const CustomPricing = () => {
  const [pricingIndex, setPricingIndex] = useState<number>(4)

  const handleSliderChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPricingIndex(parseInt(event.target.value))
  }

  const selectedPlan = PRICING[pricingIndex]

  return (
    <div className={scss.outer}>
      <h1 className={scss.title}>
        <FormattedMessage defaultMessage={'Powder pricing'} />
      </h1>
      <Slider onChange={handleSliderChange} defaultValue={pricingIndex} />
      <PricingCard plan={selectedPlan} />
    </div>
  )
}

export default CustomPricing
