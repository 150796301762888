import { createPowderMutationHook, createPowderQueryHook, createPowderQueryHookWithoutParams } from '../libs/utils'
import { fetchMyUser, fetchUser, loginEmailSignIn, loginValidateOtpSignIn } from '../services/user'

/** Queries without params */
export const useFetchMyUser = createPowderQueryHookWithoutParams(['fetchMyUser'], fetchMyUser)

/** Queries  */
export const useFetchUser = createPowderQueryHook(['fetchUser'], fetchUser)

/** Mutations */
export const useStartSignInWithEmail = createPowderMutationHook(loginEmailSignIn)
export const useFinishSignInWithEmail = createPowderMutationHook(loginValidateOtpSignIn)
