import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from 'shared/api/providers/AuthProvider'

import type { RoutePath } from 'src/App'

interface Props {
  redirection: RoutePath
}

const ProtectedRoute = ({ redirection }: Props) => {
  const { session } = useAuth()

  return session ? <Outlet /> : <Navigate to={redirection} />
}
export default ProtectedRoute
