import type { ReactNode } from 'react'
import React from 'react'
import type { Position } from 'shared/graphics/atoms/FloatingElement'
import ChevronIcon from 'shared/graphics/icons/Chevron.svg?component'

import DropDown from '../DropDown'

import scss from './style.module.scss'

interface Props extends Record<string, unknown> {
  label: string
  children?: ReactNode
  space?: number
  position?: Position
  selected?: ReactNode
  disabled?: boolean
}

const FilterSelection = ({ label, children, space = 24, position = 'bottom', selected, disabled }: Props) => {
  return (
    <DropDown position={position} space={space} variant="outlined" disabled={disabled} data={children}>
      <div className={scss.selector}>
        <span className={scss.selector__label}>{label}</span>
        <span className={scss.selector__selected}>{selected}</span>
        <ChevronIcon className={scss.selector__icon} />
      </div>
    </DropDown>
  )
}

export default FilterSelection
