import React from 'react'

import ContentLayout from 'src/templates/ContentLayout'
import MainLayout from 'src/templates/MainLayout'
import PricingLayout from 'src/templates/PricingLayout'

import Pricing from 'src/graphics/organisms/Pricing'

const Paywall = () => {
  return (
    <MainLayout menuHidden>
      <ContentLayout>
        <PricingLayout>
          <Pricing />
        </PricingLayout>
      </ContentLayout>
    </MainLayout>
  )
}

export default Paywall
