import type { ReactNode } from 'react'
import React from 'react'

import { CellTitle } from 'src/graphics/molecules/list'

import Row from '../Row'

import scss from './style.module.scss'

interface Props {
  picture: ReactNode
  settings: ReactNode
  name: string
}

const ChannelRow = ({ picture, name, settings }: Props) => {
  return (
    <Row gridTemplateColumns={'48px 1fr 24px'}>
      <div className={scss.profile}>{picture}</div>
      <div className={scss.name}>
        <CellTitle title={name} />
      </div>
      <div className={scss.settings}>{settings}</div>
    </Row>
  )
}

export default ChannelRow
