import type { RefObject } from 'react'
import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useGetCampaign, useGetCampaignRecordingClips } from 'shared/api/hooks/organization'
import { RIGHTS, useRights } from 'shared/api/providers/RightsProvider'
import Select from 'shared/graphics/molecules/Select'
import EmbedPlayer from 'shared/graphics/organisms/EmbedPlayer'
import VideoPlayer2 from 'shared/graphics/organisms/VideoPlayer2'

import { ROUTES_PATH } from 'src/config'
import CampaignClipsLayout from 'src/templates/CampaignClipsLayout'
import ContentLayout from 'src/templates/ContentLayout'
import LoadingLayout from 'src/templates/LoadingLayout'
import MainLayout from 'src/templates/MainLayout'
import Playlist from 'src/templates/Playlist'

import CampaignClipHeader from 'src/graphics/molecules/CampaignClipHeader'
import ClipItem from 'src/graphics/molecules/ClipItem'
import ClipsAnalysisStatus from 'src/graphics/molecules/ClipsAnalysisStatus'
import Error from 'src/graphics/molecules/Error'
import Transcript from 'src/graphics/molecules/Transcript'
import CampaignRecordingsAction from 'src/graphics/organisms/CampaignRecordingsAction'

import NotFound from '../NotFound'

const CampaignClipListCheck = () => {
  const { organizationId, campaignId, recordingId } = useParams()
  if (!organizationId || !campaignId || !recordingId) {
    return <NotFound />
  }

  return <CampaignClipList organizationId={organizationId} campaignId={campaignId} recordingId={recordingId} />
}

interface Props {
  organizationId: string
  campaignId: string
  recordingId: string
}

type Mentions = { label: string; value: string }[]

const CampaignClipList = ({ organizationId, campaignId, recordingId }: Props) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const mentionFilterValue = searchParams.get('mentions') ? searchParams.get('mentions')?.split(',') : null

  const { hasRight } = useRights()
  const {
    data: campaignRecordingsClips,
    isFetching: getCampaignRecordingClipsFetching,
    isError: getCampaignRecordingClipsError,
    refetch: refetchCampaignRecordingClips,
  } = useGetCampaignRecordingClips({ campaignId, organizationId, recordingId }, { enabled: hasRight(RIGHTS.LIST_VIDEO_CLIPS) })
  const { data: campaign, isLoading: getCampaignLoading } = useGetCampaign({ campaignId, organizationId })

  const mentionList = campaign?.keywords.map((keyword) => ({ value: keyword, label: keyword }))

  const recordingInfo = campaignRecordingsClips?.video
  const clips = campaignRecordingsClips?.clips
    ?.filter((clip) => !!clip.url)
    .filter((clip) =>
      mentionFilterValue?.length ? mentionFilterValue.some((value) => clip.detected_events.map((event) => event.label).includes(value)) : true
    )

  const selectedClipId = searchParams.get('clipId') || clips?.[0]?.id

  const videoRef = useRef<HTMLVideoElement | null>(null)
  const handleVideoRef = (ref: RefObject<HTMLVideoElement>) => {
    videoRef.current = ref.current
  }

  const handleMentionFilterChange = (mentions: Mentions) => {
    setSearchParams((searchParams) => {
      searchParams.set('mentions', mentions.map(({ value }) => value).join(','))

      const expectedNewClipList = campaignRecordingsClips?.clips
        ?.filter((clip) => !!clip.url)
        .filter((clip) => (mentions?.length ? mentions.some(({ value }) => clip.detected_events.map((event) => event.label).includes(value)) : true))

      if (expectedNewClipList?.length) {
        // if the expected list is not empty
        if (!expectedNewClipList?.some((clip) => clip.id === selectedClipId)) {
          // if the selected clip id is not in the expected list, select the first clip
          searchParams.set('clipId', expectedNewClipList[0].id)
        }
        return searchParams
      }
      // if the expected clip list is empty delete the clipId from the url
      searchParams.delete('clipId')
      return searchParams
    })
  }

  const handleClipClick = (id: string) => {
    setSearchParams((searchParams) => {
      searchParams.set('clipId', id)
      return searchParams
    })
  }

  const handleBack = () => {
    navigate(generatePath(ROUTES_PATH.CAMPAIGN, { organizationId, campaignId }))
  }

  if (!hasRight(RIGHTS.LIST_VIDEO_CLIPS)) {
    return <NotFound />
  }

  if (getCampaignRecordingClipsFetching) {
    return <LoadingLayout />
  }

  if (getCampaignRecordingClipsError) {
    return (
      <MainLayout>
        <ContentLayout>
          <Error />
        </ContentLayout>
      </MainLayout>
    )
  }

  const selectedClip = clips?.find((clip) => clip.id === selectedClipId)

  return (
    <MainLayout>
      <ContentLayout onBack={handleBack}>
        <CampaignClipsLayout
          header={
            <CampaignClipHeader
              title={recordingInfo?.title}
              channelName={recordingInfo?.channel_title}
              dateS={recordingInfo?.posted_at}
              views={recordingInfo?.view_count}
              url={recordingInfo?.url}
              action={
                !clips?.length &&
                recordingInfo?.analysis_status && (
                  <CampaignRecordingsAction
                    analysisStatus={recordingInfo?.analysis_status}
                    campaignId={campaignId}
                    organizationId={organizationId}
                    recordingId={recordingId}
                    refetchCampaignRecordingList={refetchCampaignRecordingClips}
                  />
                )
              }
            />
          }
        >
          <Playlist
            emptyState={<FormattedMessage defaultMessage="Sorry, we didn't get any results for this keyword(s)" />}
            filters={
              !!clips?.length && (
                <Select
                  placeholder={<FormattedMessage defaultMessage="Mentions" />}
                  isMulti
                  options={mentionList}
                  isLoading={getCampaignLoading}
                  onChange={handleMentionFilterChange}
                  value={mentionFilterValue?.map((value) => ({ value, label: value }))}
                />
              )
            }
            clips={
              clips?.length
                ? clips?.map(({ id, thumbnail_url, detected_events, duration_seconds, url }) => (
                    <ClipItem
                      key={id}
                      illustration={thumbnail_url}
                      tags={detected_events.map((i) => i.label)}
                      duration={duration_seconds}
                      onClick={() => handleClipClick(id)}
                      url={url}
                      isActive={selectedClipId === id}
                    />
                  ))
                : null
            }
            status={<ClipsAnalysisStatus analysisStatus={recordingInfo?.analysis_status} />}
            transcript={
              <Transcript
                emptyState={<FormattedMessage defaultMessage="The transcript of analyzed clips is going to appear here " />}
                events={selectedClip?.detected_events}
              >
                {selectedClip ? selectedClip?.transcript : undefined}
              </Transcript>
            }
          >
            {selectedClip ? (
              <VideoPlayer2 key={selectedClip.url} videoPath={selectedClip.url} onVideoRef={handleVideoRef} />
            ) : (
              <EmbedPlayer url={recordingInfo?.url} />
            )}
          </Playlist>
        </CampaignClipsLayout>
      </ContentLayout>
    </MainLayout>
  )
}

export default CampaignClipListCheck
