import type { AnimationConfigWithData, AnimationItem } from 'lottie-web'
import lottie from 'lottie-web'
import React, { useEffect, useRef, useState } from 'react'

interface Props {
  animationData: Record<any, any>
  className?: string
  loop?: boolean | number
  paused?: boolean
}

const LottieAnimation = ({ animationData, className, loop, paused }: Props) => {
  const [params] = useState<Partial<AnimationConfigWithData<'svg'>>>(() => ({
    loop,
  }))
  const ref = useRef(null)
  const animationUse = useState<AnimationItem | undefined>()
  let animation = animationUse[0]
  const setAnimation = animationUse[1]

  useEffect(() => {
    const container = ref.current

    if (!container) {
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    animation = lottie.loadAnimation({
      container,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
      ...params,
    })

    setAnimation(animation)

    return () => {
      animation?.destroy()
    }
  }, [params, animationData, ref])

  if (paused) animation?.pause()
  else animation?.play()

  return <span className={className} ref={ref} />
}

export default LottieAnimation
