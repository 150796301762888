import cn from 'classnames'
import type { ComponentType, MouseEvent, ReactNode } from 'react'
import React from 'react'

import scss from './style.module.scss'

const SIZES = {
  small: scss.isSmallSize,
  default: undefined,
  normal: scss.isNormalSize,
  medium: scss.isMediumSize,
  large: scss.isLargeSize,
} as const

type Sizes = keyof typeof SIZES

const COLORS = {
  default: undefined,
  purple: scss.purple,
  danger: scss.danger,
  orange: scss.orange,
} as const

type Colors = keyof typeof COLORS
export interface Props {
  children: ReactNode
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  onClick?: (event: MouseEvent) => void
  fat?: boolean
  size?: Sizes
  width?: string | number
  color?: Colors
  outlined?: boolean
  iconBefore?: ComponentType<{ className?: string | undefined }>
  href?: string
}

const Button = ({
  href,
  children,
  type,
  disabled,
  onClick,
  fat,
  width,
  size = SIZES.default,
  color = COLORS.default,
  outlined,
  iconBefore: IconBefore,
}: Props) => {
  const className = cn(scss.button, fat && scss.isFat, size && SIZES[size], color && COLORS[color], outlined && scss.isOutlined)
  const content = (
    <>
      {IconBefore && <IconBefore className={scss.icon} />} {children}
    </>
  )

  if (href) {
    return (
      <a className={className} style={{ width }} href={href}>
        {content}
      </a>
    )
  }

  return (
    <button className={className} style={{ width }} type={type} onClick={onClick} disabled={disabled}>
      {content}
    </button>
  )
}

export default Button
