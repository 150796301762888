import cn from 'classnames'
import React, { useCallback } from 'react'

import scss from './style.module.scss'

type Props = Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'type' | 'onChange' | 'value' | 'checked'> & {
  value?: boolean
  onChange?: (value: boolean) => void
}

const Toggle = ({ onChange, value, disabled, ...props }: Props) => {
  const onChange_ = useCallback(() => {
    onChange && onChange(!value)
  }, [onChange, value])

  return (
    <span className={cn(scss.outer, disabled && scss.isDisabled)}>
      <input {...props} disabled={disabled} checked={value || false} className={scss.input} type="checkbox" onChange={onChange_} />
      <span className={scss.inner} />
    </span>
  )
}

export default Toggle
