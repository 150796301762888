import type { QueryKey, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import type { Auth, Session } from '../providers/AuthProvider'
import { useAuth } from '../providers/AuthProvider'

export type QueryParams<A extends unknown = undefined> = {
  params: A extends undefined ? undefined : A
  auth: Auth
  queryFnProps: {
    queryKey: QueryKey
    signal: AbortSignal
    meta: Record<string, unknown> | undefined
  }
}

export type QueryFunction<A, B> = (params: QueryParams<A>) => Promise<B>

export const createPowderQueryHook = <A, B>(key: UseQueryOptions['queryKey'], fnc: QueryFunction<A, B>) => {
  return (params: A, queryParams: Omit<UseQueryOptions<B, Error, B, QueryKey>, 'queryFn' | 'queryKey'> = {}) => {
    const auth = useAuth()
    const result = useQuery<B, Error, B, QueryKey>({
      queryKey: [...key, params],
      queryFn: (queryFnProps) => fnc({ params: params as A extends undefined ? undefined : NonNullable<A>, auth, queryFnProps }),
      ...queryParams,
    })
    return result
  }
}

export type QueryFunctionWithoutParams<B> = (params: QueryParams) => Promise<B>

export const createPowderQueryHookWithoutParams = <B>(key: UseQueryOptions['queryKey'], fnc: QueryFunctionWithoutParams<B>) => {
  return (queryParams: Omit<UseQueryOptions<B, Error, B, QueryKey>, 'queryFn' | 'queryKey'> = {}) => {
    const auth = useAuth()
    const result = useQuery<B, Error, B, QueryKey>({
      queryKey: [...key],
      queryFn: (queryFnProps) => fnc({ params: undefined, auth, queryFnProps }),
      ...queryParams,
    })
    return result
  }
}

type MutationFunction<A, B> = (args: A, auth: Auth) => Promise<B>

export const createPowderMutationHook = <A, B>(fnc: MutationFunction<A, B>) => {
  return (params?: Omit<UseMutationOptions<unknown, Error, A, unknown>, 'mutationFn'>) => {
    const auth = useAuth()
    const result = useMutation<B, Error, A, unknown>({ mutationFn: (mutationParams) => fnc(mutationParams, auth), ...params })
    return result
  }
}

export const getAuthorization = (session?: Session | null, simple: boolean = false) => {
  return { Authorization: `Bearer ${simple ? session?.accessToken : session?.organizationToken}` }
}

export const createGlobalRefetchQueryHook = (key: UseQueryOptions['queryKey']) => {
  return () => {
    const queryClient = useQueryClient()
    return () => queryClient.refetchQueries({ queryKey: key, type: 'all' })
  }
}
