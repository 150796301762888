import { useCallback, useState } from 'react'

const useLocalStorage = <T>(key: string, initialValue: T | null): [T | null, (value: T | null | Function) => void, () => void] => {
  const readValue = useCallback((): T | null => {
    try {
      let item = window.localStorage.getItem(key)
      try {
        if (item) {
          item = JSON.parse(item)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
      return (item as T | null) || initialValue
    } catch (error) {
      return initialValue
    }
  }, [initialValue, key])

  const [storedValue, setStoredValue] = useState<T | null>(readValue)

  const setValue = (value: T | null | Function) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const removeValue = () => {
    setValue(null)
    window.localStorage.removeItem(key)
  }

  return [storedValue, setValue, removeValue]
}

export default useLocalStorage
