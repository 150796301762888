import { zodResolver } from '@hookform/resolvers/zod'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import type { IntlShape } from 'react-intl'
import { FormattedMessage, useIntl } from 'react-intl'
import { useBlocker, useParams } from 'react-router-dom'
import { useModal } from 'shared/api/providers/ModalProvider'
import type { Campaign } from 'shared/api/services/organization'
import parseChannelUrl from 'shared/helpers/parseChannelUrl'
import z, { any } from 'zod'

import CampaignAddEditLayout from 'src/templates/CampaignAddEditLayout'

import Button from 'src/graphics/atoms/Button'
import FormEditionRow from 'src/graphics/molecules/FormEditionRow'
import CampaignAddChannel from 'src/graphics/organisms/CampaignAddChannel'
import CampaignAddKeywords from 'src/graphics/organisms/CampaignAddKeywords'
import CampaignChannelsList from 'src/graphics/organisms/CampaignChannelsList'
import CampaignHeader from 'src/graphics/organisms/CampaignHeader'
import CampaignLanguageDetection from 'src/graphics/organisms/CampaignLanguageDetection'
import DialogModal from 'src/graphics/organisms/modal/DialogModal'

// why I created 2 schemas
// https://github.com/colinhacks/zod/issues/479#issuecomment-1843302409
//

const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']
const MAX_FILE_SIZE = 20000

const dateSchema = ({ intl }: { intl: IntlShape }) =>
  z
    .object({
      startDate: z.coerce.date(),
      endDate: z.coerce.date(),
    })
    .refine(
      (data) => {
        return new Date(data.endDate).getTime() > new Date(data.startDate).getTime()
      },
      { message: intl.formatMessage({ defaultMessage: 'The end date must be later than the start date' }), path: ['endDate'] }
    )

const schema = ({ currentChannels, intl }: { currentChannels: string[]; intl: IntlShape }) =>
  z.object({
    name: z
      .string({ required_error: intl.formatMessage({ defaultMessage: 'Campaign name is required' }) })
      .min(1, { message: intl.formatMessage({ defaultMessage: 'Campaign name is required' }) })
      .trim(),
    customerName: z
      .string({ required_error: intl.formatMessage({ defaultMessage: 'Customer name is required' }) })
      .min(1, { message: intl.formatMessage({ defaultMessage: 'Customer name is required' }) })
      .trim(),
    channels: z
      .array(z.string())
      .refine(
        (channels) => {
          return currentChannels.length > 0 || channels.length > 0
        },
        { message: intl.formatMessage({ defaultMessage: 'At least one channel is required' }) }
      )
      .refine(
        (channels) => {
          if (!channels.length) return true

          return channels.some((channel) => {
            return !!parseChannelUrl(channel)
          })
        },
        { message: intl.formatMessage({ defaultMessage: 'Channel link has a bad format' }) }
      ),
    keywords: z.array(z.string()).min(1, { message: intl.formatMessage({ defaultMessage: 'At least one keyword is required' }) }),
    language: z.string().min(1, { message: intl.formatMessage({ defaultMessage: 'Language is required' }) }),
    logo: any()
      .refine(
        (files) => {
          return files?.size <= MAX_FILE_SIZE
        },
        { message: intl.formatMessage({ defaultMessage: `Max file size is 2MB.` }) }
      )
      .refine((files) => ACCEPTED_IMAGE_TYPES.includes(files?.type), {
        message: intl.formatMessage({ defaultMessage: '.jpg, .jpeg, .png and .webp files are accepted.' }),
      })
      .optional(),
  })

interface Props {
  onSubmit: (data: Campaign) => void
  error?: string
  isLoading?: boolean
}

const CampaignForm = ({
  name = '',
  customerName = '',
  startDate = new Date().toISOString().split('T')[0],
  endDate = new Date().toISOString().split('T')[0],
  channels = [],
  keywords = [],
  language,
  logoUrl,
  onSubmit,
  error,
  isLoading,
}: Partial<Campaign> & Props) => {
  const { campaignId } = useParams()
  const { setModal, closeModal } = useModal()
  const intl = useIntl()
  const [currentChannels, setCurrentChannel] = useState(channels)
  const editMode = !!campaignId

  const methods = useForm<Campaign>({
    defaultValues: useMemo(
      () => ({
        name,
        customerName,
        startDate,
        endDate,
        channels: [],
        language,
        keywords,
        guests: [], // @todo
      }),
      [name, startDate, endDate, language, keywords, customerName]
    ),
    resolver: zodResolver(z.intersection(schema({ currentChannels, intl }), dateSchema({ intl }))),
  })

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods

  useEffect(() => {
    if (campaignId) {
      methods.reset({ name, customerName, startDate, endDate, language, keywords, channels: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, name, customerName, startDate, endDate, language, keywords])

  useEffect(() => {
    if (JSON.stringify(channels) === JSON.stringify(currentChannels)) return
    setCurrentChannel(channels)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels])

  const blocker = useBlocker(() => isDirty)

  const handleStayClick = useCallback(() => {
    blocker.reset?.()
    closeModal()
  }, [closeModal, blocker])

  const handleLeaveClick = useCallback(() => {
    blocker.proceed?.()
    closeModal()
  }, [closeModal, blocker])

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setModal(
        <DialogModal
          title={<FormattedMessage defaultMessage={'Are you sure you want to leave?'} />}
          subtitle={<FormattedMessage defaultMessage={'You have unsaved changes that will be lost.'} />}
          actions={
            <>
              <Button color="purple" onClick={handleLeaveClick} outlined>
                <FormattedMessage defaultMessage={'Leave'} />
              </Button>
              <Button color="purple" onClick={handleStayClick}>
                <FormattedMessage defaultMessage={'Stay'} />
              </Button>
            </>
          }
          onClose={closeModal}
        />
      )
    }
  }, [blocker, closeModal, handleLeaveClick, handleStayClick, setModal])

  const handleRemoveChannel = ({ value }: { value: string }) => {
    setCurrentChannel((prev) => prev.filter((channel) => channel !== value))
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(({ channels, ...campaign }) => onSubmit({ ...campaign, channels: [...channels, ...currentChannels] }))}>
        <CampaignAddEditLayout
          error={error}
          actions={
            <Button disabled={isLoading} color="purple">
              {campaignId ? <FormattedMessage defaultMessage={'Update Campaign'} /> : <FormattedMessage defaultMessage={'Create Campaign'} />}
            </Button>
          }
        >
          <CampaignHeader editMode={editMode} logoUrl={logoUrl} loading={isLoading} />
          <FormEditionRow title={<FormattedMessage defaultMessage="Campaign Channel" />}>
            <CampaignAddChannel editMode={editMode} />
            <CampaignChannelsList onDelete={handleRemoveChannel} list={currentChannels} editMode={editMode} />
          </FormEditionRow>
          {/** the backend is not ready */}
          {/* <FormEditionRow title={<FormattedMessage defaultMessage="Guests members" />}>
            <CampaignAddGuest />
            <CampaignGuestsList />
          </FormEditionRow> */}
          <FormEditionRow title={<FormattedMessage defaultMessage="Analysis" />}>
            <CampaignLanguageDetection editMode={editMode} />
            <CampaignAddKeywords editMode={editMode} />
          </FormEditionRow>
        </CampaignAddEditLayout>
      </form>
    </FormProvider>
  )
}

export default CampaignForm
