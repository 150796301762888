import React, { useEffect } from 'react'
import { Navigate, generatePath, useSearchParams } from 'react-router-dom'
import { useJoinOrganization } from 'shared/api/hooks/organization'

import { ROUTES_PATH } from 'src/config'
import LoadingLayout from 'src/templates/LoadingLayout'

const Join = () => {
  const [searchParams] = useSearchParams()
  const { mutate: joinOrganization, isError: joinOrganizationError, data: joinOrganizationData } = useJoinOrganization()
  const token = searchParams.get('invitation')

  useEffect(() => {
    if (token) joinOrganization({ invitationToken: token })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (joinOrganizationData) {
    return <Navigate to={generatePath(ROUTES_PATH.ORGANIZATION, { organizationId: joinOrganizationData.organizationId })} />
  }

  if (joinOrganizationError) {
    return <Navigate to={ROUTES_PATH.HOME} />
  }

  return <LoadingLayout />
}

export default Join
