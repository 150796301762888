import cn from 'classnames'
import type { ChangeEvent, LegacyRef } from 'react'
import React, { forwardRef } from 'react'
import type { ComponentType } from 'react'

import scss from './styles.module.scss'

const SIZES = {
  default: undefined,
  small: scss.small,
  large: scss.large,
} as const

type Sizes = keyof typeof SIZES
export interface Props {
  type?: 'text' | 'email' | 'password' | 'date'
  name?: string
  value?: string
  placeholder?: string
  disabled?: boolean
  maxLength?: number
  isError?: boolean
  error?: string | null
  required?: boolean
  size?: Sizes
  noBackground?: boolean
  iconBefore?: ComponentType<{ className?: string | undefined }>
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const Input = (
  {
    type,
    name,
    value,
    placeholder,
    disabled,
    maxLength,
    isError,
    onChange,
    error,
    required,
    size = SIZES.default,
    noBackground,
    iconBefore: IconBefore,
  }: Props,
  ref: LegacyRef<HTMLInputElement>
) => {
  return (
    <div className={scss.outer}>
      {!!IconBefore && <IconBefore className={scss.icon} />}
      <input
        ref={ref}
        className={cn(
          scss.input,
          isError && scss.isError,
          size && SIZES[size],
          noBackground && scss.noBackground,
          disabled && scss.isDisabled,
          !!IconBefore && scss.hasIcon
        )}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        onChange={onChange}
        required={required}
      />
      {error && <span className={scss.error}>{error}</span>}
    </div>
  )
}

export default forwardRef(Input)
