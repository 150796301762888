import cn from 'classnames'
import React from 'react'

import scss from './style.module.scss'

const COLORS = {
  cyan: scss.cyan,
  purple: scss.purple,
} as const

type Colors = keyof typeof COLORS

interface Props {
  keyword: string
  color?: Colors
}

const Chip = ({ keyword, color = 'cyan' }: Props) => {
  return <div className={cn(scss.outer, COLORS[color])}>{keyword}</div>
}

export default Chip
