import type { ReactNode } from 'react'
import React from 'react'
import { useIntl } from 'react-intl'

import type { Names as StatusNames } from 'src/graphics/molecules/Status'
import Status from 'src/graphics/molecules/Status'
import { CellInfo, CellTitle, Row, TextIcon } from 'src/graphics/molecules/list'

import scss from './style.module.scss'

interface Props {
  picture: ReactNode
  name: string
  brand?: string
  channels: string[]
  content: string[]
  status: StatusNames
  settings: ReactNode
  onClick?: () => void
}
const CampaignRow = ({ picture, name, brand, channels, content, status, settings, onClick }: Props) => {
  const intl = useIntl()

  return (
    <Row gridTemplateColumns="64px minmax(64px, 2fr) minmax(64px, 1fr) minmax(64px, 1fr) 150px 24px" gridTemplateRows="64px" onClick={onClick}>
      <div className={scss.profile}>{picture}</div>
      <div className={scss.name}>
        <CellTitle title={name} subtitle={brand} />
      </div>
      <div className={scss.channel}>
        <CellInfo
          title={intl.formatMessage({ defaultMessage: 'Channels' })}
          subtitles={channels.map((channel, index) => (
            <TextIcon text={channel} key={index} />
          ))}
        />
      </div>
      <div className={scss.content}>
        <CellInfo
          title={intl.formatMessage({ defaultMessage: 'Content' })}
          subtitles={content.map((item, index) => (
            <TextIcon text={item} key={index} />
          ))}
        />
      </div>
      <div className={scss.status}>
        <Status name={status} />
      </div>
      {settings}
    </Row>
  )
}

export default CampaignRow
