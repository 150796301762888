import cn from 'classnames'
import type { ReactNode } from 'react'
import React from 'react'

import scss from './style.module.scss'

interface Props {
  children: ReactNode
  gridTemplateColumns: string
  gridTemplateRows?: string
  noPadding?: boolean
  noGap?: boolean
  lowOpacity?: boolean
  onClick?: () => void
}

const Row = ({ children, gridTemplateColumns, gridTemplateRows, noPadding, noGap, onClick, lowOpacity }: Props) => {
  return (
    <div
      className={cn(scss.outer, noPadding && scss.isNoPadding, onClick && scss.isClickable, noGap && scss.isNoGap, lowOpacity && scss.lowOpacity)}
      style={{ gridTemplateColumns, gridTemplateRows }}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default Row
