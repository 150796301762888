import { rest_AnalysisStatus as AnalysisStatus } from '@powdergg/json-verse-api/dist/generated-axios-client/models/rest_AnalysisStatus'
import type { ReactNode } from 'react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { generatePath } from 'react-router-dom'
import { useCancelRecordingAnalysis, useStartCampaignRecordingAnalysis } from 'shared/api/hooks/organization'
import { RIGHTS, useRights } from 'shared/api/providers/RightsProvider'
import Stars from 'shared/graphics/icons/Stars.svg?component'

import { ROUTES_PATH } from 'src/config'

import Button from 'src/graphics/atoms/Button'
import LinkButton from 'src/graphics/atoms/LinkButton'

type AnalysisStatusValues = `${AnalysisStatus}`

const ANALYZE_INFO: Record<AnalysisStatusValues, { disabled: boolean; outlined: boolean; label: ReactNode; right?: string }> = {
  [AnalysisStatus.AnalysisStatusFailed]: {
    disabled: false,
    outlined: true,
    label: <FormattedMessage defaultMessage={'Re-Analyze'} />,
  },
  [AnalysisStatus.AnalysisStatusPending]: {
    disabled: false,
    outlined: false,
    label: <FormattedMessage defaultMessage={'Analyze'} />,
    right: RIGHTS.START_VIDEO_ANALYSIS,
  },
  [AnalysisStatus.AnalysisStatusProcessing]: {
    disabled: false,
    outlined: true,
    label: <FormattedMessage defaultMessage={'Cancel'} />,
  },
  [AnalysisStatus.AnalysisStatusDone]: {
    disabled: true,
    outlined: false,
    label: <FormattedMessage defaultMessage={'No clips found'} />,
  },
  [AnalysisStatus.AnalysisStatusCanceled]: {
    disabled: false,
    outlined: false,
    label: <FormattedMessage defaultMessage={'Analyze'} />,
    right: RIGHTS.START_VIDEO_ANALYSIS,
  },
} as const

interface Props {
  organizationId: string
  campaignId: string
  recordingId: string
  clipsCount?: number
  analysisStatus: AnalysisStatus
  refetchCampaignRecordingList: () => void
}

const CampaignRecordingsAction = ({ organizationId, campaignId, recordingId, analysisStatus, clipsCount, refetchCampaignRecordingList }: Props) => {
  const { hasRight } = useRights()

  const {
    mutate: startCampaignRecordingAnalysis,
    isPending: startCampaignRecordingAnalysisPending,
    isError: startCampaignRecordingAnalysisError,
  } = useStartCampaignRecordingAnalysis({ onSuccess: () => refetchCampaignRecordingList() })
  const {
    mutate: cancelRecordingAnalysis,
    isPending: cancelRecordingAnalysisPending,
    isError: cancelRecordingAnalysisError,
  } = useCancelRecordingAnalysis({ onSuccess: () => refetchCampaignRecordingList() })

  if (clipsCount) {
    return (
      <LinkButton size="small" to={generatePath(ROUTES_PATH.CAMPAIGN_CLIP_LIST, { campaignId, organizationId, recordingId })}>
        <FormattedMessage defaultMessage={'View {count} clips'} values={{ count: clipsCount }} />
      </LinkButton>
    )
  }

  const handleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (analysisStatus === 'processing') {
      cancelRecordingAnalysis({ organizationId, campaignId, recordingId })
    } else {
      startCampaignRecordingAnalysis({ organizationId, campaignId, recordingId })
    }
  }

  return (
    <Button
      size="small"
      color={startCampaignRecordingAnalysisError || cancelRecordingAnalysisError ? 'danger' : 'purple'}
      iconBefore={Stars}
      outlined={ANALYZE_INFO[analysisStatus].outlined}
      disabled={
        ANALYZE_INFO[analysisStatus].disabled ||
        cancelRecordingAnalysisPending ||
        startCampaignRecordingAnalysisPending ||
        // @ts-expect-error typescript wtf
        (ANALYZE_INFO[analysisStatus]?.right ? !hasRight(ANALYZE_INFO[analysisStatus].right) : false)
      }
      onClick={handleOnClick}
    >
      {ANALYZE_INFO[analysisStatus].label}
    </Button>
  )
}

export default CampaignRecordingsAction
