import cn from 'classnames'
import type { MouseEvent as ReactMouseEvent } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Fullscreen from 'shared/graphics/icons/player/Fullscreen-16.svg?component'
import Pause from 'shared/graphics/icons/player/Pause-16.svg?component'
import Play from 'shared/graphics/icons/player/Play-16.svg?component'
import VolumeFull from 'shared/graphics/icons/player/Volume-full-16.svg?component'
import VolumeLow from 'shared/graphics/icons/player/Volume-low-16.svg?component'
import VolumeMute from 'shared/graphics/icons/player/Volume-mute-16.svg?component'
import formatDuration from 'shared/helpers/formatDuration'

import scss from './style.module.scss'

interface Props {
  duration?: number
  percentCurrentTime?: number
  onTogglePlayPause: () => void
  isPlaying: boolean
  onToggleMute: () => void
  muted: boolean
  percentVolume: number
  updateMuted: (value: boolean) => void
  updateVolume: (value: number) => void
  toggleFullScreen?: () => void
  disablePlayer?: boolean
  isFullScreen?: boolean
}

const VideoController = ({
  duration = 0,
  percentCurrentTime = 0,
  onTogglePlayPause,
  isPlaying,
  onToggleMute,
  muted,
  percentVolume,
  updateMuted,
  updateVolume,
  toggleFullScreen,
  disablePlayer,
  isFullScreen,
}: Props) => {
  const localDuration = typeof duration === 'number' ? duration : 0
  const durationFormatted = formatDuration(Math.max(0, localDuration * 1000))
  const currentTimeFormatted = formatDuration(Math.max(0, (percentCurrentTime * localDuration) / 100) * 1000)
  const [volumeSliderMouseDown, setVolumeSliderMouseDown] = useState(false)
  const volumeSliderRef = useRef<HTMLDivElement>(null)

  const handleDocumentMouseUp = () => {
    setVolumeSliderMouseDown(false)
  }

  const updateVolumeByMouseEvent = useCallback(
    (event: MouseEvent | ReactMouseEvent) => {
      if (volumeSliderRef.current) {
        const volumeSliderRect = volumeSliderRef.current?.getBoundingClientRect() || { left: 0 }
        const volumeSliderWidth = volumeSliderRef.current?.clientWidth || 0
        const left = event.clientX - volumeSliderRect.left
        const percentPosition = (left * 100) / volumeSliderWidth
        const newVolume = Math.max(0, Math.min(1, percentPosition / 100))

        if (newVolume === 0) {
          updateMuted(true)
        } else if (muted) {
          updateMuted(false)
        }

        updateVolume(newVolume)
      }
    },
    [muted, updateMuted, updateVolume]
  )

  const handleDocumentMouseMove = useCallback(
    (event: MouseEvent) => {
      if (volumeSliderMouseDown) {
        updateVolumeByMouseEvent(event)
      }
    },
    [updateVolumeByMouseEvent, volumeSliderMouseDown]
  )

  useEffect(() => {
    window.addEventListener('mousemove', handleDocumentMouseMove)
    window.addEventListener('mouseup', handleDocumentMouseUp)

    return () => {
      window.removeEventListener('mousemove', handleDocumentMouseMove)
      window.removeEventListener('mouseup', handleDocumentMouseUp)
    }
  }, [handleDocumentMouseMove])

  const handleVolumeSliderMouseDown = () => {
    setVolumeSliderMouseDown(true)
  }

  const handleVolumeSliderClick = (event: ReactMouseEvent) => {
    updateVolumeByMouseEvent(event)
  }

  return (
    <div className={cn(scss.outer, disablePlayer && scss.isDisabled, isFullScreen && scss.isFullScreen)}>
      <div className={scss.button} onClick={onTogglePlayPause}>
        {isPlaying ? <Pause /> : <Play />}
      </div>
      <div className={scss.volumeWrapper}>
        <button className={scss.button} onClick={onToggleMute}>
          {muted ? <VolumeMute /> : percentVolume > 50 ? <VolumeFull /> : <VolumeLow />}
        </button>
        <div className={scss.volumeSliderWrapper}>
          <div className={scss.volumeSliderContainer} ref={volumeSliderRef} onMouseDown={handleVolumeSliderMouseDown} onClick={handleVolumeSliderClick}>
            <div className={scss.volumeSlider}>
              <div className={scss.volumeValue} style={{ transform: `translate(${-100 + percentVolume}%, 0)` }} />
            </div>
            <div className={scss.volumeHandler} style={{ transform: `translate(${-100 + percentVolume}%, 0)` }} />
          </div>
        </div>
      </div>
      <div className={scss.time}>
        <span>
          <span className={scss.emphasis}>{currentTimeFormatted}</span> / {durationFormatted}
        </span>
      </div>
      {toggleFullScreen && (
        <button className={scss.button} onClick={toggleFullScreen}>
          <Fullscreen />
        </button>
      )}
    </div>
  )
}

export default VideoController
