import cn from 'classnames'
import React from 'react'
import { NavLink, generatePath, useLocation } from 'react-router-dom'
import { useGetUserOrganizations } from 'shared/api/hooks/organization'

import { ROUTES_PATH } from 'src/config'

import DropDown from 'src/graphics/molecules/DropDown'
import NavbarProfilePicture from 'src/graphics/molecules/NavbarProfilePicture'
import OrganizationNavLink from 'src/graphics/molecules/OrganizationNavLink'

import scss from './style.module.scss'

interface Props {
  organizationId: string
}

const SidebarOrganizationSwitch = ({ organizationId }: Props) => {
  const { data: userOrganizations } = useGetUserOrganizations()

  const location = useLocation()

  const isActive = location.pathname === generatePath(ROUTES_PATH.ORGANIZATION, { organizationId })

  if (userOrganizations?.length === 1) {
    return (
      <NavLink end to={generatePath(ROUTES_PATH.ORGANIZATION, { organizationId })}>
        <div className={cn(scss.item, isActive && scss.isActive)}>
          <NavbarProfilePicture radius={8} size={40} src={userOrganizations?.[0].logo} text={userOrganizations?.[0].name.substring(0, 1).toUpperCase()} />
        </div>
      </NavLink>
    )
  }

  const currentOrganization = userOrganizations?.find((organization) => organization.id === organizationId)
  const userOrganizationsSorted = [
    ...(currentOrganization ? [currentOrganization] : []),
    ...(userOrganizations?.filter((organization) => organization.id !== organizationId) || []),
  ]

  return (
    <DropDown
      position="rightStart"
      space={12}
      offset={6}
      variant="outlined"
      radius={20}
      data={userOrganizationsSorted.map(({ name, logo, id }) => (
        <OrganizationNavLink key={id} id={id} name={name} image={logo} reloadDocument={id !== organizationId} />
      ))}
    >
      <div className={cn(scss.item, isActive && scss.isActive)}>
        <NavbarProfilePicture radius={8} size={40} src={currentOrganization?.logo} text={currentOrganization?.name.substring(0, 1).toUpperCase()} />
      </div>
    </DropDown>
  )
}

export default SidebarOrganizationSwitch
