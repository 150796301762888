import cn from 'classnames'
import React from 'react'

import scss from './style.module.scss'

const NAMES = {
  active: undefined,
  completed: scss.isCompleted,
  upcoming: scss.isUpcoming,
} as const

export type Names = keyof typeof NAMES

interface Props {
  name: Names
}

const Status = ({ name }: Props) => {
  return <div className={cn(scss.status, NAMES[name])}>{name}</div>
}

export default Status
