import type { ReactNode } from 'react'
import React from 'react'

import Header from 'src/graphics/organisms/Header'
import Menu from 'src/graphics/organisms/Menu'

import scss from './style.module.scss'

interface Props {
  children: ReactNode
  menuHidden?: boolean
}

const MainLayout = ({ children, menuHidden }: Props) => {
  return (
    <div className={scss.outer}>
      <Header />
      <div className={scss.inner}>
        <Menu menuHidden={menuHidden} />
        <div className={scss.children}>{children}</div>
      </div>
    </div>
  )
}

export default MainLayout
