import Page404 from '/404.png'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import LinkButton from 'src/graphics/atoms/LinkButton'

import scss from './style.module.scss'

const NotFound = () => {
  return (
    <div className={scss.outer}>
      <div className={scss.imageContainer}>
        <img src={Page404} className={scss.image} />
      </div>
      <span className={scss.text}>
        <FormattedMessage defaultMessage={"Oops, seems like this page doesn't exist"} />
      </span>
      <LinkButton to="/" color="purple" width={158}>
        <FormattedMessage defaultMessage={'Go back'} />
      </LinkButton>
    </div>
  )
}

export default NotFound
