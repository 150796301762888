import React from 'react'
import type { ReactNode } from 'react'

import scss from './style.module.scss'

interface Props {
  children: ReactNode
}

const Count = ({ children }: Props) => {
  return <span className={scss.count}>{children}</span>
}

export default Count
