import { intervalToDuration } from 'date-fns'

const formatDuration = (duration: number) => {
  const start = new Date(0)
  const end = new Date(duration)
  const d = intervalToDuration({
    start,
    end,
  })

  const formatNumber = (number: number) => {
    return number.toString().padStart(2, '0')
  }

  let result: (number | string)[] = []

  if (d.hours) {
    result = [formatNumber(d.hours)]
  }

  result = [...result, d.minutes ? formatNumber(d.minutes) : '00', d.seconds ? formatNumber(d.seconds) : '00']

  return result.join(':')
}

export const formatDurationSec = (duration: number) => {
  const durationMs = duration * 1000
  return formatDuration(durationMs)
}

export default formatDuration
