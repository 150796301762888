import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import type { Campaign } from 'shared/api/services/organization'

import ControllerSelectCreatable from 'src/graphics/molecules/ControllerSelectCreatable'
import Description from 'src/graphics/molecules/Description'
import FormEditionSubRow from 'src/graphics/molecules/FormEditionSubRow'

import scss from './style.module.scss'

interface Props {
  editMode?: boolean
}

const CampaignAddKeywords = ({ editMode }: Props) => {
  const intl = useIntl()

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useFormContext<Campaign>()

  const keywords = watch('keywords')

  return (
    <FormEditionSubRow title={<FormattedMessage defaultMessage="Brand keywords" />}>
      <div className={scss.outer}>
        <Description>
          <FormattedMessage defaultMessage={'Help the AI identify brand-related content in streams.'} />
        </Description>
        <div className={scss.form}>
          <div className={scss.select}>
            <ControllerSelectCreatable
              name="keywords"
              control={control}
              inputProps={{
                error: errors.keywords?.message,
                placeholder: intl.formatMessage({ defaultMessage: 'Add keywords, e.g. brand name, product name' }),
                value: keywords.map((keyword: string) => ({ label: keyword, value: keyword })),
                isDisabled: editMode,
                onChange: (values) => {
                  setValue('keywords', values?.map((value) => value.value) || [])
                  trigger('keywords')
                },
              }}
            />
          </div>
        </div>
      </div>
    </FormEditionSubRow>
  )
}

export default CampaignAddKeywords
