import cn from 'classnames'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { formatCurrencyPerTime } from 'src/pricing'

import Button from 'src/graphics/atoms/Button'

import scss from './style.module.scss'

interface Props {
  plan: { minutes: number; pricePerMonth: number; storage: number; licenses: number }
}

const PricingCard = ({ plan }: Props) => {
  const intl = useIntl()

  return (
    <div className={scss.plan}>
      <div className={cn(scss.plan__row, scss.title)}>
        <div className={scss.plan__category}>
          <FormattedMessage defaultMessage={'Custom'} />
        </div>
        <div className={scss.plan__title}>{formatCurrencyPerTime({ amount: plan.pricePerMonth, time: 'month', intl })} </div>
      </div>
      <div className={scss.plan__row}>
        <p className={scss.plan__description}>
          <FormattedMessage defaultMessage={'Perfect for mid-sized organizations, with enhanced storage and more cloud analysis time'} />
        </p>
      </div>
      <div className={scss.plan__row}>
        <p className={scss.plan__features}>
          <FormattedMessage defaultMessage={'Up to {time} upload minutes/ month'} values={{ time: intl.formatNumber(plan.minutes) }} />
        </p>
      </div>
      <div className={scss.plan__row}>
        <p className={scss.plan__features}>
          <FormattedMessage
            defaultMessage={'{storage} of shared cloud storage'}
            values={{ storage: intl.formatNumber(plan.storage, { style: 'unit', unit: 'gigabyte' }) }}
          />
        </p>
      </div>
      <div className={scss.plan__row}>
        <p className={scss.plan__features}>
          <FormattedMessage defaultMessage={'Up to {licence} floating PC app licenses'} values={{ licence: plan.licenses }} />
        </p>
      </div>
      <div className={cn(scss.plan__row, scss.action)}>
        <Button color="orange" href="https://powdergg.typeform.com/to/YzkkRcLz">
          <FormattedMessage defaultMessage={'Contact us'} />
        </Button>
      </div>
    </div>
  )
}

export default PricingCard
