import { init } from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import AuthProvider from 'shared/api/providers/AuthProvider.tsx'
import ModalProvider from 'shared/api/providers/ModalProvider.tsx'
import 'shared/graphics/base.css'
import 'shared/graphics/reset.css'

import en from '../compiled-lang/en.json'

import App from './App.tsx'

import './style.scss'

if (window.location.hostname !== 'localhost') {
  init({
    dsn: 'https://a6042377fbf8f1c398515da00698b228@o4504712334147584.ingest.us.sentry.io/4506943610814464',
    integrations: [],
    environment: process.env.MODE || 'development',
  })
}

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } })

const root = document.getElementById('root')

ReactDOM.createRoot(root!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <IntlProvider messages={en} locale="en" defaultLocale="en">
          <ModalProvider>
            <App />
          </ModalProvider>
        </IntlProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
)
