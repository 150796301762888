import type { ReactNode } from 'react'
import React from 'react'

import scss from './style.module.scss'

interface Props {
  children: ReactNode
  actions: ReactNode
  error?: string
}

const CampaignAddEditLayout = ({ children, actions, error }: Props) => {
  return (
    <div className={scss.outer}>
      <div className={scss.inner}>{children}</div>
      <div className={scss.actions}>
        <div className={scss.actions__buttons}>{actions}</div>
        <div className={scss.actions__error}>{error}</div>
      </div>
    </div>
  )
}

export default CampaignAddEditLayout
