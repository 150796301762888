import type { MouseEvent as ReactMouseEvent } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import scss from './style.module.scss'

interface ProgressBarProps {
  value: number
  onChange?: (value: number) => void
}
const ProgressBar = ({ value, onChange }: ProgressBarProps) => {
  const progressBarContainerRef = useRef<HTMLDivElement>(null)
  const [progressBarMouseDown, setProgressBarMouseDown] = useState(false)

  const [valueLocal, setValueLocal] = useState(value)

  useEffect(() => {
    setValueLocal(value)
  }, [value])

  const handleDocumentMouseUp = () => {
    setProgressBarMouseDown(false)
  }

  const updateCurrentTimeByMouseEvent = useCallback(
    (event: MouseEvent | ReactMouseEvent) => {
      if (!progressBarContainerRef.current) return

      const progressBarContainerRect = progressBarContainerRef.current.getBoundingClientRect()

      const left = event.clientX - progressBarContainerRect.left
      const percentPosition = (left * 100) / progressBarContainerRect.width
      const clampedPercentPosition = Math.min(100, Math.max(0, percentPosition))

      onChange?.(clampedPercentPosition)
    },
    [onChange]
  )

  const handleDocumentMouseMove = useCallback(
    (event: MouseEvent) => {
      if (progressBarMouseDown) {
        updateCurrentTimeByMouseEvent(event)
      }
    },
    [progressBarMouseDown, updateCurrentTimeByMouseEvent]
  )

  useEffect(() => {
    window.addEventListener('mousemove', handleDocumentMouseMove)
    window.addEventListener('mouseup', handleDocumentMouseUp)

    return () => {
      window.removeEventListener('mousemove', handleDocumentMouseMove)
      window.removeEventListener('mouseup', handleDocumentMouseUp)
    }
  }, [handleDocumentMouseMove])

  const handleProgressBarClick = (event: ReactMouseEvent) => {
    updateCurrentTimeByMouseEvent(event)
  }

  const handleProgressBarMouseDown = () => {
    setProgressBarMouseDown(true)
  }

  return (
    <div className={scss.outer} onClick={handleProgressBarClick} onMouseDown={handleProgressBarMouseDown} ref={progressBarContainerRef}>
      <div className={scss.barMask}>
        <div className={scss.bar} style={{ transform: `translate(${-100 + valueLocal}%, 0` }} />
      </div>
      <div className={scss.handler} style={{ transform: `translate(${-100 + valueLocal}%, 0` }} />
    </div>
  )
}

export default ProgressBar
