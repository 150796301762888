import React from 'react'
import type { ReactNode } from 'react'

import scss from './style.module.scss'

interface Props {
  selection?: ReactNode
  filters: ReactNode
  action?: ReactNode
}

const FilterLayout = ({ selection, filters, action }: Props) => {
  return (
    <div className={scss.outer}>
      {selection && <div className={scss.selection}>{selection}</div>}
      <div className={scss.filters}>
        <div className={scss.options}>{filters}</div>
        {action && <div className={scss.action}>{action}</div>}
      </div>
    </div>
  )
}

export default FilterLayout
