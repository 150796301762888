import type { ReactNode } from 'react'
import React from 'react'
import type { Position } from 'shared/graphics/atoms/FloatingElement'
import Kebab from 'shared/graphics/icons/Kebab.svg?component'

import DropDown from '../../DropDown'

import scss from './style.module.scss'

interface Props {
  space?: number
  position?: Position
  children: ReactNode
}

const CellSettings = ({ space = 24, position = 'right', children }: Props) => {
  return (
    <DropDown position={position} space={space} data={children}>
      <div className={scss.container}>
        <Kebab className={scss.icon} />
      </div>
    </DropDown>
  )
}

export default CellSettings
