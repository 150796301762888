import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { NavLink, generatePath, useParams } from 'react-router-dom'
import { useAuth } from 'shared/api/providers/AuthProvider'
import SpeakerIcon from 'shared/graphics/icons/Speaker.svg?component'

import { ROUTES_PATH } from 'src/config'

import DropDownItem from 'src/graphics/atoms/DropDownItem'
import DropDown from 'src/graphics/molecules/DropDown'
import ProfilePicture from 'src/graphics/molecules/ProfilePicture'
import SidebarItem from 'src/graphics/molecules/Sidebar/SidebarItem'
import SidebarOrganizationSwitch from 'src/graphics/molecules/Sidebar/SidebarItemOrganization'

import scss from './style.module.scss'

interface Props {
  menuHidden?: boolean
}

const Menu = ({ menuHidden }: Props) => {
  const { session, wipeSession } = useAuth()
  const intl = useIntl()

  const { organizationId } = useParams()

  return (
    <div className={scss.outer}>
      <div className={scss.links}>
        {organizationId && !menuHidden ? (
          <>
            <SidebarOrganizationSwitch organizationId={organizationId} />
            <NavLink end to={generatePath(ROUTES_PATH.CAMPAIGN_LIST, { organizationId: organizationId || null })}>
              {({ isActive }) => <SidebarItem icon={SpeakerIcon} title={intl.formatMessage({ defaultMessage: 'Campaigns' })} isActive={isActive} />}
            </NavLink>
          </>
        ) : null}
      </div>
      {session?.user && (
        <DropDown
          position="right"
          space={24}
          data={
            <>
              <span className={scss.dropdownTitle}>{session.login.email}</span>
              <DropDownItem onClick={wipeSession} size="small">
                <FormattedMessage defaultMessage={'Log out'} />
              </DropDownItem>
            </>
          }
        >
          <ProfilePicture text={session.login.email?.substring(0, 1).toUpperCase()} size={40} />
        </DropDown>
      )}
    </div>
  )
}

export default Menu
