import React, { createContext, useCallback, useContext, useState } from 'react'

import Backdrop from '../../graphics/organisms/Backdrop'

interface ModalContextValue {
  setModal: (component: JSX.Element) => void
  modal: JSX.Element | null
  closeModal: () => void
}

const ModalContext = createContext({} as ModalContextValue)

interface ModalProviderProps {
  children: JSX.Element
}

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modal, setModalState] = useState<JSX.Element | null>(null)

  const closeModal = useCallback(() => {
    setModalState(null)
  }, [])

  const setModal = useCallback((component: JSX.Element) => {
    setModalState(component)
  }, [])

  return (
    <ModalContext.Provider value={{ setModal, modal, closeModal }}>
      {children}
      {modal && <Backdrop onClick={closeModal}>{modal}</Backdrop>}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = useContext(ModalContext)
  if (context === undefined || !Object.keys(context).length) {
    throw new Error('useModal must be used in a ModalProvider')
  }
  return context
}

export default ModalProvider
