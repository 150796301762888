import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import type { Campaign } from 'shared/api/services/organization'

import ControllerInput from 'src/graphics/molecules/ControllerInput'
import Error from 'src/graphics/molecules/Error'
import FormCover from 'src/graphics/organisms/FormCover'

import scss from './style.module.scss'

interface Props {
  editMode?: boolean
  logoUrl?: string
  loading?: boolean
}

const CampaignHeader = ({ editMode, logoUrl, loading }: Props) => {
  const intl = useIntl()

  const {
    control,
    formState: { errors },
    setValue,
    trigger,
  } = useFormContext<Campaign>()

  return (
    <div className={scss.outer}>
      <div className={scss.form}>
        <FormCover
          disabled={loading}
          image={logoUrl}
          onChange={(file) => {
            setValue('logo', file)
            trigger('logo')
          }}
        />
        <div className={scss.content}>
          <ControllerInput
            control={control}
            name="name"
            inputProps={{
              placeholder: intl.formatMessage({ defaultMessage: 'Name of the Campaign*' }),
              size: 'large',
              noBackground: true,
              error: errors.name?.message,
              isError: !!errors.name,
            }}
          />
          <div className={scss.content__form}>
            <div className={scss.customer}>
              <label className={scss.label}>
                <FormattedMessage defaultMessage={'Customer'} />
              </label>
              <ControllerInput
                control={control}
                name="customerName"
                inputProps={{
                  placeholder: intl.formatMessage({ defaultMessage: 'Customer Name' }),
                  size: 'small',
                  error: errors.customerName?.message,
                  isError: !!errors.customerName,
                }}
              />
            </div>
            <div className={scss.duration}>
              <label className={scss.label}>
                <FormattedMessage defaultMessage={'Duration'} />
              </label>
              <ControllerInput
                control={control}
                name="startDate"
                inputProps={{
                  placeholder: intl.formatMessage({ defaultMessage: 'Start date' }),
                  disabled: editMode,
                  type: 'date',
                  size: 'small',
                  error: errors.startDate?.message,
                  isError: !!errors.startDate,
                }}
              />
              <ControllerInput
                control={control}
                name="endDate"
                inputProps={{
                  placeholder: intl.formatMessage({ defaultMessage: 'End Date' }),
                  disabled: editMode,
                  type: 'date',
                  size: 'small',
                  error: errors.endDate?.message,
                  isError: !!errors.endDate,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {errors.logo?.message && <Error>{errors.logo.message}</Error>}
    </div>
  )
}

export default CampaignHeader
