import React from 'react'
import LogoAnimation from 'shared/graphics/molecules/LogoAnimation'

import ContentLayout from '../ContentLayout'
import MainLayout from '../MainLayout'

interface Props {
  menuHidden?: boolean
}

const LoadingLayout = ({ menuHidden }: Props) => {
  return (
    <MainLayout menuHidden={menuHidden}>
      <ContentLayout>
        <LogoAnimation />
      </ContentLayout>
    </MainLayout>
  )
}

export default LoadingLayout
