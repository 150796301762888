import type { ReactNode } from 'react'
import React from 'react'

import scss from './style.module.scss'

interface Props {
  children: ReactNode
  clips: ReactNode
  transcript: ReactNode
  filters?: ReactNode
  emptyState: ReactNode
  status?: ReactNode
}

const Playlist = ({ children, clips, transcript, filters, emptyState, status }: Props) => {
  return (
    <div className={scss.outer}>
      {children ? <div className={scss.player}>{children}</div> : <div className={scss.noChildren}>{emptyState}</div>}
      <div className={scss.transcript}>{transcript}</div>
      <div className={scss.sidebar}>
        {filters && <div className={scss.sidebar__header}>{filters}</div>}
        {clips ? <div className={scss.sidebar__main}>{clips}</div> : <div className={scss.sidebar__status}>{status}</div>}
      </div>
    </div>
  )
}

export default Playlist
