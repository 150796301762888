import React, { useRef } from 'react'
import type { ReactNode } from 'react'
import { CSSTransition } from 'react-transition-group'

import scss from './style.module.scss'

interface Props {
  children: ReactNode
}

const CSSTransitionRow = ({ children, ...props }: Props) => {
  const nodeRef = useRef(null)

  return (
    <CSSTransition
      timeout={{
        enter: 0,
        exit: 200,
      }}
      appear
      nodeRef={nodeRef}
      classNames={{
        exit: scss.__exit,
        enter: scss.__enter,
      }}
      {...props}
    >
      <div className={scss.animationWrapper} ref={nodeRef}>
        {children}
      </div>
    </CSSTransition>
  )
}

export default CSSTransitionRow
