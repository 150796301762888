import cn from 'classnames'
import debounce from 'lodash/debounce'
import type { ReactNode } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import FloatingElement from 'shared/graphics/atoms/FloatingElement'
import type { Position } from 'shared/graphics/atoms/FloatingElement'

import scss from './style.module.scss'

interface Props {
  children: ReactNode
  data?: ReactNode
  position?: Position
  className?: string
  space?: number
  element?: string
  hidden?: boolean
}

const ToolTip = ({ children, data, position, className, space = 10, element = 'span', hidden = false }: Props) => {
  const [visible, setVisible] = useState<boolean>(false)
  const childrenRef = useRef<HTMLElement>(null)

  const handleMouseOver = debounce(
    () => {
      setVisible(true)
    },
    10,
    { leading: true }
  )

  const handleMouseLeave = () => {
    setVisible(false)
    handleMouseOver.cancel()
  }

  useEffect(() => {
    const wheelCallback = () => {
      setVisible(false)
    }

    document.addEventListener('wheel', wheelCallback)
    return () => {
      document.removeEventListener('wheel', wheelCallback)
    }
  }, [])

  return (
    <>
      {React.createElement(
        element,
        {
          className: cn(scss.children, className),
          onMouseOver: handleMouseOver,
          onMouseLeave: handleMouseLeave,
          ref: childrenRef,
        },
        children
      )}
      <FloatingElement visible={!hidden && visible} anchorRef={childrenRef} space={space} position={position}>
        {typeof data === 'string' ? <span className={scss.tooltip}>{data}</span> : data}
      </FloatingElement>
    </>
  )
}

export default ToolTip
