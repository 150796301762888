import React from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useCreateOrganization } from 'shared/api/hooks/organization'

import ContentLayout from 'src/templates/ContentLayout'
import MainLayout from 'src/templates/MainLayout'
import OrganizationAddLayout from 'src/templates/OrganizationAddLayout'

import ContentHero from 'src/graphics/molecules/ContentHero'
import Gear from 'src/graphics/molecules/ContentHero/images/Gear.png'
import type { OrganizationFormValues } from 'src/graphics/organisms/OrganizationForm'
import OrganizationForm from 'src/graphics/organisms/OrganizationForm'

const OrganizationAdd = () => {
  const { mutateAsync: createOrganization, isPending: createOrganizationPending, error: createOrganizationError } = useCreateOrganization()

  const navigate = useNavigate()

  const handleSubmit: SubmitHandler<OrganizationFormValues> = async (data: OrganizationFormValues) => {
    await createOrganization({ name: data.organizationName, website: data.organizationWebsite })
    navigate(0)
  }

  return (
    <MainLayout>
      <ContentLayout>
        <OrganizationAddLayout>
          <ContentHero title={<FormattedMessage defaultMessage="Welcome! Let's create your organization!" />} illustrationUrl={Gear} />
          <OrganizationForm onSubmit={handleSubmit} loading={createOrganizationPending} noPicture error={createOrganizationError} />
        </OrganizationAddLayout>
      </ContentLayout>
    </MainLayout>
  )
}

export default OrganizationAdd
