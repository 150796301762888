import React, { useState, useRef, useEffect } from 'react'
import { Navigate, generatePath, useNavigate, useParams } from 'react-router-dom'
import { useCreateCampaign, useGetCampaign, useUpdateCampaign, useUpdateCampaignLogo } from 'shared/api/hooks/organization'
import type { Campaign } from 'shared/api/services/organization'
import LogoAnimation from 'shared/graphics/molecules/LogoAnimation'

import { ROUTES_PATH } from 'src/config'
import ContentLayout from 'src/templates/ContentLayout'
import MainLayout from 'src/templates/MainLayout'

import Error from 'src/graphics/molecules/Error'
import CampaignForm from 'src/graphics/organisms/CampaignForm'

const FAKE_CAMPAIGN_TIMEOUT = 5000

const CampaignAddEdit = () => {
  const { campaignId, organizationId } = useParams()

  const navigate = useNavigate()

  // same as organization form update, update image take time to be updated, so we fake a loading state
  const [fakeCampaignLoading, setFakeCampaignLoading] = useState<boolean>(false)
  const campaignTimeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => {
      if (campaignTimeout.current) clearTimeout(campaignTimeout.current)
    }
  }, [])

  const {
    data: campaign,
    isLoading: getCampaignLoading,
    isError: getCampaignIsError,
    refetch: refetchCampaign,
  } = useGetCampaign({ organizationId, campaignId })
  const { mutateAsync: createCampaign, isSuccess: createCampaignSucceeded, isPending: createCampaignLoading, error: createCampaignError } = useCreateCampaign()
  const { mutateAsync: updateCampaign, isSuccess: updateCampaignSucceeded, isPending: updateCampaignLoading, error: updateCampaignError } = useUpdateCampaign()
  const { mutateAsync: updateCampaignLogo, isPending: updateCampaignLogoLoading } = useUpdateCampaignLogo()

  const handleBack = () => {
    if (organizationId) {
      navigate(generatePath(ROUTES_PATH.CAMPAIGN_LIST, { organizationId }))
    }
  }

  const handleSubmit = async (params: Campaign) => {
    const newChannelsFiltered = params.channels.filter((channel, index) => params.channels.indexOf(channel) === index) // remove duplicates

    setFakeCampaignLoading(true)
    if (campaignId && organizationId) {
      await updateCampaign(
        { campaignId, organizationId, ...params, channels: newChannelsFiltered },
        {
          onSuccess: async () => {
            if (params.logo) {
              await updateCampaignLogo({ campaignId, organizationId, file: params.logo })
            }
            campaignTimeout.current = setTimeout(async () => {
              refetchCampaign()
              setFakeCampaignLoading(false)
            }, FAKE_CAMPAIGN_TIMEOUT)
          },
        }
      )
    } else if (organizationId) {
      await createCampaign(
        { organizationId, ...params, channels: newChannelsFiltered },
        {
          onSuccess: async (data) => {
            if (params.logo) {
              await updateCampaignLogo({ organizationId, campaignId: data.id, file: params.logo })
            }
            campaignTimeout.current = setTimeout(() => {
              setFakeCampaignLoading(false)
            }, FAKE_CAMPAIGN_TIMEOUT)
          },
        }
      )
    }
  }

  if (createCampaignSucceeded && organizationId && !fakeCampaignLoading) {
    return <Navigate to={generatePath(ROUTES_PATH.CAMPAIGN_LIST, { organizationId })} />
  }

  if (updateCampaignSucceeded && organizationId && !fakeCampaignLoading) {
    return <Navigate to={generatePath(ROUTES_PATH.CAMPAIGN_LIST, { organizationId })} />
  }

  const isLoading = getCampaignLoading || createCampaignLoading || updateCampaignLoading || updateCampaignLogoLoading || fakeCampaignLoading
  const error = createCampaignError || updateCampaignError

  if (campaignId && organizationId && getCampaignIsError) {
    return (
      <MainLayout>
        <ContentLayout>
          <Error />
        </ContentLayout>
      </MainLayout>
    )
  }

  return (
    <MainLayout>
      <ContentLayout onBack={organizationId ? handleBack : undefined}>
        {campaignId && organizationId && getCampaignLoading ? (
          <LogoAnimation />
        ) : (
          <CampaignForm {...campaign} onSubmit={handleSubmit} error={error?.message} isLoading={isLoading} />
        )}
      </ContentLayout>
    </MainLayout>
  )
}

export default CampaignAddEdit
